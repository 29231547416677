import Http from "../../../utils/http";

class TopDownCompanyService extends Http {
  public constructor() {
    super();
  }

  public getTableCompany = (id: number) =>
    this.instance.get(`/company/${id}/pgv/topdown-company`);

  public searchTableCompany = (id: number, search: string) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-company?filter_text=${search}`
    );

  public getTableExpansion = (id: number) =>
    this.instance.get(`/company/${id}/pgv/topdown-company/expansion`);

  public searchTableExpansion = (id: number, search: string) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-company/expansion?filter_text=${search}`
    );

  public postTableCompany = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgv/topdown-company`, body);

  public postTableExpansion = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgv/topdown-company/expansion`, body);

  public putTableCompanyStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgv/topdown-company/status`, body);

  public getTableCompanyStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgv/topdown-company/status`);
}

export default new TopDownCompanyService();
