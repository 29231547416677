import Http from "../../../utils/http";

class WalletService extends Http {
  public constructor() {
    super();
  }

  public PostWallet = (companyId: number, body: any[]) => {
    return this.instance.post(`/company/${companyId}/pgc/wallet-mapping`, body);
  };

  public getWallet = (companyId: number, level4: any) => {
    return this.instance.get(
      `/company/${companyId}/pgc/wallet-mapping?level4_id=${level4}`
    );
  };
  public getNewcustomers = (companyId: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/wallet-mapping/newcustomers`
    );
  };
  public getWalletStatus = (companyId: number, level4: any) => {
    return this.instance.get(
      `/company/${companyId}/pgc/wallet-mapping/status?level4_id=${level4}`
    );
  };

  public PutWalletStatus = (companyId: number, body: object) => {
    return this.instance.put(
      `/company/${companyId}/pgc/wallet-mapping/status`,
      body
    );
  };

  public DeleteWallet = (companyId: number, id: number) => {
    return this.instance.delete(
      `/company/${companyId}/pgc/wallet-mapping/${id}`
    );
  };
}

export default new WalletService();
