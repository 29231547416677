import Http from "../../../utils/http";

class CompanyCropService extends Http {
  public constructor() {
    super();
  }

  public getCompanyBusinessType = (companyId: number) =>
    this.instance.get(`/company/${companyId}/crop`);

  public getCompanyCrop = (companyId: number) =>
    this.instance.get(`/company/${companyId}/crop`);

  public getCompanyGlobalCrop = () => this.instance.get(`/global/crop`);

  public createTask = (companyId: number, body: object) => {
    console.log(`/company/${companyId}/crop`, body);
    return this.instance.post(`/company/${companyId}/crop`, body);
  };

  public updateTask = (companyId: number, body: object, taskId: number) => {
    console.log(`/company/${companyId}/crop/${taskId}`, body);
    return this.instance.put(`/company/${companyId}/crop/${taskId}`, body);
  };

  public deleteTask = (companyId: number, taskId: number) => {
    console.log(`/company/${companyId}/crop/${taskId}`);
    return this.instance.delete(`/company/${companyId}/crop/${taskId}`);
  };
}

export default new CompanyCropService();
