import { LoadScript } from "@react-google-maps/api";

export function MapScript({ children }) {
  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyA9pKg5AsBXrqIiAhgNi_UsG9MTbHn4CbE"}
      libraries={["drawing"]}
    >
      {children}
    </LoadScript>
  );
}
