import Http from "../../../utils/http";

class TopDownBoardService extends Http {
  public constructor() {
    super();
  }

  public getTableBoard = (companyId: number, businessType: number) =>
    this.instance.get(
      `/company/${companyId}/pgv/topdown-board?business_type_id=${businessType}`
    );

  public saveTableBoard = (companyId: number, body: object) =>
    this.instance.post(`/company/${companyId}/pgv/topdown-board`, body);

  public putTableBoardStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgv/topdown-Board/status`, body);

  public getTableBoardStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgv/topdown-Board/status`);

  public getTableBoardVerify = (id: number, businessType: number) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-Board/verify?business_type_id=${businessType}`
    );

  public searchTableBoard = (
    companyId: number,
    businessType: number,
    search: string
  ) =>
    this.instance.get(
      `/company/${companyId}/pgv/topdown-board?business_type_id=${businessType}&filter_text=${search}`
    );
}

export default new TopDownBoardService();
