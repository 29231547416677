import { createGlobalStyle } from "styled-components";

// .ant-table-body::-webkit-scrollbar {
//   width: 15px;
//   height: 13px;
// }

export default createGlobalStyle`
 

  ::-webkit-scrollbar-track {
    background: #fafafa;        
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;    
    border-radius: 20px;       
    border: 3px solid #fafafa;
  }

 
  .ant-card-body{
    padding: 16px !important;
  }

  .ant-tooltip-inner{
    background: rgba(0, 0, 0, 0.85) !important;
  }
  
  .ant-input-number-input {
    text-align: right !important;
  }

  .color {
    background-color: #FFF !important;
  }

  body {
    background-color: #f5f5f5;
  }
  
  .ant-radio-inner{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border-color: #a3a3a3;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: all 0.3s;
  }
`;
