import { createSlice } from "@reduxjs/toolkit";
import {
  RecordsBusinessTypeProps,
  RecordsParameterProps,
  RecordsCropProps,
  RecordsCommercialStructureProps,
} from "../DTO";

const initialState = {
  businessType: [] as RecordsBusinessTypeProps[],
  crop: [] as RecordsCropProps[],
  parameter: [] as RecordsParameterProps[],
  commercialStructure: {
    level0: [] as RecordsCommercialStructureProps[],
    level1: [] as RecordsCommercialStructureProps[],
    level2: [] as RecordsCommercialStructureProps[],
    level3: [] as RecordsCommercialStructureProps[],
    level4: [] as RecordsCommercialStructureProps[],
  },
};

export const EntitiesReducer = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setRecordsBusinessType: (state, action) => {
      state.businessType = action.payload;
    },
    setRecordsCrop: (state, action) => {
      state.crop = action.payload;
    },
    setRecordsParameter: (state, action) => {
      state.parameter = action.payload;
    },
    setCommercialStructureLevel0: (state, action) => {
      state.commercialStructure.level0 = action.payload;
    },
    setCommercialStructureLevel1: (state, action) => {
      state.commercialStructure.level1 = action.payload;
    },
    setCommercialStructureLevel2: (state, action) => {
      state.commercialStructure.level2 = action.payload;
    },
    setCommercialStructureLevel3: (state, action) => {
      state.commercialStructure.level3 = action.payload;
    },
    setCommercialStructureLevel4: (state, action) => {
      state.commercialStructure.level4 = action.payload;
    },
    clearCommercialStructure: (state) => {
      state.commercialStructure.level1 = [];
      state.commercialStructure.level2 = [];
      state.commercialStructure.level3 = [];
      state.commercialStructure.level4 = [];
    },
  },
});

export const {
  setRecordsBusinessType,
  setRecordsCrop,
  setRecordsParameter,
  setCommercialStructureLevel0,
  setCommercialStructureLevel1,
  setCommercialStructureLevel2,
  setCommercialStructureLevel3,
  setCommercialStructureLevel4,
  clearCommercialStructure,
} = EntitiesReducer.actions;

export default EntitiesReducer.reducer;
