import Http from "../../utils/http";

class GlobalService extends Http {
  public constructor() {
    super();
  }

  public getCompanies = (id?: string) =>
    this.instance.get(`/global/user/${id}/companies`);

  public getModules = (companyId: number) =>
    this.instance.get(`/company/${companyId}/module`);

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/parameter`);
}

export default new GlobalService();
