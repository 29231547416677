import styled from "styled-components";
interface HeaderProps {
  background?: boolean;
}
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  padding: 0 40px;
  margin-top: 10px;
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  background: ${({ background }) => (background ? "#fff" : "transparent")};
  padding: ${({ background }) => (background ? "0 16px" : "0")};
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 8px;
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px 8px;
  direction: rtl;
`;

export const LeftIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
`;
