import styled from "styled-components";

interface ContainerProps {
  height?: number;
}

interface ContentProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => (height ? `${height}px` : "280px")};
  width: 100%;

  background: #ffffff;
  border-radius: 8px;
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${({ color }) => color};
`;

export const Percent = styled.p`
  font-size: 30px;
  line-height: 25px;
  color: #15243e;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
