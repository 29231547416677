import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Container } from "./styles";

export function Loading() {
  const Icon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <Container>
      <Spin indicator={Icon} spinning={true} />
    </Container>
  );
}