import styled from "styled-components";

export const Actions = styled.div`
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
  }
`;
