import Http from "../../utils/http";

class SalesPlanningService extends Http {
  public constructor() {
    super();
  }

  public getDashboard = (id: number) =>
    this.instance.get(`/company/${id}/pgv/dashboard`);

  public getBotoomUpStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgv/bottom-up/status`);

  public getActionPlainStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgv/action-plan/status`);
}

export default new SalesPlanningService();
