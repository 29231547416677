import Http from "../../../utils/http";

class CustomerService extends Http {
  public constructor() {
    super();
  }

  public getFindAll = async (company_id: number, type: string) =>
    await this.instance.get(`/company/${company_id}/customer/type/${type}`);

  public getCustomerGroup = async (
    company_id: number,
    customer_group_id: number
  ) =>
    await this.instance.get(
      `/company/${company_id}/customer/ingroup/${customer_group_id}`
    );

  public PostCustomerClient = async (company_id: number, body: object) =>
    await this.instance.post(`/company/${company_id}/customer`, body);

  public PutCustomerClient = async (
    company_id: number,
    id: number | null,
    body: object
  ) => await this.instance.put(`/company/${company_id}/customer/${id}`, body);

  public PostCustomerGroup = async (company_id: number, body: object) =>
    await this.instance.post(`/company/${company_id}/customer`, body);

  public GetAccessType = async () =>
    await this.instance.get(`/global/access-type`);

  public GetCategory = async (company_id: number) =>
    await this.instance.get(`/company/${company_id}/customercategory`);

  public PatchGroup = async (company_id: number, customer_group_id: number) => {
    await this.instance.patch(
      `/company/${company_id}/customer/${customer_group_id}`
    );
  };

  public putStatus = async (company_id: number, body: object) =>
    await this.instance.put(
      `/company/${company_id}/pgc/action-plan/status`,
      body
    );
}

export default new CustomerService();
