import Http from "../../utils/http";

class PgcService extends Http {
  public constructor() {
    super();
  }

  public getDashboard = (id: number) =>
    this.instance.get(`/company/${id}/pgc/dashboard`);

  public getPGCPermission = (id: number) =>
    this.instance.get(`/company/${id}/pgc/setup/permission`);

  public getPGVPermission = (id: number) =>
    this.instance.get(`/company/${id}/pgv/setup/permission`);

  public getBudgetStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgc/budget/detail`);

  public getCustomerMappingStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgc/customer-mapping/status`);

  public getLevel3MappingStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgc/level3-mapping/status`);

  public getCustomMappingStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgc/customer-mapping/detail`);

  public getWalletMappingDetail = (id: number) =>
    this.instance.get(`/company/${id}/pgc/wallet-mapping/detail`);

  public getActionPlainStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgc/action-plan/detail`);
}

export default new PgcService();
