import { Button, Card, Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import { CardProgress } from "../../components/CardProgress";
import { Loading } from "../../components/Loading";
import { TableStatus } from "../../components/TableStatus";
import { useAppSelector } from "../../hooks";
import SalesPlanningService from "../../services/SalesPlanning";

interface DashboardProps {
  [name: string]: {
    permission?: boolean;
    status: string;
    label: string;
    color: string;
    percent: number;
    period_open: boolean;
    period_past: boolean;
    initial_date: string;
    final_date: string;
  };
}
interface StatusProps {
  id: number;
  level3_external_name: string;
  pgv_bottomup: string;
  color: string;
}
interface RecordsProps {
  id: number;
  name: string;
  filial: string;
  status: string;
}

export function SalesPlanning() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [dashboard, setDashboard] = useState<DashboardProps>({});
  const [loading, setLoading] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [loadingRecordsActionPlan, setLoadingRecordsActionPlan] =
    useState(false);
  const [records, setRecords] = useState<RecordsProps[]>([]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    loadDashboard();

    async function loadDashboard() {
      try {
        console.log();
        setLoading(true);
        const response = await SalesPlanningService.getDashboard(companyId);
        setDashboard(response);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId]);

  async function handleDetailBottomUp() {
    try {
      setLoadingRecords(true);
      const response: StatusProps[] =
        await SalesPlanningService.getBotoomUpStatus(companyId);
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level3_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoadingRecords(false);
    }
  }

  async function handleDetailActionPlan() {
    try {
      setLoadingRecordsActionPlan(true);
      const response: StatusProps[] =
        await SalesPlanningService.getActionPlainStatus(companyId);
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level3_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoadingRecordsActionPlan(false);
    }
  }

  return (
    <Breadcrumb path="Planejamento de Vendas">
      {loading ? (
        <Loading />
      ) : (
        <Row justify="center" gutter={[8, 8]}>
          <Col span={24}>
            <Card bordered={false}>
              <Row justify="center" align="top" gutter={[8, 8]}>
                <Col span={5}>
                  <CardProgress
                    cardInfo={dashboard?.setup}
                    height={568}
                    disabled={!dashboard?.setup?.permission}
                    actions={[
                      {
                        children: "Setup",
                        type: "primary",
                        onClick: () => navigate("/pgv/setup"),
                      },
                    ]}
                  />
                </Col>

                <Col span={14}>
                  <Row justify="center" align="top" gutter={[8, 8]}>
                    <Col span={8}>
                      <CardProgress
                        cardInfo={dashboard?.topdown_company}
                        disabled={!dashboard?.topdown_company?.permission}
                        actions={[
                          {
                            children: "Projeções Empresa",
                            type: "primary",
                            onClick: () => navigate("topdown-company"),
                          },
                        ]}
                      />
                    </Col>

                    <Col span={8}>
                      <CardProgress
                        cardInfo={dashboard?.topdown_board}
                        disabled={!dashboard?.topdown_board?.permission}
                        actions={[
                          {
                            children: "Projeções Diretoria",
                            type: "primary",
                            onClick: () => navigate("topdown-board"),
                          },
                        ]}
                      />
                    </Col>

                    <Col span={8}>
                      <CardProgress
                        cardInfo={dashboard?.topdown_product}
                        disabled={!dashboard?.topdown_product?.permission}
                        actions={[
                          {
                            children: "Projeções Produto",
                            type: "primary",
                            onClick: () => navigate("topdown-product"),
                          },
                        ]}
                      />
                    </Col>

                    <Col span={24}>
                      <CardProgress
                        cardInfo={dashboard?.bottomup}
                        disabled={!dashboard?.bottomup?.permission}
                        actions={[
                          {
                            children: "Bottom Up",
                            type: "primary",
                            onClick: () => navigate("bottom-up"),
                          },
                          {
                            children: "Detalhes",
                            type: "default",
                            onClick: handleDetailBottomUp,
                            loading: loadingRecords,
                            style: { marginTop: 8 },
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={5}>
                  <CardProgress
                    cardInfo={dashboard?.action_plan}
                    disabled={!dashboard?.action_plan?.permission}
                    height={568}
                    actions={[
                      {
                        children: "Plano de Ação",
                        type: "primary",
                        onClick: () => navigate("action-plain"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            {records.length > 0 && (
              <TableStatus
                loading={loadingRecords || loadingRecordsActionPlan}
                records={records}
              />
            )}
          </Col>
        </Row>
      )}
    </Breadcrumb>
  );
}
