import { businessType } from "./BusinessType";
import { crop } from "./Crop";
import { parameter } from "./Parameter";
import { level0 } from "./CommercialStructure/Level0";

export async function loadEntities(companyId: number) {
  Promise.all([
    parameter(companyId),
    businessType(companyId),
    crop(companyId),
    level0(companyId),
  ]);
}
