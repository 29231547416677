import Http from "../../../utils/http";

class AcesseType extends Http {
  public constructor() {
    super();
  }

  public getAcessType = async () => await this.instance.get(`global/profile`);

  public putAcessType = async (id: number | null, body: object) =>
    await this.instance.put(`global/profile/${id}`, body);
}

export default new AcesseType();
