import { Breadcrumb } from "../../../components/Breadcrumb";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Typography,
} from "antd";
import { createRef, useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../hooks";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import { Buttons, Header } from "./styles";
import { InputNumberTable } from "../../../components/TableDinamic/InputNumber";
import { FiFilter, FiMenu, FiSearch, FiX } from "react-icons/fi";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import SalesPlanningService from "../../../services/SalesPlanning";
import { resetFilter, setFiltercommercialStucture } from "../Reducer";
import { FormInstance } from "antd/es/form";
import { formatPercent } from "../../../utils";
interface TotalProps {
  total_base_line: number;
  total_year1: number;
  total_year2: number;
  total_year3: number;
  total_year4: number;
  total_year5: number;
  volume_base_line: number;
  volume_year1: number;
  volume_year2: number;
  volume_year3: number;
  volume_year4: number;
  volume_year5: number;
}
const { Column } = Table;
export function BottomUp() {
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [statusButtonUp, setStatusButtonUp] = useState<any[]>([]);
  const [status, setStatus] = useState<string>();
  const years = useParameterName("QTD_YEARS");
  const formRef = createRef<FormInstance>();
  const [level3, setLevel3] = useState<any>();
  const [total, setTotal] = useState<TotalProps | undefined>();
  const [total_base_Line, setTotalBase_Line] = useState<any>();
  const [total_year1, setTotal_Year1] = useState<any>();
  const [total_year2, setTotal_Year2] = useState<any>();
  const [total_year3, setTotal_Year3] = useState<any>();
  const [total_year4, setTotal_Year4] = useState<any>();
  const [total_year5, setTotal_Year5] = useState<any>();
  const [volume_base_line, setVolume_Base_Line] = useState<any>();
  const [volume_year1, setVolume_Year1] = useState<any>();
  const [volume_year2, setVolume_Year2] = useState<any>();
  const [volume_year3, setVolume_Year3] = useState<any>();
  const [volume_year4, setVolume_Year4] = useState<any>();
  const [volume_year5, setVolume_Year5] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(true);
  const [dataMiniDash, setDataMiniDash] = useState<any | undefined>({});
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(true);
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(false);
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(false);
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(false);
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(
    useButtonActiveYears(6)
  );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  async function btup() {
    try {
      const response = await SalesPlanningService.getBotoomUpStatus(companyId);
      setStatusButtonUp(response);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
    }
  }
  if (status === "F") {
    var Btn = "Reabrir";
    var disbled = true;
  } else {
    var Btn = "Finalizar";
    var disbled = false;
  }

  useEffect(() => {
    btup();
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);

  const loadTableProduct = useCallback(
    async (id: number, showLoading: boolean) => {
      try {
        setStatus(undefined);
        setLoading(showLoading);
        const response = await BottomUpService.getTableProduct(companyId, id);
        setData(response.records);
        var price_base_line = 0;
        var price_year1 = 0;
        var price_year2 = 0;
        var price_year3 = 0;
        var price_year4 = 0;
        var price_year5 = 0;
        var total_base_line = 0;
        var total_year1 = 0;
        var total_year2 = 0;
        var total_year3 = 0;
        var total_year4 = 0;
        var total_year5 = 0;
        var volume_base_line = 0;
        var volume_year1 = 0;
        var volume_year2 = 0;
        var volume_year3 = 0;
        var volume_year4 = 0;
        var volume_year5 = 0;
        for (let i = 0; i < response.records.length; i++) {
          price_base_line += response.records[i].price_base_line;
          price_year1 += response.records[i].price_year1;
          price_year2 += response.records[i].price_year2;
          price_year3 += response.records[i].price_year3;
          price_year4 += response.records[i].price_year4;
          price_year5 += response.records[i].price_year5;
          total_base_line += response.records[i].total_base_line;
          total_year1 += response.records[i].total_year1;
          total_year2 += response.records[i].total_year2;
          total_year3 += response.records[i].total_year3;
          total_year4 += response.records[i].total_year4;
          total_year5 += response.records[i].total_year5;
          volume_base_line += response.records[i].volume_base_line;
          volume_year1 += response.records[i].volume_year1;
          volume_year2 += response.records[i].volume_year2;
          volume_year3 += response.records[i].volume_year3;
          volume_year4 += response.records[i].volume_year4;
          volume_year5 += response.records[i].volume_year5;
        }
        var obj = JSON.stringify({
          total: {
            total_base_line: total_base_line,
            total_year1: total_year1,
            total_year2: total_year2,
            total_year3: total_year3,
            total_year4: total_year4,
            total_year5: total_year5,
            volume_base_line: volume_base_line,
            volume_year1: volume_year1,
            volume_year2: volume_year2,
            volume_year3: volume_year3,
            volume_year4: volume_year4,
            volume_year5: volume_year5,
          },
        });
        setTotal(JSON.parse(obj));
        setTotalBase_Line(total_base_line);
        setTotal_Year1(total_year1);
        setTotal_Year2(total_year2);
        setTotal_Year3(total_year3);
        setTotal_Year4(total_year4);
        setTotal_Year5(total_year5);
        setVolume_Base_Line(volume_base_line);
        setVolume_Year1(volume_year1);
        setVolume_Year2(volume_year2);
        setVolume_Year3(volume_year3);
        setVolume_Year4(volume_year4);
        setVolume_Year5(volume_year5);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId, data]
  );

  const loadMiniDash = useCallback(
    async (id: number, showLoading: boolean) => {
      try {
        setLoading(showLoading);
        const response = await BottomUpService.getMiniDashboard(companyId, id);
        setDataMiniDash(response);
      } catch (err) {
        message.error(err.response.data.message);
        setLoading(false);
      }
    },
    [companyId]
  );

  async function onSave(data: any) {
    if (data.value && data.value.toString() !== data.initialValue.toString()) {
      const body = {
        company_id: companyId,
        level3_id: commercialStructure.level3,
        product_id: data.records.product_id,
        field_changed: data.name,
        new_value: data.value,
      };
      await BottomUpService.seveTableData(companyId, body);
      loadTableProduct(commercialStructure.level3, false);
    }
  }

  function renderInput(record: number, records: object, field: string) {
    return (
      <InputNumberTable
        onSave={onSave}
        disbled={disbled}
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
  }) {
    setStatus(undefined);
    dispatch(setFiltercommercialStucture(obj));
    loadTableProduct(obj.level3, true);
    loadMiniDash(obj.level3, true);
    setLevel3(obj.level3);
    for (let i = 0; i < statusButtonUp.length; i++) {
      if (obj.level3 == statusButtonUp[i].id) {
        setStatus(statusButtonUp[i].pgv_bottomup);
      }
    }

    setFilter(false);
  }

  async function onChange(search: string) {
    try {
      setLoading(true);
      const response = await BottomUpService.searchTableProduct(
        companyId,
        commercialStructure.level3,
        search
      );
      var price_base_line = 0;
      var price_year1 = 0;
      var price_year2 = 0;
      var price_year3 = 0;
      var price_year4 = 0;
      var price_year5 = 0;
      var total_base_line = 0;
      var total_year1 = 0;
      var total_year2 = 0;
      var total_year3 = 0;
      var total_year4 = 0;
      var total_year5 = 0;
      var volume_base_line = 0;
      var volume_year1 = 0;
      var volume_year2 = 0;
      var volume_year3 = 0;
      var volume_year4 = 0;
      var volume_year5 = 0;
      for (let i = 0; i < response.records.length; i++) {
        price_base_line += response.records[i].price_base_line;
        price_year1 += response.records[i].price_year1;
        price_year2 += response.records[i].price_year2;
        price_year3 += response.records[i].price_year3;
        price_year4 += response.records[i].price_year4;
        price_year5 += response.records[i].price_year5;
        total_base_line += response.records[i].total_base_line;
        total_year1 += response.records[i].total_year1;
        total_year2 += response.records[i].total_year2;
        total_year3 += response.records[i].total_year3;
        total_year4 += response.records[i].total_year4;
        total_year5 += response.records[i].total_year5;
        volume_base_line += response.records[i].volume_base_line;
        volume_year1 += response.records[i].volume_year1;
        volume_year2 += response.records[i].volume_year2;
        volume_year3 += response.records[i].volume_year3;
        volume_year4 += response.records[i].volume_year4;
        volume_year5 += response.records[i].volume_year5;
      }
      var obj = JSON.stringify({
        total: {
          total_base_line: total_base_line,
          total_year1: total_year1,
          total_year2: total_year2,
          total_year3: total_year3,
          total_year4: total_year4,
          total_year5: total_year5,
          volume_base_line: volume_base_line,
          volume_year1: volume_year1,
          volume_year2: volume_year2,
          volume_year3: volume_year3,
          volume_year4: volume_year4,
          volume_year5: volume_year5,
        },
      });
      setTotal(JSON.parse(obj));
      setTotalBase_Line(total_base_line);
      setTotal_Year1(total_year1);
      setTotal_Year2(total_year2);
      setTotal_Year3(total_year3);
      setTotal_Year4(total_year4);
      setTotal_Year5(total_year5);
      setVolume_Base_Line(volume_base_line);
      setVolume_Year1(volume_year1);
      setVolume_Year2(volume_year2);
      setVolume_Year3(volume_year3);
      setVolume_Year4(volume_year4);
      setVolume_Year5(volume_year5);
      setData(response.records);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  function MiniDashCard(year: any, mktShare: any, soc: any) {
    return (
      <Card bordered={false}>
        <Row>{year}</Row>
        <Row justify="space-between">
          <Col>
            <Row>Market Share</Row>
            <Row>{formatPercent(mktShare)}%</Row>
          </Col>
          <Col>
            <Row>SOC</Row>
            <Row>{formatPercent(soc)}%</Row>
          </Col>
        </Row>
      </Card>
    );
  }
  return (
    <Breadcrumb
      path="BottomUp"
      actions={[
        {
          children: Btn,
          type: Btn === "Finalizar" ? "primary" : "default",
          className: level3,
        },
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {dataMiniDash &&
                MiniDashCard(
                  year0,
                  dataMiniDash.mkt_base_line,
                  dataMiniDash.soc_base_line
                )}
            </Col>
            <Col span={12}>
              {dataMiniDash &&
                MiniDashCard(
                  year1,
                  dataMiniDash.mkt_year1,
                  dataMiniDash.soc_year1
                )}
            </Col>
            {filter && (
              <Col span={4}>
                <Card bordered={false}>
                  <Form
                    ref={formRef}
                    onFinish={onFinish}
                    initialValues={commercialStructure}
                  >
                    <CommercialStructure formRef={formRef} level={3} />
                    <Button htmlType="submit" type="primary" block>
                      Buscar
                    </Button>
                  </Form>
                </Card>
              </Col>
            )}
            <Col span={filter ? 20 : 24}>
              <Card bordered={false}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Header>
                      <Buttons>
                        {filter ? (
                          <FiX
                            color="#00CC66"
                            size={20}
                            onClick={() => setFilter(!filter)}
                            style={{ marginRight: 16 }}
                          />
                        ) : (
                          <FiMenu
                            color="#00CC66"
                            size={20}
                            onClick={() => setFilter(!filter)}
                            style={{ marginRight: 16 }}
                          />
                        )}
                        <Button
                          disabled
                          onClick={() => setYear0Active(!year0Active)}
                          type={year0Active ? "primary" : "default"}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 1
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year0}
                        </Button>
                        <Button
                          onClick={() => setYear1Active(!year1Active)}
                          type={year1Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 2
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year1}
                        </Button>
                        <Button
                          onClick={() => setYear2Active(!year2Active)}
                          type={year2Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 3
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year2}
                        </Button>
                        <Button
                          onClick={() => setYear3Active(!year3Active)}
                          type={year3Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 4
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year3}
                        </Button>
                        <Button
                          onClick={() => setYear4Active(!year4Active)}
                          type={year4Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year4}
                        </Button>
                        <Button
                          onClick={() => setYear5Active(!year5Active)}
                          type={year5Active ? "primary" : "default"}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            display:
                              years?.value && parseInt(years.value) > 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year5}
                        </Button>
                      </Buttons>
                      <Input
                        allowClear
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Pesquisar"
                        prefix={<FiSearch color="#00CC66" />}
                        suffix={<FiFilter color="#00CC66" />}
                        style={{ width: 350 }}
                        disabled={!commercialStructure.level3}
                      />
                    </Header>
                  </Col>

                  <Col span={24}>
                    <Table
                      rowKey="product_id"
                      loading={{ indicator: Icon, spinning: loading }}
                      scroll={{ y: "30vw", x: "100vw" }}
                      size="small"
                      pagination={false}
                      dataSource={data}
                      summary={() => {
                        return (
                          <Table.Summary fixed>
                            <Table.Summary.Row
                              style={{ background: "#f5f5f5" }}
                            >
                              <Table.Summary.Cell index={0} colSpan={4}>
                                Total
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} align="right">
                                <Typography.Text>
                                  {volume_base_line &&
                                    volume_base_line.toLocaleString("pt-BR")}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={2} align="right">
                                <Typography.Text>
                                  {total_base_Line &&
                                    total_base_Line.toLocaleString("pt-BR")}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {year1Active && (
                                <Table.Summary.Cell index={3} align="right">
                                  <Typography.Text>
                                    {volume_year1 &&
                                      volume_year1.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year1Active && (
                                <Table.Summary.Cell index={4} align="right">
                                  <Typography.Text>
                                    {total_year1 &&
                                      total_year1.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={5} align="right">
                                  <Typography.Text>
                                    {volume_year2 &&
                                      volume_year2.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={6} align="right">
                                  <Typography.Text>
                                    {total_year2 &&
                                      total_year2.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={7} align="right">
                                  <Typography.Text>
                                    {volume_year3 &&
                                      volume_year3.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={8} align="right">
                                  <Typography.Text>
                                    {total_year3 &&
                                      total_year3.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={9} align="right">
                                  <Typography.Text>
                                    {volume_year4 &&
                                      volume_year4.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={10} align="right">
                                  <Typography.Text>
                                    {total_year4 &&
                                      total_year4.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year5Active && (
                                <Table.Summary.Cell index={11} align="right">
                                  <Typography.Text>
                                    {volume_year5 &&
                                      volume_year5.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}

                              {year5Active && (
                                <Table.Summary.Cell index={12} align="right">
                                  <Typography.Text>
                                    {total_year5 &&
                                      total_year5.toLocaleString("pt-BR")}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                            </Table.Summary.Row>
                          </Table.Summary>
                        );
                      }}
                    >
                      <Column
                        title="Segmento"
                        dataIndex="segment_name"
                        sorter={(a: any, b: any) =>
                          a.segment_name.localeCompare(b.segment_name)
                        }
                        width={100}
                      />
                      <Column
                        title="Produto"
                        dataIndex="product_name"
                        sorter={(a: any, b: any) =>
                          a.product_name.localeCompare(b.product_name)
                        }
                        width={100}
                      />
                      <Column
                        title="SKU"
                        dataIndex="sku_name"
                        sorter={(a: any, b: any) =>
                          a.sku_name.localeCompare(b.sku_name)
                        }
                        width={100}
                      />
                      <Column
                        title="Tipo de Produto"
                        dataIndex="type_name"
                        sorter={(a: any, b: any) =>
                          a.type_name.localeCompare(b.type_name)
                        }
                        width={150}
                      />
                      {year0Active ? (
                        <Column
                          title={`Vol. (Kg/Lt) ${year0}`}
                          align="right"
                          dataIndex="volume_base_line"
                          sorter={(a: any, b: any) =>
                            a.volume_base_line - b.volume_base_line
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year0Active ? (
                        <Column
                          title={`Total (R$) ${year0}`}
                          align="right"
                          dataIndex="total_base_line"
                          sorter={(a: any, b: any) =>
                            a.total_base_line - b.total_base_line
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {year1Active ? (
                        <Column
                          className="color"
                          title={`Vol. (Kg/Lt) ${year1}`}
                          align="right"
                          dataIndex="volume_year1"
                          sorter={(a: any, b: any) =>
                            a.volume_year1 - b.volume_year1
                          }
                          width={120}
                          render={(record: number, records: object) =>
                            renderInput(record, records, "volume_year1")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year1Active ? (
                        <Column
                          className="color"
                          title={`Total (R$) ${year1}`}
                          align="right"
                          dataIndex="total_year1"
                          sorter={(a: any, b: any) =>
                            a.total_year1 - b.total_year1
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {year2Active ? (
                        <Column
                          title={`Vol. (Kg/Lt) ${year2}`}
                          align="right"
                          dataIndex="volume_year2"
                          sorter={(a: any, b: any) =>
                            a.volume_year2 - b.volume_year2
                          }
                          width={120}
                          render={(record: number, records: object) =>
                            renderInput(record, records, "volume_year2")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year2Active ? (
                        <Column
                          title={`Total (R$) ${year2}`}
                          align="right"
                          dataIndex="total_year2"
                          sorter={(a: any, b: any) =>
                            a.total_year2 - b.total_year2
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {year3Active ? (
                        <Column
                          className="color"
                          title={`Vol. (Kg/Lt) ${year3}`}
                          align="right"
                          dataIndex="volume_year3"
                          sorter={(a: any, b: any) =>
                            a.volume_year3 - b.volume_year3
                          }
                          width={120}
                          render={(record: number, records: object) =>
                            renderInput(record, records, "volume_year3")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year3Active ? (
                        <Column
                          className="color"
                          title={`Total (R$) ${year3}`}
                          align="right"
                          dataIndex="total_year3"
                          sorter={(a: any, b: any) =>
                            a.total_year3 - b.total_year3
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {year4Active ? (
                        <Column
                          title={`Vol. (Kg/Lt) ${year4}`}
                          align="right"
                          dataIndex="volume_year4"
                          sorter={(a: any, b: any) =>
                            a.volume_year4 - b.volume_year4
                          }
                          width={120}
                          render={(record: number, records: object) =>
                            renderInput(record, records, "volume_year4")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year4Active ? (
                        <Column
                          title={`Total (R$) ${year4}`}
                          align="right"
                          dataIndex="total_year4"
                          sorter={(a: any, b: any) =>
                            a.total_year4 - b.total_year4
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {year5Active ? (
                        <Column
                          className="color"
                          title={`Vol. (Kg/Lt) ${year5}`}
                          align="right"
                          dataIndex="volume_year5"
                          sorter={(a: any, b: any) =>
                            a.volume_year5 - b.volume_year5
                          }
                          width={120}
                          render={(record: number, records: object) =>
                            renderInput(record, records, "volume_year5")
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {year5Active ? (
                        <Column
                          className="color"
                          title={`Total (R$) ${year5}`}
                          align="right"
                          dataIndex="total_year5"
                          sorter={(a: any, b: any) =>
                            a.total_year5 - b.total_year5
                          }
                          width={120}
                          render={(record: number) =>
                            record.toLocaleString("pt-BR")
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Breadcrumb>
  );
}
