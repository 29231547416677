import Http from "../../../utils/http";

class GroupProductService extends Http {
  public constructor() {
    super();
  }

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/productgroup`);

  public createTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/productgroup`, body);
  };

  public updateTask = (companyId: number, body: object, taskId: number) => {
    return this.instance.put(
      `/company/${companyId}/productgroup/${taskId}`,
      body
    );
  };

  public deleteTask = (companyId: number, taskId: number) => {
    return this.instance.delete(`/company/${companyId}/productgroup/${taskId}`);
  };
}

export default new GroupProductService();
