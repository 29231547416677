import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  Select,
  DatePicker,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../../components/Breadcrumb";
import Industry from "../../../../services/Industry";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../../../hooks";

const { Option } = Select;
export function Metas() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [modalNewParameter, setShowModalNewParameter] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedParameterId, setSelectedParameterId] = useState<number | null>(
    null
  );
  const [listCapamign, setListCapamign] = useState<any[]>([]);
  const [name, setName] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);

  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>("");
  const [selectedInitiative, setSelectedInitiative] = useState<any>([]);
  const [dataInicio, setDataInicio] = useState<any>("");
  const [dataIndustryId, setDataIndustryId] = useState<any>([]);
  console.log("🚀 ~ file: index.tsx:40 ~ Metas ~ dataIndustryId:", dataIndustryId)
  console.log(dataInicio);
  const [dataFim, setDataFim] = useState<any>("");
  const getListParameter = useCallback(async () => {
    try {
      setLoading(true);
      const response: any[] = await Industry.getCampaignAll(companyId);
      console.log(response);
      setListCapamign(response);
      setSearchData(response);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    getListParameter();
  }, [getListParameter]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Id da Industria",
      dataIndex: "industry_id",
      key: "industry_id",
    },
    {
      title: "Id da Campanha",
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: "Data Inicio",
      dataIndex: "dateinit",
      key: "dateinit",
      render: (date: string | null) => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Data Fim",
      dataIndex: "dateend",
      key: "dateend",
      render: (date: string | null) => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            <FiEdit2
              size={18}
              color="#00CC66"
              onClick={() => handleClickEdit(record)}
            />
            <Popconfirm
              title="Tem certeza que deseja excluir esse parâmetro?"
              onConfirm={() => confirmDelete(record)}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash size={18} color="#00CC66" />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];

  async function confirmDelete(parameter: any) {
    try {
      await Industry.deleteById(companyId, parameter.campaign_id);
    } catch (e) {
      console.error("Falha ao excluir parâmetro: ");
      console.error(e);
    }
    await getListParameter();
  }

  function handleClickEdit(parameter: any) {
    console.log(parameter);
    setSelectedParameterId(parameter.campaign_id ?? null);
    setName(parameter.campaign_name);
    setValue(parameter.industry_id);
    setDataInicio(parameter.dateinit);
    setDataFim(parameter.dateend);
    setSelectedInitiativeId(parameter.campaign_type);
    // setSelectedInitiative(parameter.type_id);
    setShowModalNewParameter(true);
  }

  function showModalNewParameter() {
    setShowModalNewParameter(true);
  }

  async function handleSave() {
    try {
      if (selectedParameterId) {
        await Industry.update(companyId, {
          campaign_id: selectedParameterId,
          campaign_name: name,
          industry_id: value,
          dateinit: dataInicio,
          dateend: dataFim,
          campaign_type: selectedInitiativeId,
        });
      } else {
        await Industry.create(companyId, {
          campaign_name: name.toUpperCase(),
          industry_id: value,
          dateinit: dataInicio,
          dateend: dataFim,
          campaign_type: selectedInitiativeId,
        });
      }
    } catch (e) {
      console.error("Falha ao salvar parâmetro: ");
      console.error(e);
    }

    await getListParameter();
    setShowModalNewParameter(false);
    clearData();
  }

  function clearData() {
    setSelectedParameterId(null);
    setName("");
    setValue("");
  }

  function handleCancel() {
    setShowModalNewParameter(false);
    setSelectedInitiativeId("");
    setSelectedInitiative(null);
    setDataFim("");
    setDataInicio("");
    clearData();
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = listCapamign.filter(
        (record) =>
          record["campaign_name"].toLowerCase().indexOf(value.toLowerCase()) >
          -1
      );
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listCapamign);
    }
  }
  const onChangeinit = (date: any, dateString: any) => {
    const dataAntiga = dateString;
    const partes = dataAntiga.split("/");
    const dataNova = `${partes[2]}-${partes[1]}-${partes[0]}`;
    setDataInicio(dataNova);
  };
  const onChangefim = (date: any, dateString: any) => {
    console.log("aqui", dateString);
    const dataAntiga = dateString;
    const partes = dataAntiga.split("/");
    const dataNova = `${partes[2]}-${partes[1]}-${partes[0]}`;
    setDataFim(dataNova);
  };
  const getIndustryId = async (id: number) => {
    setLoading(true);
    const response = await Industry.getIndustryId(companyId, id);
    setDataIndustryId(response);

    // const response2 = await Industry.getIndustryProducts(companyId);
    // setDataIndustryIdProducts(response2);
    setLoading(false);
  };

  return (
    <Breadcrumb path="Teste" child={["Teste"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Button type="primary" onClick={showModalNewParameter}>
              Novo
            </Button>
          </Row>
        </Card>

        <Modal
          visible={modalNewParameter}
          okText={"Salvar"}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Row justify="center">
            
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
