import Http from "../../../utils/http";

class GeographyService extends Http {
  public constructor() {
    super();
  }

  public getStates = () => {
    return this.instance.get(`global/geography/states`);
  };

  public getCities = (statdos: any) => {
    return this.instance.get(
      `global/geography/citiesofstate?state_initials=${statdos}`
    );
  };
}

export default new GeographyService();
