import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Select,
  Button,
  Input,
  message,
  Modal,
  Typography,
  Popconfirm,
} from "antd";
import { createRef, useCallback, useEffect, useState } from "react";
import {
  FiEdit2,
  FiFilter,
  FiMenu,
  FiPlus,
  FiSearch,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import {
  useAppDispatch,
  useAppSelector,
  useParameterName,
} from "../../../hooks";
import { FormInstance } from "antd/es/form";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { Actions, Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import WalletService from "../../../services/Global/Wallet";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;
export function WalletMapping() {
  const dispatch = useAppDispatch();
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [disabled, setDisabled] = useState(true);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [filter, setFilter] = useState(true);
  const [status, setStatus] = useState<string>();
  const formRef = createRef<FormInstance>();
  const [data_nao_alocados, setData_nao_alocados] = useState<any>(undefined);
  const [level3, setLevel3] = useState<any>();
  const [modalShowNew, setModalShowNew] = useState(false);
  const [disabledNew, setDisabledNew] = useState(true);
  const [nomeVendedor, setNomeVendedor] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [listTasks, setListTasks] = useState<any>();
  const [listTasks2, setListTasks2] = useState<any>();
  const [level4, setLevel4] = useState<any>();
  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  if (status === "F") {
    var newStatus = "Reabrir";
    var disbled = true;
  } else {
    var newStatus = "Finalizar";
    var disbled = false;
  }
  const start = () => {
    setLoading(true);
    PostWallet(selectedRowKeys);
    setModalShowNew(false);
    data_nao_alocados(undefined);
    setSearch2("");
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys: any) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Cod. Grupo",
      dataIndex: "customer_group_id",
      key: "customer_group_id",
    },
    {
      title: "Grupo",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Cod. Cliente",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "Categoria Cliente",
      dataIndex: "customer_category",
      key: "customer_category",
    },
    {
      title: "Cliente",
      dataIndex: "customer_name",
      key: "customer_name",
    },

    {
      title: "Cidade",
      dataIndex: "customer_city_name",
      key: "customer_city_id",
    },
    {
      title: "UF",
      dataIndex: "customer_state",
      key: "customer_state",
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            <Popconfirm
              title="Tem certeza que deseja excluir?"
              onConfirm={() => DeleteWallet(record)}
              okText="Excluir"
              disabled={status === "F" ? true : false}
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash
                size={18}
                color={status === "F" ? "#9b9b9b" : "#00CC66"}
                style={{ cursor: status === "F" ? "no-drop" : "pointer" }}
              />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Cod. Grupo",
      dataIndex: "customer_group_id",
      key: "customer_group_id",
    },
    {
      title: "Grupo",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Cod. Cliente",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "Categoria Cliente",
      dataIndex: "customer_category",
      key: "customer_category",
    },
    {
      title: "Cliente",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Cidade",
      dataIndex: "customer_city_name",
      key: "customer_city_id",
    },

    {
      title: "UF",
      dataIndex: "customer_state",
      key: "customer_state",
    },
  ];

  async function Nao_alocados() {
    setData_nao_alocados(undefined);
    setSelectedRowKeys([]);
    setSearch2("");
    setLoading(true);
    try {
      const response = await WalletService.getNewcustomers(companyId);

      for (let i = 0; i < response.length; i++) {
        response[i].key = response[i].customer_id;
      }
      setData_nao_alocados(response);
      setDisabled(false);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          customer_city_id: rawTask.customer_city_id,
          customer_city_name: rawTask.customer_city_name,
          customer_external_code:
            rawTask.customer_external_code === ""
              ? " "
              : rawTask.customer_external_code,
          customer_id: rawTask.customer_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : " ",
          customer_name: rawTask.customer_name,
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : " ",
          customer_state: rawTask.customer_state,
          group_external_code: rawTask.group_external_code
            ? rawTask.group_external_code
            : " ",
          group_name: rawTask.group_name ? rawTask.group_name : " ",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : " ",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : " ",
          pgc_customer_mapping_id: rawTask.pgc_customer_mapping_id
            ? rawTask.pgc_customer_mapping_id
            : " ",
          key: rawTask.customer_id,
        };
      });
      setListTasks2(
        formattedTasks.sort(function (a: any, b: any) {
          if (a.customer_id < b.customer_id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function PostWallet(selectRowKeys: any[]) {
    let body: any = { level4_id: level4, customer_id: selectRowKeys };
    try {
      await WalletService.PostWallet(companyId, body);
      loadTableProduct(level4);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function DeleteWallet(value: any) {
    try {
      await WalletService.DeleteWallet(
        companyId,
        value.pgc_customer_mapping_id
      );
      loadTableProduct(level4);
      setDisabled(false);
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  async function WalletMappingStatus(level4: number) {
    try {
      const response = await WalletService.getWalletStatus(companyId, level4);
      setNomeVendedor(response.level4_external_name);
      setStatus(response.pgc_wallet_customer_mapping);
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  function showModalNew() {
    setModalShowNew(true);
    Nao_alocados();
  }
  const loadTableProduct = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        setData(undefined);
        setStatus(undefined);
        const response = await WalletService.getWallet(companyId, id);
        setData(response);
        const formattedTasks = response.map((rawTask: any) => {
          return {
            customer_city_id: rawTask.customer_city_id,
            customer_city_name: rawTask.customer_city_name,
            customer_external_code:
              rawTask.customer_external_code === ""
                ? " "
                : rawTask.customer_external_code,
            customer_id: rawTask.customer_id,
            customer_category: rawTask.customer_category
              ? rawTask.customer_category
              : " ",
            customer_name: rawTask.customer_name,
            customer_registered_number: rawTask.customer_registered_number
              ? rawTask.customer_registered_number
              : " ",
            customer_state: rawTask.customer_state,
            group_external_code: rawTask.group_external_code
              ? rawTask.group_external_code
              : " ",
            customer_group_id: rawTask.customer_group_id
              ? rawTask.customer_group_id
              : " ",
            group_name: rawTask.group_name ? rawTask.group_name : " ",
            group_registered_number: rawTask.group_registered_number
              ? rawTask.group_registered_number
              : " ",
            pgc_customer_mapping_id: rawTask.pgc_customer_mapping_id
              ? rawTask.pgc_customer_mapping_id
              : " ",
            key: rawTask.customer_id,
          };
        });
        setListTasks(
          formattedTasks.sort(function (a: any, b: any) {
            if (a.customer_id < b.customer_id) {
              return -1;
            } else {
              return 1;
            }
          }) as []
        );
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );
  useEffect(() => {
    filterTable();

    async function filterTable() {
      if (!search) {
        setData(listTasks);
      } else {
        let newList = await listTasks.filter((task: any) => {
          return (
            task.customer_name.toLowerCase().includes(search.toLowerCase()) ||
            task.customer_category
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.customer_city_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.customer_registered_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.group_name.toLowerCase().includes(search.toLowerCase()) ||
            task.customer_state.toLowerCase().includes(search.toLowerCase()) ||
            task.group_external_code
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.group_registered_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.customer_id.toString().includes(search.toLowerCase()) ||
            task.customer_group_id.toString().includes(search.toLowerCase())
          );
        });
        setData(newList);
      }
    }
  }, [search, listTasks]);
  useEffect(() => {
    filterTable2();
  }, [search2, listTasks2]);

  async function filterTable2() {
    if (!search2) {
      setData_nao_alocados(listTasks2);
    } else {
      let newList2 = await listTasks2.filter((task: any) => {
        return (
          task.customer_name.toLowerCase().includes(search2.toLowerCase()) ||
          task.customer_category
            .toLowerCase()
            .includes(search2.toLowerCase()) ||
          task.customer_id.toString().includes(search2) ||
          task.customer_group_id.toString().includes(search2)
        );
      });
      setData_nao_alocados(newList2);
    }
  }

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    setStatus(undefined);
    dispatch(setFiltercommercialStucture(obj));
    setLevel3(obj.level3);
    setLevel4(obj.level4);
    loadTableProduct(obj.level4);
    WalletMappingStatus(obj.level4);
    setFilter(false);
    setDisabledNew(false);
  }

  function handleCancel() {
    setSearch2("");
    setModalShowNew(false);
    data_nao_alocados(undefined);
    setSelectedRowKeys([]);
  }
  function disableSave(): boolean {
    return !hasSelected;
  }

  return (
    <Breadcrumb
      path="Mapeamento de Carteira"
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level4,
        },
      ]}
      child={nomeVendedor}
    >
      <Row gutter={[16, 16]}>
        {filter && (
          <Col span={4}>
            <Card bordered={false}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                initialValues={commercialStructure}
              >
                <CommercialStructure formRef={formRef} level={4} />
                <Button htmlType="submit" type="primary" block>
                  Buscar
                </Button>
              </Form>
            </Card>
          </Col>
        )}
        <Col span={filter ? 20 : 24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col
                span={filter ? 2.5 : 2}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Buttons>
                  {filter ? (
                    <FiX
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  ) : (
                    <FiMenu
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  )}
                </Buttons>
                <Button
                  type="primary"
                  onClick={showModalNew}
                  style={{ marginLeft: 10 }}
                  disabled={status === "F" ? true : disabledNew}
                  icon={<FiPlus />}
                >
                  Novo
                </Button>
              </Col>
              <Col span={8}>
                <Input
                  allowClear
                  placeholder="Pesquisar"
                  prefix={<FiSearch color="#00CC66" />}
                  suffix={<FiFilter color="#00CC66" />}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  loading={{ indicator: Icon, spinning: loading }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={7}></Col>
        <Modal
          visible={modalShowNew}
          onOk={start}
          onCancel={handleCancel}
          okText={"Salvar"}
          width={1000}
          okButtonProps={{ disabled: disableSave() }}
        >
          <Card bordered={false}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col>Não Alocados</Col>
              <Col span={18}>
                <Input
                  allowClear
                  placeholder="Pesquisar"
                  prefix={<FiSearch color="#00CC66" />}
                  suffix={<FiFilter color="#00CC66" />}
                  onChange={(e) => setSearch2(e.target.value)}
                  value={search2}
                />
              </Col>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns2}
                  rowSelection={rowSelection}
                  dataSource={data_nao_alocados}
                  loading={{ indicator: Icon, spinning: loading }}
                />
              </Col>
            </Row>
          </Card>
        </Modal>
      </Row>
    </Breadcrumb>
  );
}
