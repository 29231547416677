import Http from "../../utils/http";

class Industry extends Http {
  public constructor() {
    super();
  }

  public getIndustry = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign/all/industry`);
  };

  public getIndustryId = (companyId: number, id: number) => {
    return this.instance.get(`/company/${companyId}/campaign/industry/${id}`);
  };

  public getIndustryProducts = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign/products/all/`);
  };

  public getVendasProductsAll = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign/vendas/all/`);
  };

  public getVendasProducts = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign/vendas/separado/`);
  };

  public getPerfil = (companyId: number, user_id:string | undefined) => {
    return this.instance.get(`/company/${companyId}/campaign/Listar/Perfil/${user_id}`);
  };

  public getProfile = (companyId: number, user_id:string | undefined) => {
    return this.instance.get(`/company/${companyId}/campaign/Listar/Profile/${user_id}`);
  };

  public getCampaignProducts = (companyId: number,campaign:number) => {
    return this.instance.get(`/company/${companyId}/campaign/products/${campaign}`);
  };

  public getCampaignAll = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign`);
  };

  public getCampaignTeste = (companyId: number, data: any) => {
    return this.instance.post(`/company/${companyId}/campaign/filtro`,data);
  };

  public getTypeAll = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/campaign/all/type`);
  };

  public getType = (companyId: number, id: number) => {
    return this.instance.get(`/company/${companyId}/campaign/type/${id}`);
  };

  public getDate = (companyId: number, Ano_mes: string) => {
    return this.instance.get(`/company/${companyId}/campaign/date/${Ano_mes}`);
  };

  public deleteById = (companyId: number, id: number) => {
    return this.instance.delete(`/company/${companyId}/campaign/${id}`);
  };
  public update = (companyId: number, data: any) => {
    return this.instance.put(
      `/company/${companyId}/campaign/${data.campaign_id}`,
      data
    );
  };

  public create = (companyId: number, data: any) => {
    return this.instance.post(`/company/${companyId}/campaign`, data);
  };
}

export default new Industry();
