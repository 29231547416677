import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Radio,
  Input,
  Popconfirm,
  message,
  Select,
  RadioChangeEvent,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import CompanyProductService from "../../../services/Company/Product";
import Customer from "../../../services/Pgc/Customer";
import GeographyService from "../../../services/Global/Geography";
import {
  FiEdit2,
  FiEye,
  FiFilter,
  FiPlus,
  FiSearch,
  FiTrash,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
//import { mask } from "../../../components/MaskCPF_CNPJ/MaskCPF_CNPJ";
import { useAppSelector } from "../../../hooks";
const { Option } = Select;
export function CreateClient() {
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [modalNewProduct2, setModalNewProduct2] = useState(false);
  const [slected, setSlected] = useState<string>("C");
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [RegName, setRegName] = useState<string>("");
  const [RegGroupName, setRegGroupName] = useState<any>(undefined);
  const [NomeGrupo, setNomeGrupo] = useState<string>("");
  const [nameCategory, setNameCategory] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [listTasks2, setListTasks2] = useState<any[]>([]);
  const [states, setStates] = useState<any>(undefined);
  const [cities, setCities] = useState<any>(undefined);
  const [estado, setEstado] = useState<any>(undefined);
  const [accessTypes, setAccessTypes] = useState<any>(undefined);
  const [accessType, setAccessType] = useState<any>(undefined);
  const [category, setCategory] = useState<any>(undefined);
  const [category_id, setCategory_id] = useState<any>(undefined);
  const [city_Latitude, setCity_Latitude] = useState<any>(undefined);
  const [city_Longitude, setCity_Longitude] = useState<any>(undefined);
  const [register_number, setRegister_number] = useState<any>(undefined);
  const [city_id, setCity_id] = useState<any>(undefined);
  const [groupList, setGroupList] = useState<any>(undefined);
  const [searchData, setSearchData] = useState<any>([]);
  const [searchData2, setSearchData2] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const options = [
    { label: "Cliente", value: "C" },
    { label: "Grupo", value: "G" },
  ];
  useEffect(() => {
    onAccessTypes();
    getCategory();
    getListgroup();
    if (city_id) {
      for (let i = 0; i < cities.length; i++) {
        if (cities[i].id === city_id) {
          setCity_Latitude(cities[i].city_latitude);
          setCity_Longitude(cities[i].city_longitude);
        }
      }
    }
  }, [city_id]);
  async function getListTasks(value: string) {
    try {
      setLoading(true);
      const response: any[] = await Customer.getFindAll(companyId, value);
      console.log(response);
      const formattedTasks = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          access_type_id: rawTask.access_type_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          customer_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          state_initials: rawTask.state_initials ? rawTask.state_initials : "",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : "",
          customer_latitude: rawTask.customer_latitude
            ? rawTask.customer_latitude
            : "",
          customer_group_category_id: rawTask.customer_group_category_id
            ? rawTask.customer_group_category_id
            : "",
          customer_category_id: rawTask.customer_category_id
            ? rawTask.customer_category_id
            : "",
          customer_longitude: rawTask.customer_longitude
            ? rawTask.customer_longitude
            : "",
          customer_name: rawTask.customer_name ? rawTask.customer_name : "",
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : "",
          access_name: rawTask.access_name ? rawTask.access_name : "",
          customer_type: rawTask.customer_type ? rawTask.customer_type : "",
          customer_group_category: rawTask.customer_group_category
            ? rawTask.customer_group_category
            : "",
          group_city_id: rawTask.group_city_id ? rawTask.group_city_id : "",
          group_latitude: rawTask.group_latitude ? rawTask.group_latitude : "",
          group_longitude: rawTask.group_longitude
            ? rawTask.group_longitude
            : "",
          group_name: rawTask.group_name ? rawTask.group_name : "",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : "",
          city_name: rawTask.city_name ? rawTask.city_name : "",
        };
      });
      setListTasks(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      message.error(e.response.data.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  async function getListTasksOnlyGroup(id_group: number) {
    try {
      setLoading(true);
      const response: any[] = await Customer.getCustomerGroup(
        companyId,
        id_group
      );
      console.log(response);
      const formattedTasks2 = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          access_type_id: rawTask.access_type_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          customer_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          state_initials: rawTask.state_initials ? rawTask.state_initials : "",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : "",
          customer_latitude: rawTask.customer_latitude
            ? rawTask.customer_latitude
            : "",
          customer_longitude: rawTask.customer_longitude
            ? rawTask.customer_longitude
            : "",
          customer_group_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          Customer_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          customer_name: rawTask.customer_name ? rawTask.customer_name : "",
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : "",
          access_name: rawTask.access_name ? rawTask.access_name : "",
          customer_type: rawTask.customer_type ? rawTask.customer_type : "",
          customer_group_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          group_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          group_latitude: rawTask.group_latitude ? rawTask.group_latitude : "",
          group_longitude: rawTask.group_longitude
            ? rawTask.group_longitude
            : "",
          group_name: rawTask.customer_name ? rawTask.customer_name : "",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : "",
          city_name: rawTask.city_name ? rawTask.city_name : "",
        };
      });
      setListTasks2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      message.error(e.response.data.message);
      console.error(e);
    } finally {
      setModalNewProduct2(true);
      setLoading(false);
    }
  }
  async function getListgroup() {
    try {
      const response: any[] = await Customer.getFindAll(companyId, "G");
      console.log(response);
      setGroupList(response);
    } catch (e) {
      console.error("Falha ao buscar");
      message.error(e.response.data.message);
      console.error(e);
    }
  }
  async function PostClinte() {
    const body_c = {
      access_type_id: accessType,
      customer_city_id: city_id,
      customer_name: RegName,
      customer_registered_number: register_number,
      customer_category_id: category_id,
      customer_latitude: city_Latitude,
      customer_longitude: city_Longitude,
      customer_type: slected,
      customer_group_id: RegGroupName ? RegGroupName : null,
    };
    const body_g = {
      access_type_id: accessType,
      group_city_id: city_id,
      group_name: NomeGrupo,
      group_registered_number: register_number,
      customer_group_category_id: category_id,
      group_latitude: city_Latitude,
      group_longitude: city_Longitude,
      customer_type: slected,
      //  customer_group_id: RegGroupName,
    };
    console.log(body_c);
    console.log(body_g);
    try {
      if (!selectedTaskId) {
        if (slected === "C") {
          await Customer.PostCustomerClient(companyId, body_c);
          message.success("Cliente cadastrado com sucesso!");
        } else {
          await Customer.PostCustomerClient(companyId, body_g);
          message.success("Grupo cadastrado com sucesso!");
        }
      } else {
        if (slected === "C") {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_c);
          message.success("Cliente cadastrado com sucesso!");
        } else {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_g);
          message.success("Grupo cadastrado com sucesso!");
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      getListTasks(slected);
    }
  }
  const onAccessTypes = useCallback(async () => {
    try {
      const response = await Customer.GetAccessType();
      setAccessTypes(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getCategory = useCallback(async () => {
    try {
      const response = await Customer.GetCategory(companyId);
      setCategory(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    setSlected(value);
    getListTasks(value);
  };
  async function States() {
    try {
      const response = await GeographyService.getStates();
      setStates(response);
    } catch (error) {
      console.log(error);
    }
  }
  async function Cities(estados: any, value: any) {
    try {
      setCity_id(undefined);
      setCities(undefined);
      const response = await GeographyService.getCities(estados);
      setCities(response);
    } catch (error) {
      console.log(error);
    } finally {
      if (value) {
        setCity_id(
          slected === "G" ? value.group_city_id : value.customer_city_id
        );
      }
    }
  }

  //  function handleChangeMask(event: any) {
  //    const { value } = event.target;
  //    setRegister_number(mask(value));
  //  }

  async function confirmDelete(task: any) {
    try {
      if (modalNewProduct2) {
        await Customer.PatchGroup(companyId, +task.id);
      }
    } catch (e) {
      console.error("Falha ao excluir: ");
      message.error(e.response.data.message + "Falha ao excluir.");
      console.error(e);
    } finally {
      if (modalNewProduct2) {
        await getListTasksOnlyGroup(task.customer_group_id);
      } else {
        await getListTasks(slected);
      }
    }
  }
  useEffect(() => {
    getListTasks(slected);
    States();
  }, [companyId]);

  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            !search ||
            (record["customer_type"].includes(slected) &&
              (record["customer_name"]
                .toLowerCase()
                .includes(search.toLowerCase()) ||
                record["group_name"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["group_registered_number"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["customer_category"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["city_name"]
                  ?.toLowerCase()
                  .includes(search.toLowerCase())))
        );
        return setSearchData(array);
      }
    }
  }, [search, listTasks, slected]);
  useEffect(() => {
    filterTable2();
    function filterTable2() {
      if (!search2) {
        setSearchData2(listTasks2);
      } else {
        const array = listTasks2.filter(
          (record) =>
            !search2 ||
            record["customer_name"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["group_registered_number"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["customer_category"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["city_name"]?.toLowerCase().includes(search2.toLowerCase())
        );
        return setSearchData2(array);
      }
    }
  }, [search2, listTasks2]);
  function showModalNewProduct() {
    setModalNewProduct(true);
  }
  function handleClickEdit(task: any) {
    console.log("Edite:");
    console.log(task);
    setEstado(task.state_initials);
    Cities(task.state_initials, task);
    if (cities) {
      setCity_id(slected === "G" ? task.group_city_id : task.customer_city_id);
    }
    setCategory_id(
      slected === "G"
        ? task.customer_group_category_id
        : task.customer_category_id
    );
    setRegGroupName(task.customer_group_id);
    setNomeGrupo(task.group_name);
    setRegister_number(
      slected === "G"
        ? task.group_registered_number
        : task.customer_registered_number
    );
    setSelectedTaskId(task.id);
    setRegName(slected === "G" ? task.group_name : task.customer_name);
    setNameCategory(
      slected === "G" ? task.group_category : task.customer_category
    );
    setAccessType(task.access_type_id);
    setModalNewProduct(true);
  }
  async function handleOk() {
    setModalNewProduct(false);
    PostClinte();
    handleCancel();
  }

  function handleCancel() {
    setCities(undefined);
    setEstado(undefined);
    setCity_id(undefined);
    setRegister_number(undefined);
    setRegName("");
    setRegGroupName(undefined);
    setCategory_id(undefined);
    setNameCategory("");
    setAccessType(undefined);
    setSelectedTaskId(null);
    if (modalNewProduct === true && modalNewProduct2 === true) {
      setModalNewProduct(false);
    } else {
      setModalNewProduct(false);
      setModalNewProduct2(false);
    }
  }
  function disableSave(): boolean {
    return !estado || !city_id || !register_number || !category_id;
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    slected === "G"
      ? {
          title: "Nome",
          dataIndex: "group_name",
          key: "group_name",
        }
      : {
          title: "Nome",
          dataIndex: "customer_name",
          key: "customer_name",
        },
    slected === "G"
      ? {
          title: "Categoria",
          dataIndex: "customer_group_category",
          key: "customer_group_category",
        }
      : {
          title: "Categoria",
          dataIndex: "customer_category",
          key: "customer_category",
        },

    {
      title: "Cidade",
      dataIndex: "city_name",
      key: "city_name",
    },
    {
      title: "UF",
      dataIndex: "state_initials",
      key: "state_initials",
    },
    slected === "C"
      ? {
          title: "Nome do Grupo",
          dataIndex: "group_name",
          key: "group_name",
        }
      : {},
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            {slected === "G" && !modalNewProduct2 ? (
              <FiEye
                size={18}
                color="#00CC66"
                onClick={() => getListTasksOnlyGroup(record.id)}
              />
            ) : (
              <></>
            )}
            {slected === "G" && !modalNewProduct2 ? (
              <FiEdit2
                size={18}
                color="#00CC66"
                onClick={() => handleClickEdit(record)}
              />
            ) : (
              <></>
            )}
            {slected === "C" ? (
              <FiEdit2
                size={18}
                color="#00CC66"
                onClick={() => handleClickEdit(record)}
              />
            ) : (
              <></>
            )}
            {slected === "G" && modalNewProduct2 ? (
              <Popconfirm
                title="Tem certeza que deseja excluir essa tarefa?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <FiTrash size={18} color="#00CC66" />
              </Popconfirm>
            ) : (
              <></>
            )}
          </Actions>
        );
      },
    },
  ];
  return (
    <Breadcrumb path="Cadastro de Clientes" child={["Produto"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={11}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewProduct}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Radio.Group
                options={options}
                onChange={onChange3}
                value={slected}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
            <Col span={7}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>
        <Modal
          title="Clientes nesse grupo"
          visible={modalNewProduct2}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch2(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData2}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={modalNewProduct}
          onOk={handleOk}
          okText={"Salvar"}
          width={600}
          onCancel={handleCancel}
          okButtonProps={{ disabled: disableSave() }}
          title={
            selectedTaskId
              ? slected === "G"
                ? "Atualizar Grupo"
                : "Atualizar Cliente"
              : slected === "G"
              ? "Novo Grupo"
              : "Novo Cliente"
          }
        >
          <Row justify="center">
            {!selectedTaskId ? (
              <Col span={16} style={{ textAlign: "center" }}>
                <Radio.Group
                  options={options}
                  onChange={onChange3}
                  value={slected}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            ) : (
              <></>
            )}
            <Col span={12} style={{ textAlign: "center" }}>
              {states !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Estado
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      dropdownMatchSelectWidth={false}
                      //  disabled={modalShowEdite}
                      showSearch
                      placeholder="Estado"
                      optionFilterProp="children"
                      onChange={(value) => [
                        setEstado(value),
                        Cities(value, null),
                      ]}
                      value={estado}
                    >
                      {states.map((initiative: any) => (
                        <Option value={initiative.state_initials}>
                          {initiative.state_initials}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {cities !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cidade
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      dropdownMatchSelectWidth={false}
                      showSearch
                      placeholder="Cidade"
                      optionFilterProp="children"
                      onChange={(value) => setCity_id(value)}
                      value={city_id}
                    >
                      {cities.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.city_name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {accessTypes !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Tipo de Acesso
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      dropdownMatchSelectWidth={false}
                      showSearch
                      placeholder="Tipo de Acesso"
                      optionFilterProp="children"
                      onChange={(value) => setAccessType(+value)}
                      value={accessType}
                    >
                      {accessTypes.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Registro
                </Typography.Text>

                <Input
                  onChange={(e) => setRegister_number(e.target.value)}
                  size="large"
                  style={{ width: "100%", margin: "10px 0" }}
                  placeholder="Registro"
                  maxLength={18}
                  value={register_number}
                />
              </Col>
              {slected === "C" ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cliente
                    </Typography.Text>

                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Cliente"
                      value={
                        RegName != ""
                          ? RegName[0].toUpperCase() +
                            RegName.slice(1).toLowerCase()
                          : RegName
                      }
                      onChange={(e) => setRegName(e.target.value)}
                    />
                  </Col>
                  {groupList !== undefined ? (
                    <>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text
                          style={{
                            whiteSpace: "nowrap",
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Nome do grupo
                        </Typography.Text>
                        <Select
                          style={{ width: "100%", margin: "10px 0" }}
                          size="large"
                          dropdownMatchSelectWidth={false}
                          showSearch
                          placeholder="Nome do grupo"
                          optionFilterProp="children"
                          onChange={(value) => setRegGroupName(+value)}
                          value={RegGroupName}
                        >
                          {groupList.map((initiative: any) => (
                            <>
                              <Option value={initiative.id}>
                                {initiative.group_name}
                              </Option>
                            </>
                          ))}
                        </Select>
                      </Col>
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              ) : (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Nome do grupo
                    </Typography.Text>
                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Nome do grupo"
                      value={
                        NomeGrupo != ""
                          ? NomeGrupo[0].toUpperCase() +
                            NomeGrupo.slice(1).toLowerCase()
                          : NomeGrupo
                      }
                      onChange={(e) => setNomeGrupo(e.target.value)}
                    />
                  </Col>
                </>
              )}
              {category !== undefined ? (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Categoria
                  </Typography.Text>
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    dropdownMatchSelectWidth={false}
                    showSearch
                    placeholder="Categoria"
                    optionFilterProp="children"
                    onChange={(value) => setCategory_id(+value)}
                    value={category_id}
                  >
                    {category.map((initiative: any) => (
                      <>
                        <Option value={initiative.id}>{initiative.name}</Option>
                      </>
                    ))}
                  </Select>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
