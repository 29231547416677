import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Typography,
} from "antd";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { useEffect, useState, createRef, useCallback } from "react";
import type { FormInstance } from "antd/es/form";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../hooks";

import TopDownBoardService from "../../../services/SalesPlanning/TopDownBoard";
import { Buttons, Header } from "./styles";
import { InputNumberTable } from "../../../components/TableDinamic/InputNumber";
import { formatPercent } from "../../../utils";
import { FiFilter, FiMenu, FiSearch, FiSettings, FiX } from "react-icons/fi";
import { BusinessType } from "../../../components/Filter/BusinessType";
import { setFilterBusinessType, resetFilter } from "../Reducer";

const { Column, ColumnGroup } = Table;

interface TotalProps {
  channel_base_line: number;
  channel_cagr: number;
  channel_year1: number;
  channel_year2: number;
  channel_year3: number;
  channel_year4: number;
  channel_year5: number;
  industry_base_line: number;
  industry_cagr: number;
  industry_year1: number;
  industry_year2: number;
  industry_year3: number;
  industry_year4: number;
  industry_year5: number;
  market_growth: number;
  potential_base_line: number;
  potential_year1: number;
  potential_year2: number;
  potential_year3: number;
  potential_year4: number;
  potential_year5: number;
  soc_base_line: number;
  soc_year1: number;
  soc_year2: number;
  soc_year3: number;
  soc_year4: number;
  soc_year5: number;
}

export function TopDownBoard() {
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const { businessType } = useAppSelector(
    (state) => state.salesPlanning.filter
  );

  const years = useParameterName("QTD_YEARS");
  const formRef = createRef<FormInstance>();
  const [status, setStatus] = useState<string>("");
  if (status === "F") {
    var newStatus = "Reabrir";
    var disabled = true;
  } else {
    var newStatus = "Finalizar";
    var disabled = false;
  }
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(true);
  const [total, setTotal] = useState<TotalProps | undefined>();
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(
    useButtonActiveYears(2)
  );
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(
    useButtonActiveYears(3)
  );
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(
    useButtonActiveYears(4)
  );
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(
    useButtonActiveYears(5)
  );
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(
    useButtonActiveYears(6)
  );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);

  useEffect(() => {
    TopDownBoardStatus();
    return function clean() {
      dispatch(resetFilter());
    };
  }, [dispatch]);

  const loadTableCompany = useCallback(
    async (id: number, showLoading: boolean) => {
      try {
        if (showLoading) {
          setLoading(true);
        }
        const response = await TopDownBoardService.getTableBoard(companyId, id);
        setData(response.records);
        setTotal(response.total);
      } catch (err) {
        message.error(err.response.data.message);
        setData([]);
        setTotal(undefined);
      } finally {
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [companyId]
  );
  async function TopDownBoardStatus() {
    try {
      const response = await TopDownBoardService.getTableBoardStatus(companyId);
      setStatus(response.pgv_topdown_board);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
    }
  }
  async function onSave(data: any) {
    if (data.value && data.value.toString() !== data.initialValue.toString()) {
      const body = {
        business_type_id: businessType,
        level1_id: data.records.level1_id,
        field_changed: data.name,
        new_value: data.value,
      };
      await TopDownBoardService.saveTableBoard(companyId, body);
      loadTableCompany(businessType, false);
    }
  }

  function renderInput(record: number, records: object, field: string) {
    if (
      field === "channel_year1" ||
      field === "channel_year2" ||
      field === "channel_year3" ||
      field === "channel_year4" ||
      field === "channel_year5"
    ) {
      var control = false;
    } else {
      var control = true;
    }

    return (
      <InputNumberTable
        onSave={onSave}
        disbled={disabled}
        max={control}
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }

  async function onFinish(obj: any) {
    dispatch(setFilterBusinessType(obj));
    loadTableCompany(obj.businessType, true);
    setFilter(false);
  }

  async function onChange(search: string) {
    try {
      setLoading(true);
      const response = await TopDownBoardService.searchTableBoard(
        companyId,
        businessType,
        search
      );
      setData(response.records);
      setTotal(response.total);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Breadcrumb
      path="Projeções Diretoria"
      child={[businessType?.toString()]}
      disabled={businessType != undefined && businessType > 0 ? false : true}
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
        },
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={16}>
            {filter && (
              <Col span={4}>
                <Card bordered={false}>
                  <Form
                    initialValues={{ businessType }}
                    onFinish={onFinish}
                    ref={formRef}
                  >
                    <BusinessType />
                    <Button htmlType="submit" type="primary" block>
                      Buscar
                    </Button>
                  </Form>
                </Card>
              </Col>
            )}
            <Col span={filter ? 20 : 24}>
              <Card bordered={false}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Header>
                      <Buttons>
                        {filter ? (
                          <FiX
                            color="#00CC66"
                            size={20}
                            onClick={() => setFilter(!filter)}
                            style={{ marginRight: 16 }}
                          />
                        ) : (
                          <FiMenu
                            color="#00CC66"
                            size={20}
                            onClick={() => setFilter(!filter)}
                            style={{ marginRight: 16 }}
                          />
                        )}
                        <Button
                          disabled
                          onClick={() => setYear0Active(!year0Active)}
                          type={year0Active ? "primary" : "default"}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 1
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year0}
                        </Button>
                        <Button
                          onClick={() => setYear1Active(!year1Active)}
                          type={year1Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 2
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year1}
                        </Button>
                        <Button
                          onClick={() => setYear2Active(!year2Active)}
                          type={year2Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 3
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year2}
                        </Button>
                        <Button
                          onClick={() => setYear3Active(!year3Active)}
                          type={year3Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 4
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year3}
                        </Button>
                        <Button
                          onClick={() => setYear4Active(!year4Active)}
                          type={year4Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year4}
                        </Button>
                        <Button
                          onClick={() => setYear5Active(!year5Active)}
                          type={year5Active ? "primary" : "default"}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            display:
                              years?.value && parseInt(years.value) > 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year5}
                        </Button>
                      </Buttons>
                      <Input
                        allowClear
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Pesquisar"
                        prefix={<FiSearch color="#00CC66" />}
                        suffix={<FiFilter color="#00CC66" />}
                        style={{ width: 350 }}
                        disabled={!businessType}
                      />
                    </Header>
                  </Col>
                  <Col span={24}>
                    <Table
                      rowKey={(record: any) =>
                        record.level1_id + record.potential_base_line
                      }
                      loading={{ indicator: Icon, spinning: loading }}
                      scroll={{ y: 450, x: "100vw" }}
                      size="small"
                      pagination={false}
                      dataSource={data}
                      summary={() => {
                        return (
                          <Table.Summary>
                            <Table.Summary.Row
                              style={{ background: "#f5f5f5" }}
                            >
                              <Table.Summary.Cell
                                colSpan={param ? 3 : 1}
                                index={0}
                              >
                                Total
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} align="right">
                                <Typography.Text>
                                  {total?.potential_base_line &&
                                    total?.potential_base_line.toLocaleString(
                                      "pt-BR"
                                    )}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {year1Active && (
                                <Table.Summary.Cell index={2} align="right">
                                  <Typography.Text>
                                    {total?.potential_year1 &&
                                      total?.potential_year1.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={3} align="right">
                                  <Typography.Text>
                                    {total?.potential_year2 &&
                                      total?.potential_year2.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={4} align="right">
                                  <Typography.Text>
                                    {total?.potential_year3 &&
                                      total?.potential_year3.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={5} align="right">
                                  <Typography.Text>
                                    {total?.potential_year4 &&
                                      total?.potential_year4.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year5Active && (
                                <Table.Summary.Cell index={6} align="right">
                                  <Typography.Text>
                                    {total?.potential_year5 &&
                                      total?.potential_year5.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              <Table.Summary.Cell index={7} align="right">
                                <Typography.Text>
                                  {total?.channel_base_line &&
                                    total?.channel_base_line.toLocaleString(
                                      "pt-BR"
                                    )}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {year1Active && (
                                <Table.Summary.Cell index={8} align="right">
                                  <Typography.Text>
                                    {total?.channel_year1 &&
                                      total?.channel_year1.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={9} align="right">
                                  <Typography.Text>
                                    {total?.channel_year2 &&
                                      total?.channel_year2.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={10} align="right">
                                  <Typography.Text>
                                    {total?.channel_year3 &&
                                      total?.channel_year3.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={11} align="right">
                                  <Typography.Text>
                                    {total?.channel_year4 &&
                                      total?.channel_year4.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year5Active && (
                                <Table.Summary.Cell index={12} align="right">
                                  <Typography.Text>
                                    {total?.channel_year5 &&
                                      total?.channel_year5.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              <Table.Summary.Cell index={13} align="right">
                                <Typography.Text>
                                  {total?.industry_base_line &&
                                    total?.industry_base_line.toLocaleString(
                                      "pt-BR"
                                    )}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {year1Active && (
                                <Table.Summary.Cell index={14} align="right">
                                  <Typography.Text>
                                    {total?.industry_year1 &&
                                      total?.industry_year1.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={15} align="right">
                                  <Typography.Text>
                                    {total?.industry_year2 &&
                                      total?.industry_year2.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={16} align="right">
                                  <Typography.Text>
                                    {total?.industry_year3 &&
                                      total?.industry_year3.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={17} align="right">
                                  <Typography.Text>
                                    {total?.industry_year4 &&
                                      total?.industry_year4.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year5Active && (
                                <Table.Summary.Cell index={18} align="right">
                                  <Typography.Text>
                                    {total?.industry_year5 &&
                                      total?.industry_year5.toLocaleString(
                                        "pt-BR"
                                      )}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              <Table.Summary.Cell index={19} align="right">
                                <Typography.Text>
                                  {formatPercent(total?.soc_base_line)}%
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {year1Active && (
                                <Table.Summary.Cell index={20} align="right">
                                  <Typography.Text>
                                    {formatPercent(total?.soc_year1)}%
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year2Active && (
                                <Table.Summary.Cell index={21} align="right">
                                  <Typography.Text>
                                    {formatPercent(total?.soc_year2)}%
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year3Active && (
                                <Table.Summary.Cell index={22} align="right">
                                  <Typography.Text>
                                    {formatPercent(total?.soc_year3)}%
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year4Active && (
                                <Table.Summary.Cell index={23} align="right">
                                  <Typography.Text>
                                    {formatPercent(total?.soc_year4)}%
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              {year5Active && (
                                <Table.Summary.Cell index={24} align="right">
                                  <Typography.Text>
                                    {formatPercent(total?.soc_year5)}%
                                  </Typography.Text>
                                </Table.Summary.Cell>
                              )}
                              <Table.Summary.Cell index={25} align="right">
                                <Typography.Text>
                                  {formatPercent(total?.channel_cagr)}%
                                </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={26} align="right">
                                <Typography.Text>
                                  {formatPercent(total?.industry_cagr)}%
                                </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={27} align="right">
                                <Typography.Text>
                                  {formatPercent(total?.market_growth)}%
                                </Typography.Text>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        );
                      }}
                    >
                      <Column
                        title={() => {
                          return (
                            <Row align="middle" justify="space-between">
                              <Col>Diretoria</Col>
                              <Col>
                                <FiSettings
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setParam(!param)}
                                  size={16}
                                  color="#00CC66"
                                />
                              </Col>
                            </Row>
                          );
                        }}
                        dataIndex="level1_name"
                        width={120}
                      />
                      {param && (
                        <ColumnGroup title="Parametros">
                          <Column
                            title="Canal Cagr"
                            dataIndex="channel_param_cagr"
                            width={110}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <InputNumberTable
                                    onSave={onSave}
                                    disbled={disabled}
                                    records={records}
                                    name="channel_param_cagr"
                                    initialValue={record}
                                  />
                                </div>
                                <div>%</div>
                              </div>
                            )}
                          />
                          <Column
                            title="Industria Cagr"
                            dataIndex="industry_param_cagr"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <InputNumberTable
                                    onSave={onSave}
                                    disbled={disabled}
                                    records={records}
                                    name="industry_param_cagr"
                                    initialValue={record}
                                  />
                                </div>
                                <div>%</div>
                              </div>
                            )}
                          />
                        </ColumnGroup>
                      )}

                      <ColumnGroup title="Potencial" className="color">
                        {year0Active ? (
                          <Column
                            className="color"
                            title={`${year0}`}
                            align="right"
                            dataIndex="potential_base_line"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            className="color"
                            title={year1}
                            align="right"
                            dataIndex="potential_year1"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            className="color"
                            title={year2}
                            align="right"
                            dataIndex="potential_year2"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            className="color"
                            title={year3}
                            align="right"
                            dataIndex="potential_year3"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            className="color"
                            title={year4}
                            align="right"
                            dataIndex="potential_year4"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            className="color"
                            title={year5}
                            align="right"
                            dataIndex="potential_year5"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </ColumnGroup>
                      <ColumnGroup title="Canal">
                        {year0Active ? (
                          <Column
                            title={`${year0}`}
                            align="right"
                            dataIndex="channel_base_line"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            title={year1}
                            align="right"
                            dataIndex="channel_year1"
                            width={120}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "channel_year1")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            title={year2}
                            align="right"
                            dataIndex="channel_year2"
                            width={120}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "channel_year2")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            title={year3}
                            align="right"
                            dataIndex="channel_year3"
                            width={120}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "channel_year3")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            title={year4}
                            align="right"
                            dataIndex="channel_year4"
                            width={120}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "channel_year4")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            title={year5}
                            align="right"
                            dataIndex="channel_year5"
                            width={120}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "channel_year5")
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </ColumnGroup>
                      <ColumnGroup title="Industria" className="color">
                        {year0Active ? (
                          <Column
                            className="color"
                            title={`${year0}`}
                            align="right"
                            dataIndex="industry_base_line"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            className="color"
                            title={year1}
                            align="right"
                            dataIndex="industry_year1"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            className="color"
                            title={year2}
                            align="right"
                            dataIndex="industry_year2"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            className="color"
                            title={year3}
                            align="right"
                            dataIndex="industry_year3"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            className="color"
                            title={year4}
                            align="right"
                            dataIndex="industry_year4"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            className="color"
                            title={year5}
                            align="right"
                            dataIndex="industry_year5"
                            width={120}
                            render={(record: number) =>
                              record.toLocaleString("pt-BR")
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </ColumnGroup>
                      <ColumnGroup title="Soc">
                        {year0Active ? (
                          <Column
                            title={`${year0}`}
                            align="right"
                            dataIndex="industry_soc_base_line"
                            width={120}
                            /*  render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_base_line"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}*/
                            render={(record: number) =>
                              `${formatPercent(record)}%`
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            title={year1}
                            align="right"
                            dataIndex="industry_soc_year1"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_year1"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}
                            //  render={(record: number) => `${formatPercent(record)}%`}
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            title={year2}
                            align="right"
                            dataIndex="industry_soc_year2"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_year2"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}
                            //  render={(record: number) => `${formatPercent(record)}%`}
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            title={year3}
                            align="right"
                            dataIndex="industry_soc_year3"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_year3"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}
                            // render={(record: number) => `${formatPercent(record)}%`}
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            title={year4}
                            align="right"
                            dataIndex="industry_soc_year4"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_year4"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}
                            //render={(record: number) => `${formatPercent(record)}%`}
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            title={year5}
                            align="right"
                            dataIndex="industry_soc_year5"
                            width={120}
                            render={(record: number, records: object) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {renderInput(
                                    record,
                                    records,
                                    "industry_soc_year5"
                                  )}
                                </div>
                                <div>%</div>
                              </div>
                            )}
                            // render={(record: number) => `${formatPercent(record)}%`}
                          />
                        ) : (
                          <></>
                        )}
                      </ColumnGroup>
                      <ColumnGroup title="Cagr" className="color">
                        <Column
                          className="color"
                          title="Canal"
                          align="right"
                          dataIndex="channel_cagr"
                          width={120}
                          render={(record: number) =>
                            `${formatPercent(record)}%`
                          }
                        />
                        <Column
                          className="color"
                          title="Industria"
                          align="right"
                          dataIndex="industry_cagr"
                          width={120}
                          render={(record: number) =>
                            `${formatPercent(record)}%`
                          }
                        />
                        <Column
                          className="color"
                          title="Mercado"
                          dataIndex="market_growth"
                          width={120}
                          align="right"
                          render={(record: number) =>
                            `${formatPercent(record)}%`
                          }
                        />
                      </ColumnGroup>
                    </Table>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Breadcrumb>
  );
}
