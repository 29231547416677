import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useParameterName = (name: string) => {
  const parameter = useAppSelector((state) => state.entities.parameter);
  return parameter.find((parameter) => parameter.name === name);
};

export const useButtonActiveYears = (year: number) => {
  const parameter = useAppSelector((state) => state.entities.parameter);
  const years = parameter.find((parameter) => parameter.name === "QTD_YEARS");
  return years?.value && parseInt(years.value) === year ? true : false;
};
