import { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { useAppSelector } from "../../../hooks";

interface BusinessTypeProps {
  label: string;
  value: number;
}

export function BusinessType() {
  const recordsBusinessType = useAppSelector(
    (state) => state.entities.businessType
  );
  const [businessType, setBusinessType] = useState<BusinessTypeProps[]>([]);

  useEffect(() => {
    const array = recordsBusinessType.map((type) => {
      return {
        label: type.name,
        value: type.id,
      };
    });

    setBusinessType(array);
  }, [recordsBusinessType]);

  return (
    <Form.Item name="businessType" rules={[{ required: true, message: "" }]}>
      <Select placeholder="Tipo de Negócio" allowClear>
        {businessType.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
