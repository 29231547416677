import { Card, Col, Row, Table } from "antd";
import { Breadcrumb } from "../../../components/Breadcrumb";

export function Region() {

  const columns = [
    {
      title: 'Cod. País',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'País',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Cod. Região',
      dataIndex: 'offce',
      key: 'offce',
    },
    {
      title: 'Região',
      dataIndex: 'parce',
      key: 'parce',
    },
    {
      title: 'Cod. UF',
      dataIndex: 'codUF',
      key: 'codUF',
    },
    {
      title: 'UF',
      dataIndex: 'uf',
      key: 'uf',
    },
    {
      title: 'Cod. Meso',
      dataIndex: 'codMEso',
      key: 'codMEso',
    },
    {
      title: 'Meso',
      dataIndex: 'meso',
      key: 'meso',
    },
    {
      title: 'Cod. Micro',
      dataIndex: 'codMicro',
      key: 'codMicro',
    },
    {
      title: 'Micro',
      dataIndex: 'micro',
      key: 'micro',
    },
    {
      title: 'Cod. Cidade',
      dataIndex: 'codCidade',
      key: 'codCidade',
    },
    {
      title: 'Cidade',
      dataIndex: 'cidade',
      key: 'cidade',
    },
  ];

  return (
    <Breadcrumb path="Setup" child={["Região"]}>
      <Row>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table size="small" columns={columns} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Breadcrumb>
  )
}