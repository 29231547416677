import { Badge, Card, Col, Input, Row, Table, Tag, Typography } from "antd";
import { FiSearch, FiFilter } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";

interface DetailProps {
  id: number;
  name: string;
  filial: string;
  status: string;
}
interface TableStatusProps {
  records: DetailProps[];
  status?: string;
  loading: boolean;
  name?: string;
}

export function TableStatus({ records, loading, name }: TableStatusProps) {
  console.log(records);
  const [searchData, setSearchData] = useState(records);
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "Filial",
      dataIndex: "filial",
      key: "filial",
    },
    {
      title: "Vendedor",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 300,
      align: "center" as const,
      render: (record: string) => <Tag color={record}>&nbsp;</Tag>,
    },
  ];

  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = records.filter((record: any) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.filial.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(records);
    }
  }

  return (
    <Card bordered={false} style={{ marginBottom: 30 }}>
      <Row justify="space-between" align="middle" gutter={[12, 16]}>
        <Col span={7} style={{ justifyContent: "center" }}>
          <Typography.Title level={5}>{name}</Typography.Title>
        </Col>
        <Col span={7}>
          <Input
            allowClear
            value={search}
            onChange={(e) => searchTable(e.target.value)}
            placeholder="Pesquisar"
            prefix={<FiSearch color="#00CC66" />}
            suffix={<FiFilter color="#00CC66" />}
          />
        </Col>
        <Col span={9} style={{ textAlign: "center" }}>
          <Badge offset={[0, 0]} text="Não iniciado" color="#F5F5F5" />
          <Badge offset={[10, 0]} text="Pendente" color="#FBC531" />
          <Badge offset={[20, 0]} text="Atrasado" color="#E84118" />
          <Badge offset={[30, 0]} text="Finalizado" color="#00CC66" />
        </Col>
        <Col span={24}>
          <Table
            pagination={false}
            rowKey="id"
            scroll={{ y: 200 }}
            loading={{ indicator: Icon, spinning: loading }}
            size="small"
            columns={columns}
            dataSource={searchData}
          />
        </Col>
      </Row>
    </Card>
  );
}
