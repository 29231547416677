import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 16px;
  }
`;
