import { createSlice } from "@reduxjs/toolkit";

interface UserProps {
  id?: string;
  name?: string;
  email?: string;
  picture?: string;
  cognito_id?: string;
}

const initialState = {
  user: {} as UserProps,
  autorized: false,
};

export const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin: (state, action) => {
      state.autorized = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.autorized = false;
      state.user = {};
      localStorage.clear();
    },
  },
});

export const { signin, logout } = AuthReducer.actions;

export default AuthReducer.reducer;
