import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Select, Form, message } from "antd";
import {
  useAppDispatch,
  useAppSelector,
  useParameterName,
} from "../../../../hooks";
import CompanyCommercialStructureService from "../../../../services/Company/CommercialStructure";
import {
  setCommercialStructureLevel3,
  setCommercialStructureLevel4,
} from "../../../../entities/Reducer";
import { FormInstance } from "antd/es/form";
interface Level2Props {
  formRef: RefObject<FormInstance<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
interface Level2ValueProps {
  label: string;
  value: number;
}

export function Level2({ formRef, loading, setLoading }: Level2Props) {
  const dispatch = useAppDispatch();
  const recordsLevel2 = useAppSelector(
    (state) => state.entities.commercialStructure.level2
  );
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const labelLevel2 = useParameterName("LEVEL2_LABEL");
  const [level2, setLevel2] = useState<Level2ValueProps[]>([]);

  useEffect(() => {
    if (recordsLevel2) {
      const array = recordsLevel2.map((level) => {
        return {
          label: level?.level2_external_name,
          value: level?.level2_id,
        };
      });
      setLevel2(array);
    } else {
      setLevel2([]);
    }
  }, [recordsLevel2]);

  async function onChange(value: number) {
    try {
      if (value) {
        setLoading(true);
        const response =
          await CompanyCommercialStructureService.getAllCommercialStrucuteLevel3(
            companyId,
            value
          );
        dispatch(setCommercialStructureLevel3(response));
        dispatch(setCommercialStructureLevel4([]));
        formRef.current?.setFieldsValue({
          level3: null,
          level4: null,
        });
      } else {
        dispatch(setCommercialStructureLevel3([]));
        dispatch(setCommercialStructureLevel4([]));
        formRef.current?.setFieldsValue({
          level3: null,
          level4: null,
        });
      }
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form.Item name="level2" rules={[{ required: true, message: "" }]}>
      <Select
        disabled={!level2.length}
        loading={loading}
        onChange={onChange}
        placeholder={labelLevel2?.value}
        allowClear
      >
        {level2.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
