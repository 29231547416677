import { Rebate } from "../../Rebate";
import { MyExtract } from "../MyExtract";
import { CampaignAwards } from "../CampaignAwards";
import { ElectProduct } from "../ElectProduct";
import { CampaignType } from "../CampaignType";
import { Campaign } from "../Setup/Campaign";
import { Metas } from "../Setup/Metas";
export const RebateRoutes = [
  { path: "/rebate", element: <Rebate /> },

  { path: "/rebate/elect-product", element: <ElectProduct /> },
  { path: "/rebate/awards", element: <CampaignAwards /> },
  { path: "/rebate/my-extract", element: <MyExtract /> },
  { path: "/rebate/campaign-type", element: <CampaignType /> },
  { path: "/rebate/setup/campaign", element: <Campaign /> },
  { path: "/rebate/setup/metas", element: <Metas /> },
];
