/* eslint-disable react/jsx-no-undef */
// tela que mostra o pdf feita em react.js da politica de privacidade que esta em ./v1.pdf
import React from 'react'

export const PolicePrivacy = () => {

    return (
        <div className="container">
            <iframe src="https://drive.google.com/file/d/1VOot4HO7oreUKt_io9J1cSZmSwLWDwH1/preview" width="100%" height="1000px" title="policePrivacy"></iframe>
        </div>
    )
}

