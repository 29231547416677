import Http from "../../../utils/http";

class GlobalIndustryService extends Http {
  public constructor() {
    super();
  }

  public getParameter = () => this.instance.get(`/global/industry`);
}

export default new GlobalIndustryService();
