import Http from "../../../utils/http";

class CompanyParameterService extends Http {
  public constructor() {
    super();
  }

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/parameter`);
}

export default new CompanyParameterService();
