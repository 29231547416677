import {
  Table,
  Modal,
  Col,
  Card,
  Row,
  Button,
  Input,
  Select,
  message,
} from "antd";
import { useState, useCallback, useEffect } from "react";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { FiPlus, FiSearch, FiEdit2, FiFilter } from "react-icons/fi";
import AcesseType from "../../../services/Global/AcesseType";
import { Actions } from "../User/styles";
import { Breadcrumb } from "../../../components/Breadcrumb";
const { Option } = Select;
export default function AccessType() {
  const [modalEditAccessType, setModalEditAccessType] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [Name, setName] = useState<any>(undefined);
  const [pgc_action_plan, setPgc_action_plan] = useState<boolean>(false);
  const [pgc_budget, setPgc_budget] = useState<boolean>(false);
  const [pgc_customer_mapping, setPgc_customer_mapping] =
    useState<boolean>(false);
  const [pgc_customer_wallet, setPgc_customer_wallet] =
    useState<boolean>(false);
  const [pgc_level3_mapping, setPgc_level3_mapping] = useState<boolean>(false);
  //  const [pgt_action_plan, setPgt_action_plan] = useState<boolean>(false);
  //  const [pgt_budget, setPgt_budget] = useState<boolean>(false);
  //  const [pgt_customer_mapping, setPgt_customer_mapping] =
  //    useState<boolean>(false);
  //  const [pgt_level4_mapping, setPgt_level4_mapping] = useState<boolean>(false);
  const [pgv_action_plan, setPgv_action_plan] = useState<boolean>(false);
  const [pgv_bottomup, setPgv_bottomup] = useState<boolean>(false);
  const [pgv_topdown_board, setPgv_topdown_board] = useState<boolean>(false);
  const [pgv_topdown_company, setPgv_topdown_company] =
    useState<boolean>(false);
  const [pgv_topdown_product, setPgv_topdown_product] =
    useState<boolean>(false);
  const [setup, setSetup] = useState<boolean>(false);
  const [pgv_setup_parameter, setPgv_setup_parameter] =
    useState<boolean>(false);
  const [pgv_setup_user, setPgv_setup_user] = useState<boolean>(false);
  const [pgv_setup_commercialstructure, setPgv_setup_commercialstructure] =
    useState<boolean>(false);
  const [
    pgv_setup_commercialstructure_user,
    setPgv_setup_commercialstructure_user,
  ] = useState<boolean>(false);
  const [pgv_setup_cultivation, setPgv_setup_cultivation] =
    useState<boolean>(false);
  const [pgv_setup_businesstype, setPgv_setup_businesstype] =
    useState<boolean>(false);
  const [pgv_setup_productgroup, setPgv_setup_productgroup] =
    useState<boolean>(false);
  const [pgv_setup_product, setPgv_setup_product] = useState<boolean>(false);
  const [pgv_setup_accesstype, setPgv_setup_accesstype] =
    useState<boolean>(false);
  const [pgv_setup_customerwallet, setPgv_setup_customerwallet] =
    useState<boolean>(false);
  const [pgv_setup_migrationwallet, setPgv_setup_migrationwallet] =
    useState<boolean>(false);
  const [pgc_setup_parameter, setPgc_setup_parameter] =
    useState<boolean>(false);
  const [pgc_setup_user, setPgc_setup_user] = useState<boolean>(false);
  const [pgc_setup_commercialstructure, setPgc_setup_commercialstructure] =
    useState<boolean>(false);
  const [
    pgc_setup_commercialstructure_user,
    setPgc_setup_commercialstructure_user,
  ] = useState<boolean>(false);
  const [pgc_setup_cultivation, setPgc_setup_cultivation] =
    useState<boolean>(false);
  const [pgc_setup_businesstype, setPgc_setup_businesstype] =
    useState<boolean>(false);
  const [pgc_setup_productgroup, setPgc_setup_productgroup] =
    useState<boolean>(false);
  const [pgc_setup_product, setPgc_setup_product] = useState<boolean>(false);
  const [pgc_setup_accesstype, setPgc_setup_accesstype] =
    useState<boolean>(false);
  const [pgc_setup_customerwallet, setPgc_setup_customerwallet] =
    useState<boolean>(false);
  const [pgc_setup_migrationwallet, setPgc_setup_migrationwallet] =
    useState<boolean>(false);
    const [rebate_setup, setRebate_setup] =
    useState<boolean>(false);
    const [rebate_extract, setRebate_extract] =
    useState<boolean>(false);
    const [rebate_setup_campaigntype, setRebate_setup_campaigntype] =
    useState<boolean>(false);
    const [rebate_setup_campaigns, setRebate_setup_campaigns] =
    useState<boolean>(false);
    const [rebate_setup_eligibleproduct, setRebate_setup_eligibleproduct] =
    useState<boolean>(false);

  const [selectedAccessType, setSelectedAccessType] = useState<number | null>(
    null
  );
  const [accessType, setAccessType] = useState<any>(undefined);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [listAccessType, setListAccessType] = useState<any>(undefined);
  const [allok, setAllok] = useState<boolean>(false);
  const [selecteds, setSelecteds] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  function handleClickEdit(task: any) {
    console.log(task);
    setSelectedAccessType(task.id);
    setModalEditAccessType(true);
    setName(task.name);
    setPgc_action_plan(task.pgc_action_plan);
    setPgc_budget(task.pgc_budget);
    setPgc_customer_mapping(task.pgc_customer_mapping);
    setPgc_customer_wallet(task.pgc_customer_wallet);
    setPgc_level3_mapping(task.pgc_level3_mapping);
    setPgv_action_plan(task.pgv_action_plan);
    setPgv_bottomup(task.pgv_bottomup);
    setPgv_topdown_board(task.pgv_topdown_board);
    setPgv_topdown_company(task.pgv_topdown_company);
    setPgv_topdown_product(task.pgv_topdown_product);
    setSetup(task.setup);

    setPgv_setup_parameter(task.pgv_setup_parameter);
    setPgv_setup_user(task.pgv_setup_user);
    setPgv_setup_commercialstructure(task.pgv_setup_commercialstructure);
    setPgv_setup_commercialstructure_user(
      task.pgv_setup_commercialstructure_user
    );
    setPgv_setup_cultivation(task.pgv_setup_cultivation);
    setPgv_setup_businesstype(task.pgv_setup_businesstype);
    setPgv_setup_productgroup(task.pgv_setup_productgroup);
    setPgv_setup_product(task.pgv_setup_product);
    setPgv_setup_accesstype(task.pgv_setup_accesstype);
    setPgv_setup_customerwallet(task.pgv_setup_customerwallet);
    setPgv_setup_migrationwallet(task.pgv_setup_migrationwallet);
    setPgc_setup_parameter(task.pgc_setup_parameter);
    setPgc_setup_user(task.pgc_setup_user);
    setPgc_setup_commercialstructure(task.pgc_setup_commercialstructure);
    setPgc_setup_commercialstructure_user(
      task.pgc_setup_commercialstructure_user
    );
    setPgc_setup_cultivation(task.pgc_setup_cultivation);
    setPgc_setup_businesstype(task.pgc_setup_businesstype);
    setPgc_setup_productgroup(task.pgc_setup_productgroup);
    setPgc_setup_product(task.pgc_setup_product);
    setPgc_setup_accesstype(task.pgc_setup_accesstype);
    setPgc_setup_customerwallet(task.pgc_setup_customerwallet);
    setPgc_setup_migrationwallet(task.pgc_setup_migrationwallet);

    setRebate_setup(task.setRebate_setup);
    setRebate_extract(task.setRebate_extract);
    setRebate_setup_campaigntype(task.setRebate_setup_campaigntype);
    setRebate_setup_campaigns(task.setRebate_setup_campaigns);
    setRebate_setup_eligibleproduct(task.setRebate_setup_eligibleproduct);

    const select: React.ReactNode[] = [];

    if (task.pgc_action_plan === true) {
      select.push("pgc_action_plan");
    }
    if (task.pgc_budget === true) {
      select.push("pgc_budget");
    }
    if (task.pgc_customer_mapping === true) {
      select.push("pgc_customer_mapping");
    }
    if (task.pgc_customer_wallet === true) {
      select.push("pgc_customer_wallet");
    }
    if (task.pgc_level3_mapping === true) {
      select.push("pgc_level3_mapping");
    }
    if (task.pgt_action_plan === true) {
      select.push("pgt_action_plan");
    }
    if (task.pgt_budget === true) {
      select.push("pgt_budget");
    }
    if (task.pgt_customer_mapping === true) {
      select.push("pgt_customer_mapping");
    }
    if (task.pgt_level4_mapping === true) {
      select.push("pgt_level4_mapping");
    }
    if (task.pgv_action_plan === true) {
      select.push("pgv_action_plan");
    }
    if (task.pgv_bottomup === true) {
      select.push("pgv_bottomup");
    }
    if (task.pgv_topdown_board === true) {
      select.push("pgv_topdown_board");
    }
    if (task.pgv_topdown_company === true) {
      select.push("pgv_topdown_company");
    }
    if (task.pgv_topdown_product === true) {
      select.push("pgv_topdown_product");
    }
    if (task.setup === true) {
      select.push("setup");
    }
    if (task.pgv_setup_parameter === true) {
      select.push("pgv_setup_parameter");
    }
    if (task.pgv_setup_user === true) {
      select.push("pgv_setup_user");
    }
    if (task.pgv_setup_commercialstructure === true) {
      select.push("pgv_setup_commercialstructure");
    }
    if (task.pgv_setup_commercialstructure_user === true) {
      select.push("pgv_setup_commercialstructure_user");
    }
    if (task.pgv_setup_cultivation === true) {
      select.push("pgv_setup_cultivation");
    }
    if (task.pgv_setup_businesstype === true) {
      select.push("pgv_setup_businesstype");
    }
    if (task.pgv_setup_productgroup === true) {
      select.push("pgv_setup_productgroup");
    }
    if (task.pgv_setup_product === true) {
      select.push("pgv_setup_product");
    }
    if (task.pgv_setup_accesstype === true) {
      select.push("pgv_setup_accesstype");
    }
    if (task.pgv_setup_customerwallet === true) {
      select.push("pgv_setup_customerwallet");
    }
    if (task.pgv_setup_migrationwallet === true) {
      select.push("pgv_setup_migrationwallet");
    }
    if (task.pgc_setup_parameter === true) {
      select.push("pgc_setup_parameter");
    }
    if (task.pgc_setup_user === true) {
      select.push("pgc_setup_user");
    }
    if (task.pgc_setup_commercialstructure === true) {
      select.push("pgc_setup_commercialstructure");
    }
    if (task.pgc_setup_commercialstructure_user === true) {
      select.push("pgc_setup_commercialstructure_user");
    }
    if (task.pgc_setup_cultivation === true) {
      select.push("pgc_setup_cultivation");
    }
    if (task.pgc_setup_businesstype === true) {
      select.push("pgc_setup_businesstype");
    }
    if (task.pgc_setup_productgroup === true) {
      select.push("pgc_setup_productgroup");
    }
    if (task.pgc_setup_product === true) {
      select.push("pgc_setup_product");
    }
    if (task.pgc_setup_accesstype === true) {
      select.push("pgc_setup_accesstype");
    }
    if (task.pgc_setup_customerwallet === true) {
      select.push("pgc_setup_customerwallet");
    }
    if (task.pgc_setup_migrationwallet === true) {
      select.push("pgc_setup_migrationwallet");
    }

    if (task.rebate_setup === true) {
      select.push("rebate_setup");
    }
    if (task.rebate_extract === true) {
      select.push("rebate_extract");
    }
    if (task.rebate_setup_campaigntype === true) {
      select.push("rebate_setup_campaigntype");
    }
    if (task.rebate_setup_campaigns === true) {
      select.push("rebate_setup_campaigns");
    }
    if (task.rebate_setup_eligibleproduct === true) {
      select.push("rebate_setup_eligibleproduct");
    }

    setSelecteds(select);
    //  handleChange(select);
  }
  function handleCancel() {
    setModalEditAccessType(false);
    setSelectedAccessType(null);
    handleClear();
  }
  function handleClear() {
    setPgc_action_plan(false);
    setPgc_budget(false);
    setPgc_customer_mapping(false);
    setPgc_customer_wallet(false);
    setPgc_level3_mapping(false);
    //  setPgt_action_plan(false);
    //  setPgt_budget(false);
    //  setPgt_customer_mapping(false);
    //  setPgt_level4_mapping(false);
    setPgv_action_plan(false);
    setPgv_bottomup(false);
    setPgv_topdown_board(false);
    setPgv_topdown_company(false);
    setPgv_topdown_product(false);
    setSetup(false);
    setPgv_setup_parameter(false);
    setPgv_setup_user(false);
    setPgv_setup_commercialstructure(false);
    setPgv_setup_commercialstructure_user(false);
    setPgv_setup_cultivation(false);
    setPgv_setup_businesstype(false);
    setPgv_setup_productgroup(false);
    setPgv_setup_product(false);
    setPgv_setup_accesstype(false);
    setPgv_setup_customerwallet(false);
    setPgv_setup_migrationwallet(false);
    setPgc_setup_parameter(false);
    setPgc_setup_user(false);
    setPgc_setup_commercialstructure(false);
    setPgc_setup_commercialstructure_user(false);
    setPgc_setup_cultivation(false);
    setPgc_setup_businesstype(false);
    setPgc_setup_productgroup(false);
    setPgc_setup_product(false);
    setPgc_setup_accesstype(false);
    setPgc_setup_customerwallet(false);
    setPgc_setup_migrationwallet(false);


    setRebate_setup(false);
    setRebate_extract(false);
    setRebate_setup_campaigntype(false);
    setRebate_setup_campaigns(false);
    setRebate_setup_eligibleproduct(false);



  }

  async function handleChange(value: string | string[] | any) {
    setSelecteds(await value);
  }
  async function handleOk() {
    //  console.log(selecteds);
    handleClear();
    for (let i = 0; i < selecteds.length; i++) {
      if (selecteds[i] === "pgc_action_plan") {
        setPgc_action_plan(true);
      }
      if (selecteds[i] === "pgc_budget") {
        setPgc_budget(true);
      }
      if (selecteds[i] === "pgc_customer_mapping") {
        setPgc_customer_mapping(true);
      }
      if (selecteds[i] === "pgc_customer_wallet") {
        setPgc_customer_wallet(true);
      }
      if (selecteds[i] === "pgc_level3_mapping") {
        setPgc_level3_mapping(true);
      }
      if (selecteds[i] === "pgv_action_plan") {
        setPgv_action_plan(true);
      }
      if (selecteds[i] === "pgv_bottomup") {
        setPgv_bottomup(true);
      }
      if (selecteds[i] === "pgv_topdown_board") {
        setPgv_topdown_board(true);
      }
      if (selecteds[i] === "pgv_topdown_company") {
        setPgv_topdown_company(true);
      }
      if (selecteds[i] === "pgv_topdown_product") {
        setPgv_topdown_product(true);
      }
      if (selecteds[i] === "setup") {
        setSetup(true);
      }
      if (selecteds[i] === "pgv_setup_parameter") {
        setPgv_setup_parameter(true);
      }
      if (selecteds[i] === "pgv_setup_user") {
        setPgv_setup_user(true);
      }
      if (selecteds[i] === "pgv_setup_commercialstructure") {
        setPgv_setup_commercialstructure(true);
      }
      if (selecteds[i] === "pgv_setup_commercialstructure_user") {
        setPgv_setup_commercialstructure_user(true);
      }
      if (selecteds[i] === "pgv_setup_cultivation") {
        setPgv_setup_cultivation(true);
      }
      if (selecteds[i] === "pgv_setup_businesstype") {
        setPgv_setup_businesstype(true);
      }
      if (selecteds[i] === "pgv_setup_productgroup") {
        setPgv_setup_productgroup(true);
      }
      if (selecteds[i] === "pgv_setup_product") {
        setPgv_setup_product(true);
      }
      if (selecteds[i] === "pgv_setup_accesstype") {
        setPgv_setup_accesstype(true);
      }
      if (selecteds[i] === "pgv_setup_customerwallet") {
        setPgv_setup_customerwallet(true);
      }
      if (selecteds[i] === "pgv_setup_migrationwallet") {
        setPgv_setup_migrationwallet(true);
      }
      if (selecteds[i] === "pgc_setup_parameter") {
        setPgc_setup_parameter(true);
      }
      if (selecteds[i] === "pgc_setup_user") {
        setPgc_setup_user(true);
      }
      if (selecteds[i] === "pgc_setup_commercialstructure") {
        setPgc_setup_commercialstructure(true);
      }
      if (selecteds[i] === "pgc_setup_commercialstructure_user") {
        setPgc_setup_commercialstructure_user(true);
      }
      if (selecteds[i] === "pgc_setup_cultivation") {
        setPgc_setup_cultivation(true);
      }
      if (selecteds[i] === "pgc_setup_businesstype") {
        setPgc_setup_businesstype(true);
      }
      if (selecteds[i] === "pgc_setup_productgroup") {
        setPgc_setup_productgroup(true);
      }
      if (selecteds[i] === "pgc_setup_product") {
        setPgc_setup_product(true);
      }
      if (selecteds[i] === "pgc_setup_accesstype") {
        setPgc_setup_accesstype(true);
      }
      if (selecteds[i] === "pgc_setup_customerwallet") {
        setPgc_setup_customerwallet(true);
      }
      if (selecteds[i] === "pgc_setup_migrationwallet") {
        setPgc_setup_migrationwallet(true);
      }
      if (selecteds[i] === "rebate_setup") {
        setRebate_setup(true);
      }

      if (selecteds[i] === "rebate_extract") {
        setRebate_extract(true);
      }

      if (selecteds[i] === "rebate_setup_campaigntype") {
        setRebate_setup_campaigntype(true);
      }

      if (selecteds[i] === "rebate_setup_campaigns") {
        setRebate_setup_campaigns(true);
      }

      if (selecteds[i] === "rebate_setup_eligibleproduct") {
        setRebate_setup_eligibleproduct(true);
      }


      

    }
    setAllok(true);
  }
  useEffect(() => {
    if (allok === true) {
      putAccessType();
      setAllok(false);
      handleCancel();
    }
  }, [allok]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: object) => {
        return (
          <Actions>
            <FiEdit2
              size={18}
              color="#00CC66"
              onClick={async () => handleClickEdit(await record)}
            />
          </Actions>
        );
      },
    },
  ];

  const getAccessType = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await AcesseType.getAcessType();
      console.log("🚀 ~ file: index.tsx:455 ~ getAccessType ~ response:", response)
      const data = response.map((task: any) => {
        return {
          id: task.id,
          name: task.name,
          pgc_action_plan: task.pgc_action_plan,
          pgc_budget: task.pgc_budget,
          pgc_customer_mapping: task.pgc_customer_mapping,
          pgc_customer_wallet: task.pgc_customer_wallet,
          pgc_level3_mapping: task.pgc_level3_mapping,
          pgv_action_plan: task.pgv_action_plan,
          pgv_bottomup: task.pgv_bottomup,
          pgv_topdown_board: task.pgv_topdown_board,
          pgv_topdown_company: task.pgv_topdown_company,
          pgv_topdown_product: task.pgv_topdown_product,
          setup: task.setup,
          pgv_setup_parameter: task.pgv_setup_parameter,
          pgv_setup_user: task.pgv_setup_user,
          pgv_setup_commercialstructure: task.pgv_setup_commercialstructure,
          pgv_setup_commercialstructure_user:
            task.pgv_setup_commercialstructure_user,
          pgv_setup_cultivation: task.pgv_setup_cultivation,
          pgv_setup_businesstype: task.pgv_setup_businesstype,
          pgv_setup_productgroup: task.pgv_setup_productgroup,
          pgv_setup_product: task.pgv_setup_product,
          pgv_setup_accesstype: task.pgv_setup_accesstype,
          pgv_setup_customerwallet: task.pgv_setup_customerwallet,
          pgv_setup_migrationwallet: task.pgv_setup_migrationwallet,
          pgc_setup_parameter: task.pgc_setup_parameter,
          pgc_setup_user: task.pgc_setup_user,
          pgc_setup_commercialstructure: task.pgc_setup_commercialstructure,
          pgc_setup_commercialstructure_user:
            task.pgc_setup_commercialstructure_user,
          pgc_setup_cultivation: task.pgc_setup_cultivation,
          pgc_setup_businesstype: task.pgc_setup_businesstype,
          pgc_setup_productgroup: task.pgc_setup_productgroup,
          pgc_setup_product: task.pgc_setup_product,
          pgc_setup_accesstype: task.pgc_setup_accesstype,
          pgc_setup_customerwallet: task.pgc_setup_customerwallet,
          pgc_setup_migrationwallet: task.pgc_setup_migrationwallet,

          rebate_setup: task.rebate_setup,
          rebate_extract: task.rebate_extract,
          rebate_setup_campaigntype: task.rebate_setup_campaigntype,
          rebate_setup_campaigns: task.rebate_setup_campaigns,
          rebate_setup_eligibleproduct: task.rebate_setup_eligibleproduct,



        };
      });
      setListAccessType(data);
      setSearchData(data);
      const formattedTasks = response.map((task: any) => {
        return {
          id: task.id,
          name: task.name,
          pgc_action_plan: "pgc_action_plan",
          pgc_budget: "pgc_budget",
          pgc_customer_mapping: "pgc_customer_mapping",
          pgc_customer_wallet: "pgc_customer_wallet",
          pgc_level3_mapping: "pgc_level3_mapping",
          pgv_action_plan: "pgv_action_plan",
          pgv_bottomup: "pgv_bottomup",
          pgv_topdown_board: "pgv_topdown_board",
          pgv_topdown_company: "pgv_topdown_company",
          pgv_topdown_product: "pgv_topdown_product",
          setup: "setup",
          pgv_setup_parameter: "pgv_setup_parameter",
          pgv_setup_user: "pgv_setup_user",
          pgv_setup_commercialstructure: "pgv_setup_commercialstructure",
          pgv_setup_commercialstructure_user:
            "pgv_setup_commercialstructure_user",
          pgv_setup_cultivation: "pgv_setup_cultivation",
          pgv_setup_businesstype: "pgv_setup_businesstype",
          pgv_setup_productgroup: "pgv_setup_productgroup",
          pgv_setup_product: "pgv_setup_product",
          pgv_setup_accesstype: "pgv_setup_accesstype",
          pgv_setup_customerwallet: "pgv_setup_customerwallet",
          pgv_setup_migrationwallet: "pgv_setup_migrationwallet",
          pgc_setup_parameter: "pgc_setup_parameter",
          pgc_setup_user: "pgc_setup_user",
          pgc_setup_commercialstructure: "pgc_setup_commercialstructure",
          pgc_setup_commercialstructure_user:
            "pgc_setup_commercialstructure_user",
          pgc_setup_cultivation: "pgc_setup_cultivation",
          pgc_setup_businesstype: "pgc_setup_businesstype",
          pgc_setup_productgroup: "pgc_setup_productgroup",
          pgc_setup_product: "pgc_setup_product",
          pgc_setup_accesstype: "pgc_setup_accesstype",
          pgc_setup_customerwallet: "pgc_setup_customerwallet",
          pgc_setup_migrationwallet: "pgc_setup_migrationwallet",
          rebate_setup: "rebate_setup",
          rebate_extract: "rebate_extract",
          rebate_setup_campaigntype: "rebate_setup_campaigntype",
          rebate_setup_campaigns: "rebate_setup_campaigns",
          rebate_setup_eligibleproduct: "rebate_setup_eligibleproduct",
        };
      });
      setListAccessType(formattedTasks);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
  }, []);
  async function putAccessType() {
    console.log(
      Name,
      pgc_action_plan,
      pgc_budget,
      pgc_customer_mapping,
      pgc_customer_wallet,
      pgc_level3_mapping,
      pgv_action_plan,
      pgv_bottomup,
      pgv_topdown_board,
      pgv_topdown_company,
      pgv_topdown_product,
      setup,
      pgv_setup_parameter,
      pgv_setup_user,
      pgv_setup_commercialstructure,
      pgv_setup_commercialstructure_user,
      pgv_setup_cultivation,
      pgv_setup_businesstype,
      pgv_setup_productgroup,
      pgv_setup_product,
      pgv_setup_accesstype,
      pgv_setup_customerwallet,
      pgv_setup_migrationwallet,
      pgc_setup_parameter,
      pgc_setup_user,
      pgc_setup_commercialstructure,
      pgc_setup_commercialstructure_user,
      pgc_setup_cultivation,
      pgc_setup_businesstype,
      pgc_setup_productgroup,
      pgc_setup_product,
      pgc_setup_accesstype,
      pgc_setup_customerwallet,
      pgc_setup_migrationwallet,
      rebate_setup,
      rebate_extract,
      rebate_setup_campaigntype,
      rebate_setup_campaigns,
      rebate_setup_eligibleproduct
    );
    const body = {
      name: Name,
      pgc_action_plan: pgc_action_plan,
      pgc_budget: pgc_budget,
      pgc_customer_mapping: pgc_customer_mapping,
      pgc_customer_wallet: pgc_customer_wallet,
      pgc_level3_mapping: pgc_level3_mapping,
      pgv_action_plan: pgv_action_plan,
      pgv_bottomup: pgv_bottomup,
      pgv_topdown_board: pgv_topdown_board,
      pgv_topdown_company: pgv_topdown_company,
      pgv_topdown_product: pgv_topdown_product,
      setup: setup,
      pgv_setup_parameter: pgv_setup_parameter,
      pgv_setup_user: pgv_setup_user,
      pgv_setup_commercialstructure: pgv_setup_commercialstructure,
      pgv_setup_commercialstructure_user: pgv_setup_commercialstructure_user,
      pgv_setup_cultivation: pgv_setup_cultivation,
      pgv_setup_businesstype: pgv_setup_businesstype,
      pgv_setup_productgroup: pgv_setup_productgroup,
      pgv_setup_product: pgv_setup_product,
      pgv_setup_accesstype: pgv_setup_accesstype,
      pgv_setup_customerwallet: pgv_setup_customerwallet,
      pgv_setup_migrationwallet: pgv_setup_migrationwallet,
      pgc_setup_parameter: pgc_setup_parameter,
      pgc_setup_user: pgc_setup_user,
      pgc_setup_commercialstructure: pgc_setup_commercialstructure,
      pgc_setup_commercialstructure_user: pgc_setup_commercialstructure_user,
      pgc_setup_cultivation: pgc_setup_cultivation,
      pgc_setup_businesstype: pgc_setup_businesstype,
      pgc_setup_productgroup: pgc_setup_productgroup,
      pgc_setup_product: pgc_setup_product,
      pgc_setup_accesstype: pgc_setup_accesstype,
      pgc_setup_customerwallet: pgc_setup_customerwallet,
      pgc_setup_migrationwallet: pgc_setup_migrationwallet,
      rebate_setup: rebate_setup,
      rebate_extract: rebate_extract,
      rebate_setup_campaigntype: rebate_setup_campaigntype,
      rebate_setup_campaigns: rebate_setup_campaigns,
      rebate_setup_eligibleproduct: rebate_setup_eligibleproduct,
    };
    try {
      setLoading(true);
      await AcesseType.putAcessType(selectedAccessType, body);
      getAccessType();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    } finally {
      getAccessType();
    }
  }
  useEffect(() => {
    getAccessType();
  }, [getAccessType]);
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    const filteredData = searchData.filter((item: any) => {
      return item.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setAccessType(filteredData);
  };
  const [size, setSize] = useState<SizeType>("middle");

  return (
    <Breadcrumb path="Tipo de Acesso">
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={17}>
              {/* <Button
              type="primary"
              icon={<FiPlus />}
              //  onClick={showModalNewProduct}
            >
              Novo
            </Button>*/}
            </Col>

            <Col span={7}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => handleSearch(e)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={accessType != undefined ? accessType : searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
          <Modal
            title="Atualizar Tipo de Acesso"
            visible={modalEditAccessType}
            onCancel={() => handleCancel()}
            onOk={() => handleOk()}
          >
            <Input
              placeholder="Nome"
              style={{ width: "100%", margin: "10px 0" }}
              size="large"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />

            {listAccessType !== undefined ? (
              <Select
                mode="tags"
                size={size}
                placeholder="Selecione as Telas"
                defaultValue={selecteds}
                onChange={handleChange}
                value={selecteds}
                style={{ width: "100%" }}
              >
                <>
                  <Option key={listAccessType[0].pgc_action_plan}>
                    {listAccessType[0].pgc_action_plan}
                  </Option>
                  <Option key={listAccessType[0].pgc_budget}>
                    {listAccessType[0].pgc_budget}
                  </Option>
                  <Option key={listAccessType[0].pgc_customer_mapping}>
                    {listAccessType[0].pgc_customer_mapping}
                  </Option>
                  <Option key={listAccessType[0].pgc_customer_wallet}>
                    {listAccessType[0].pgc_customer_wallet}
                  </Option>
                  <Option key={listAccessType[0].pgc_level3_mapping}>
                    {listAccessType[0].pgc_level3_mapping}
                  </Option>
                  <Option key={listAccessType[0].pgv_action_plan}>
                    {listAccessType[0].pgv_action_plan}
                  </Option>
                  <Option key={listAccessType[0].pgv_bottomup}>
                    {listAccessType[0].pgv_bottomup}
                  </Option>
                  <Option key={listAccessType[0].pgv_topdown_board}>
                    {listAccessType[0].pgv_topdown_board}
                  </Option>
                  <Option key={listAccessType[0].pgv_topdown_company}>
                    {listAccessType[0].pgv_topdown_company}
                  </Option>
                  <Option key={listAccessType[0].pgv_topdown_product}>
                    {listAccessType[0].pgv_topdown_product}
                  </Option>
                  <Option key={listAccessType[0].setup}>
                    {listAccessType[0].setup}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_parameter}>
                    {listAccessType[0].pgv_setup_parameter}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_user}>
                    {listAccessType[0].pgv_setup_user}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_commercialstructure}>
                    {listAccessType[0].pgv_setup_commercialstructure}
                  </Option>
                  <Option
                    key={listAccessType[0].pgv_setup_commercialstructure_user}
                  >
                    {listAccessType[0].pgv_setup_commercialstructure_user}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_cultivation}>
                    {listAccessType[0].pgv_setup_cultivation}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_businesstype}>
                    {listAccessType[0].pgv_setup_businesstype}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_productgroup}>
                    {listAccessType[0].pgv_setup_productgroup}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_product}>
                    {listAccessType[0].pgv_setup_product}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_accesstype}>
                    {listAccessType[0].pgv_setup_accesstype}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_customerwallet}>
                    {listAccessType[0].pgv_setup_customerwallet}
                  </Option>
                  <Option key={listAccessType[0].pgv_setup_migrationwallet}>
                    {listAccessType[0].pgv_setup_migrationwallet}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_parameter}>
                    {listAccessType[0].pgc_setup_parameter}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_user}>
                    {listAccessType[0].pgc_setup_user}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_commercialstructure}>
                    {listAccessType[0].pgc_setup_commercialstructure}
                  </Option>
                  <Option
                    key={listAccessType[0].pgc_setup_commercialstructure_user}
                  >
                    {listAccessType[0].pgc_setup_commercialstructure_user}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_cultivation}>
                    {listAccessType[0].pgc_setup_cultivation}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_businesstype}>
                    {listAccessType[0].pgc_setup_businesstype}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_productgroup}>
                    {listAccessType[0].pgc_setup_productgroup}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_product}>
                    {listAccessType[0].pgc_setup_product}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_accesstype}>
                    {listAccessType[0].pgc_setup_accesstype}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_customerwallet}>
                    {listAccessType[0].pgc_setup_customerwallet}
                  </Option>
                  <Option key={listAccessType[0].pgc_setup_migrationwallet}>
                    {listAccessType[0].pgc_setup_migrationwallet}
                  </Option>
                  <Option key={listAccessType[0].rebate_setup}>
                    {listAccessType[0].rebate_setup}
                  </Option>
                  <Option key={listAccessType[0].rebate_extract}>
                    {listAccessType[0].rebate_extract}
                  </Option>
                  <Option key={listAccessType[0].rebate_setup_campaigntype}>
                    {listAccessType[0].rebate_setup_campaigntype}
                  </Option>
                  <Option key={listAccessType[0].rebate_setup_campaigns}>
                    {listAccessType[0].rebate_setup_campaigns}
                  </Option>
                  <Option key={listAccessType[0].rebate_setup_eligibleproduct}>
                    {listAccessType[0].rebate_setup_eligibleproduct}
                  </Option>
                </>
              </Select>
            ) : null}
          </Modal>
        </Card>
      </>
    </Breadcrumb>
  );
}

// Language: typescript
// Path: src\modules\Setup\BusinessType\index.tsx
// Compare this snippet from src\modules\Setup\AccessType\index.tsx
