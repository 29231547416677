import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  message,
  Select,
  
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../../hooks";
import ParameterService from "../../../services/SalesPlanning/Setup/Parameter";
import { ParameterData } from "../../../services/SalesPlanning/Setup/Parameter/ParameterDataTypes";

const { Option } = Select;
export function Parameter() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [modalNewParameter, setShowModalNewParameter] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedParameterId, setSelectedParameterId] = useState<number | null>(
    null
  );
  const [listParameters, setListParameters] = useState<ParameterData[]>([]);
  const [name, setName] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);

  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>([]);
  const [selectedInitiative, setSelectedInitiative] = useState<any>([]);
  const getListParameter = useCallback(async () => {
    try {
      setLoading(true);
      const response: ParameterData[] = await ParameterService.findAll(
        companyId
      );
      console.log(response)
      setListParameters(response);
      setSearchData(response);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  

  useEffect(() => {
    getListParameter();
  }, [getListParameter]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (text: any) => {
        if (text.length > 50) {
          return text.substring(0, 50) + '...';
        }
        return text;
      }
    },
    {
      title: "Tipo",
      dataIndex: "type_id",
      key: "type_id",
      render: (text:any) => {
        if (text === 1) {
          return "Data";
        }
        if (text === 2) {
          return "Estrutura Comercial";
        }
        if (text === 3) {
          return "Link Dashboard";
        }
        if (text === 4) {
          return "Outros";
        }
        return text;
      }
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: ParameterData) => {
        return (
          <Actions>
            <FiEdit2
              size={18}
              color="#00CC66"
              onClick={() => handleClickEdit(record)}
            />
            <Popconfirm
              title="Tem certeza que deseja excluir esse parâmetro?"
              onConfirm={() => confirmDelete(record)}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash size={18} color="#00CC66" />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];

  async function confirmDelete(parameter: ParameterData) {
    try {
      await ParameterService.deleteById(companyId, parameter.id);
    } catch (e) {
      console.error("Falha ao excluir parâmetro: ");
      console.error(e);
    }
    await getListParameter();
  }

  const onChangeHandler = (value : any) => (event : any) => {
    // setSelectedInitiative({ value: event.target.value });
    setSelectedInitiativeId({ value: event.target.value });
  };

  useEffect(() => {
    let val = "Nenhum"
    if(selectedInitiativeId == 1){
      val = "Data"
    }
    if(selectedInitiativeId == 2){
      val = "Estrutura Comercial"
    }
    if(selectedInitiativeId == 3){
      val = "Link Dashboard"
    }
    if(selectedInitiativeId == 4){
      val = "Outros"
    }
    setSelectedInitiative({ value: val });
  }, [selectedInitiativeId]);

  function handleClickEdit(parameter: ParameterData) {
    console.log(parameter);
    setSelectedParameterId(parameter.id ?? null);
    setName(parameter.name);
    setValue(parameter.value);
    setSelectedInitiativeId(parameter.type_id);
    // setSelectedInitiative(parameter.type_id);
    setShowModalNewParameter(true);
  }

  function showModalNewParameter() {
    setShowModalNewParameter(true);
  }

  async function handleSave() {
    try {
      if (selectedParameterId) {
        await ParameterService.update(companyId, {
          id: selectedParameterId,
          name: name.toUpperCase(),
          value,
          type_id: selectedInitiativeId,
        });
      } else {
        await ParameterService.create(companyId, {
          name: name.toUpperCase(),
          value,
          type_id: selectedInitiativeId,
        });
      }
    } catch (e) {
      console.error("Falha ao salvar parâmetro: ");
      console.error(e);
    }

    await getListParameter();
    setShowModalNewParameter(false);
    clearData();
  }

  function clearData() {
    setSelectedParameterId(null);
    setName("");
    setValue("");
  }

  function handleCancel() {
    setShowModalNewParameter(false);
    setSelectedInitiativeId(null);
    setSelectedInitiative(null);
    clearData();
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = listParameters.filter(
        (record) =>
          record["name"].toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listParameters);
    }
  }

  return (
    <Breadcrumb path="Setup" child={["Parâmetro"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewParameter}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          visible={modalNewParameter}
          okText={"Salvar"}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedParameterId ? "Atualizar" : "Novo"} Parâmetro
              </Typography.Title>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Nome
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Nome"
                  value={name != "" ? name.toUpperCase() : name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Valor
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Valor"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Col>
              <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Tipo de Parametro
                  </Typography.Text>
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    dropdownMatchSelectWidth={false}
                    showSearch
                    placeholder="Nenhum"
                    optionFilterProp="children"
                    onChange={(value) => setSelectedInitiativeId(value)}
                    value={selectedInitiative}
                  >
                   
                      <Option value="1">
                       Data
                      </Option>
                      <Option value="2">
                       Estrutura Comercial
                      </Option>
                      <Option value="3">
                      Link Dashboard
                      </Option>
                      <Option value="4">
                      Outros
                      </Option>
                    
                  </Select>
                </Col>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
