import { message } from "antd";

import CompanyCropService from "../../services/Company/Crop";
import { store } from "../../store";
import { setRecordsCrop } from "../Reducer";

const { dispatch } = store;

export const crop = async (companyId: number) => {
  try {
    const response = await CompanyCropService.getCompanyBusinessType(companyId);
    dispatch(setRecordsCrop(response));
  } catch (err) {
    message.error(err.response.data.message);
  }
};
