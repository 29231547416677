import Http from "../../../utils/http";

class TopDownProductService extends Http {
  public constructor() {
    super();
  }

  public getTableProduct = (
    id: number,
    level1_id: number,
    business_type_id: number
  ) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-product?level1_id=${level1_id}&business_type_id=${business_type_id}`
    );
  public saveTableProduct = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgv/topdown-product`, body);

  public putTableProductStatus = (id: number, search: string, body: object) =>
    this.instance.put(
      `/company/${id}/pgv/topdown-product/status?filter_text=${search}`,
      body
    );
  public getTableProductStatus = (id: number, search: string | any) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-product/status?filter_text=${search}`
    );

  public searchTableProduct = (
    id: number,
    level1_id: number,
    search: string,
    business_type_id: number
  ) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-product?level1_id=${level1_id}&business_type_id=${business_type_id}&filter_text=${search}`
    );
}

export default new TopDownProductService();
