import Http from "../../../utils/http";

class UpdateSellerService extends Http {
  public constructor() {
    super();
  }

  public PostUpSeller = (companyId: number, body: any) => {
    return this.instance.post(`/company/${companyId}/pgc/change/seller`, body);
  };
}

export default new UpdateSellerService();
