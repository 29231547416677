import { Form, Input, message } from "antd";

import { FormAuth } from "../../../components/Auth/FormAuth";
import UserService from "../../../services/Global/User";
import { Signin } from "./styles";
//import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface FormDataForgotPassword {
  email: string;
}

interface FormDataSetNewPassword {
  code: string;
  Senha: string;
  confirmar_Senha: string;
}

export function ForgotPassword() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showNewPasswordForm, setShowNewPasswordForm] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  async function handleForgotPassword(data: FormDataForgotPassword) {
    try {
      setLoading(true);
      setEmail(data.email.toLowerCase());
      const url = window.location.origin;
      await UserService.SendEmail(data.email, url);
      setShowNewPasswordForm(true);
    } catch (err) {
      message.error("Usuário não encontrado!");
    } finally {
      setLoading(false);
    }
  }

  async function handleSetPassword(data: FormDataSetNewPassword) {
    if (data.Senha === data.confirmar_Senha) {
      await setNewPassword(data);
    } else {
      message.error("Senhas não conferem");
    }
  }

  async function setNewPassword(data: FormDataSetNewPassword) {
    try {
      setLoading(true);
      await UserService.verifyPassword(data.code, data.Senha);
      navigate("/");
    } catch (err) {
      message.error("Algo deu errado, Verifique o codigo ou tente mais tarde.");
    } finally {
      setLoading(false);
    }
  }

  function renderData() {
    if (showNewPasswordForm) {
      return renderNewPasswordForm();
    } else {
      return renderEmailForm();
    }
  }

  function renderEmailForm() {
    return (
      <Form onFinish={handleForgotPassword}>
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input size="large" placeholder="E-mail" />
        </Form.Item>
        <Form.Item>
          <Signin loading={isLoading} htmlType="submit" size="large" block>
            Enviar e-mail
          </Signin>
        </Form.Item>
      </Form>
    );
  }

  function renderNewPasswordForm() {
    return (
      <Form onFinish={handleSetPassword}>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: "Código de verificação é obrigatório" },
            {
              pattern: /^[0-9]{6}$/,
              message:
                "Código de verificação deve ter 6 dígitos e conter apenas números",
            },
          ]}
        >
          <Input
            size="large"
            type="text"
            maxLength={6}
            placeholder="Código de verificação"
            onInput={(e) => {
              e.currentTarget.value = e.currentTarget.value.replace(
                /[^0-9]/g,
                ""
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name="Senha"
          rules={[
            { required: true, message: "Senha é obrigatória" },
            { min: 8, message: "Senha deve ter no mínimo 8 caracteres" },
          ]}
        >
          <Input.Password size="large" placeholder="Nova senha" />
        </Form.Item>
        <Form.Item
          name="confirmar_Senha"
          rules={[
            { required: true, message: "Confirmação de senha é obrigatória" },
            { min: 8, message: "Senha deve ter no mínimo 8 caracteres" },
          ]}
        >
          <Input.Password size="large" placeholder="Confirmar senha" />
        </Form.Item>
        <Form.Item>
          <Signin loading={isLoading} htmlType="submit" size="large" block>
            Cadastrar nova senha
          </Signin>
        </Form.Item>
      </Form>
    );
  }

  return (
    <FormAuth
      title="Esqueceu a senha?"
      subTitle="Enviaremos um e-mail com o código para redefinir sua senha."
    >
      {renderData()}
    </FormAuth>
  );
}
