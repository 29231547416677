import "./App.less";
import { ConfigProvider, theme } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "moment/locale/pt-br";
import ptBR from "antd/lib/locale/pt_BR";
import GloalStyle from "./styles/global";

import { store } from "./store";
import { Routes } from "./routes";
import { persistor } from "./store";

export function App() {
  const PersistGateComponent: any = PersistGate;
  return (
    <ConfigProvider
      locale={ptBR}
      theme={{
        token: {
          colorPrimary: "#0c6",
          fontFamily: "Montserrat",
        },
      }}
    >
      <Provider store={store}>
        <PersistGateComponent loading={null} persistor={persistor}>
          <Routes />
          <GloalStyle />
        </PersistGateComponent>
      </Provider>
    </ConfigProvider>
  );
}
