import Http from "../../../utils/http";

class SalesForecastService extends Http {
  public constructor() {
    super();
  }

  public getBudgetLevel4 = (companyId: number, level4_id: number) => {
    
    return this.instance.get(
      `/company/${companyId}/pgc/budget/level4-customers?level4_id=${level4_id}`
    );
  };

  public getBudgetLevel4Category = (companyId: number, level4_id: number, Category: number) => {
   
    return this.instance.get(
      `/company/${companyId}/pgc/budget/level4-customersCategory?level4_id=${level4_id}&category_id=${Category}`
    );
  };

  public GetCategory = async (company_id: number) =>
    await this.instance.get(`/company/${company_id}/customercategory`);

  public getBudget = (
    companyId: number,
    customer_id: number,
    category_id: number,
    level4_id: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget?customer_group_id=${customer_id}&level4_id=${level4_id}&category_id=${category_id}`
    );
  };
  public PostBudget = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/pgc/budget`, body);
  };

  public putBudgetStatus = (companyId: number, body: object) => {
    return this.instance.put(`/company/${companyId}/pgc/budget/status`, body);
  };

  public getBudgetStatus = (companyId: number, level4_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget/status?level4_id=${level4_id}`
    );
  };
}

export default new SalesForecastService();
