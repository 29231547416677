import { createSlice } from "@reduxjs/toolkit";

export interface CompanyProps {
  id: number;
  name?: string;
  registered_number?: any;
  logo?: string;
}

const initialState = {
  company: {} as CompanyProps,
};

export const ParametersReducer = createSlice({
  name: "parameters",
  initialState,
  reducers: {
    decrement(state) {
      console.log(state)
      return {
        ...state,
        company: initialState.company,
      };
    },
    selectedCompany: (state, action) => {
      state.company = action.payload;
    },
  },
});

export const { selectedCompany, decrement } = ParametersReducer.actions;

export default ParametersReducer.reducer;
