import { Card, Col, Row, Table, Form, Button, message, Modal } from "antd";
import { createRef, useCallback, useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { FormInstance } from "antd/es/form";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import WalletService from "../../../services/Global/Wallet";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import UpdateSellerService from "../../../services/Global/UpdateSeller";
export function UpdateSeller() {
  const dispatch = useAppDispatch();
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [filter, setFilter] = useState(true);
  const formRef = createRef<FormInstance>();
  const [nomeVendedor, setNomeVendedor] = useState<any>(undefined);
  const [nomeVendedorPara, setNomeVendedorPara] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [level4, setLevel4] = useState<any>();
  const [level4_Para, setLevel4_Para] = useState<any>();
  const [modal, contextHolder] = Modal.useModal();
  const [contar, setContar] = useState<any>(0);
  const [showModal, setShowModal] = useState(false);

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  useEffect(() => {
    const body = {
      level4_de: level4,
      level4_para: level4_Para,
    };
    if (level4_Para !== undefined) {
      console.log("de" + level4);
      try {
        setTimeout(() => {
          UpdateSellerService.PostUpSeller(companyId, body);
          message.success(
            "Transferencia de dados entre vendedores realizada com sucesso!"
          );
          loadTableProduct(level4);
          setNomeVendedorPara(undefined);
        }, 2000);
      } catch (err) {
        message.error(err.response.data.message);
      }
    }
  }, [contar]);
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Cod. Grupo",
      dataIndex: "customer_group_id",
      key: "customer_group_id",
    },
    {
      title: "Grupo",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Cod. Cliente",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "Categoria Cliente",
      dataIndex: "customer_category",
      key: "customer_category",
    },
    {
      title: "Cliente",
      dataIndex: "customer_name",
      key: "customer_name",
    },

    {
      title: "Cidade",
      dataIndex: "customer_city_name",
      key: "customer_city_id",
    },
    {
      title: "UF",
      dataIndex: "customer_state",
      key: "customer_state",
    },
  ];
  async function WalletMappingStatus(level4: number) {
    try {
      const response = await WalletService.getWalletStatus(companyId, level4);
      console.log("de" + response.level4_external_name);
      setNomeVendedor(response.level4_external_name);
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  async function WalletMappingStatusPara(level4: number) {
    try {
      const response = await WalletService.getWalletStatus(companyId, level4);
      console.log("para" + response.level4_external_name);
      setNomeVendedorPara(response.level4_external_name);
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  const loadTableProduct = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        setData(undefined);
        const response = await WalletService.getWallet(companyId, id);
        setData(response);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    loadTableProduct(obj.level4);
    WalletMappingStatus(obj.level4);
    setFilter(false);
  }
  async function onFinishPara(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    setLevel4_Para(obj.level4);
    WalletMappingStatusPara(obj.level4);
  }

  async function handleTransfer() {
    setContar(contar + 1);
    setShowModal(false);
  }

  return (
    <Breadcrumb
      path="Tranferir Clientes"
      actions={[
        {
          className: level4,
        },
      ]}
      child={nomeVendedor}
    >
      <Row gutter={[16, 16]}>
        {filter && (
          <Col span={4}>
            <Card bordered={false}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                initialValues={commercialStructure}
              >
                <CommercialStructure formRef={formRef} level={4} />
                <Button htmlType="submit" type="primary" block>
                  Buscar
                </Button>
              </Form>
            </Card>
          </Col>
        )}
        <Col span={filter ? 20 : 20}>
          <Card bordered={false}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col
                span={filter ? 2.5 : 2}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Buttons>
                  {filter ? (
                    <FiX
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  ) : (
                    <FiMenu
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  )}
                </Buttons>
              </Col>
              <Col span={1}>
                <Buttons onClick={() => setFilter(!filter)}>
                  {!filter ? (
                    <FiX color="#00CC66" size={20} />
                  ) : (
                    <FiMenu color="#00CC66" size={20} />
                  )}
                </Buttons>
              </Col>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  loading={{ indicator: Icon, spinning: loading }}
                />
              </Col>
            </Row>
          </Card>
          <Modal
            visible={showModal}
            okText={"CONFIRMAR"}
            onOk={() => handleTransfer()}
            onCancel={() => setShowModal(false)}
          >
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col span={24}>
                <ExclamationCircleOutlined /> CONFIRMAR
                <Card bordered={false}>
                  Deseja realmente tranferir os Dados do vendedor {nomeVendedor}{" "}
                  para o vendedor {nomeVendedorPara} ?
                </Card>
              </Col>
            </Row>
          </Modal>
        </Col>
        {!filter && (
          <Col span={4}>
            <Card bordered={false}>
              <Form onFinish={onFinishPara}>
                <CommercialStructure formRef={formRef} level={4} />

                <Button
                  htmlType="submit"
                  type="primary"
                  block
                  onClick={() => setShowModal(true)}
                >
                  Transferir
                </Button>
              </Form>
            </Card>
            {contextHolder}
          </Col>
        )}
        <Col span={7}></Col>
      </Row>
    </Breadcrumb>
  );
}
