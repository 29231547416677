import Http from "../../../utils/http";

class CostomerMappingService extends Http {
  public constructor() {
    super();
  }

  public PostCostomerMapping = (companyId: number, body: any[]) => {
    return this.instance.post(
      `/company/${companyId}/pgc/customer-mapping/crops`,
      body
    );
  };

  public PutCostomerMapping = (companyId: number, body: any[]) => {
    return this.instance.post(
      `/company/${companyId}/pgc/customer-mapping/crops`,
      body
    );
  };

  public getCostomerMapping = (companyId: number, level4: any) => {
    return this.instance.get(
      `/company/${companyId}/pgc/customer-mapping?level4_id=${level4}`
    );
  };

  public getNewcustomers = (
    companyId: number,
    level4: any,
    customer_id: any
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/customer-mapping/crops?level4_id=${level4}&customer_id=${customer_id}`
    );
  };

  public getCostomerMappingStatus = (companyId: number, level4: any) => {
    return this.instance.get(
      `/company/${companyId}/pgc/customer-mapping/status?level4_id=${level4}`
    );
  };

  public PutCostomerMappingStatus = (companyId: number, body: object) => {
    return this.instance.put(
      `/company/${companyId}/pgc/customer-mapping/status`,
      body
    );
  };

  public DeleteCostomerMapping = (companyId: number, id: number) => {
    return this.instance.delete(
      `/company/${companyId}/pgc/customer-mapping/crops/${id}`
    );
  };
}

export default new CostomerMappingService();
