import Http from "../../../utils/http";

class GlobalBusinessType extends Http {
  public constructor() {
    super();
  }

  public getGlobalBusinessType = () =>
    this.instance.get(`/global/business-type`);
}
export default new GlobalBusinessType();
