import {
  Button,
  Card,
  Col,
  Input,
  Radio,
  Row,
  Table,
  Modal,
  Typography,
  Tag,
  Form,
  Select,
  Popconfirm,
} from "antd";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import {
  FiEdit2,
  FiFilter,
  FiMenu,
  FiPlus,
  FiSearch,
  FiX,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { FormInstance } from "antd/es/form";
import ActionPlanService from "../../../services/Pgc/ActionPlan";
import { Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import { StyledDatePicker } from "../../SalesPlanning/ActionPlan/styles";
import { Actions } from "../CustomerMapping/styles";
const { Option } = Select;
export function ActionPlan() {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [modalNewAction, setModalNewAction] = useState(false);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [status, setStatus] = useState<any>();
  const [level4, setLevel4] = useState<any>(undefined);
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const formRef = createRef<FormInstance>();
  const [searchData, setSearchData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);
  const [filter, setFilter] = useState(false);
  const [initiative_id, setInitiative_id] = useState<any>();
  const [initial_date, setInitial_date] = useState<any>(null);
  const [final_date, setFinal_date] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [status_value, setStatus_value] = useState<any>();
  const [initiatives, setInitiatives] = useState<any[]>([]);
  const [customer_id, setCustomer_id] = useState<any[]>([]);
  const [id, setId] = useState<any>(null);
  const [data_customer, setData_customer] = useState<any[]>([]);
  if (status === "F") {
    var newStatus = "Reabrir";
    var disbled = true;
  } else {
    var newStatus = "Finalizar";
    var disbled = false;
  }

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tarefa",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Atividade",
      dataIndex: "Atividade",
      key: "Atividade",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Data de início",
      dataIndex: "data_inicio",
      key: "data_inicio",
    },
    {
      title: "Data de entrega",
      dataIndex: "data_fim",
      key: "data_fim",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            <FiEdit2
              size={18}
              color={status === "F" ? "#9b9b9b" : "#00CC66"}
              style={{
                cursor: status === "F" ? "no-drop" : "pointer",
              }}
              onClick={() => handleClickEdit(record)}
            />
          </Actions>
        );
      },
    },
  ];

  function handleClickEdit(task: any) {
    if (status === "A") {
      setDescription(task.description);
      setStatus_value(task.status);
      setCustomer_id(task.customer_id);
      setId(task.key);
      setInitiative_id(task.initiative_id);
      setInitial_date(task.initial_date ? moment(task.initial_date) : null);
      setFinal_date(task.final_date ? moment(task.final_date) : null);
      setModalNewAction(true);
    }
  }

  useEffect(() => {
    findAllInitiatives();
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return " Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }
  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }
  async function FindData(level4_id: number) {
    try {
      setLoading(true);
      const response = await ActionPlanService.getFindAll(companyId, level4_id);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          ...rawTask,
          key: rawTask.id,
          level4: rawTask.level4.level4_external_name,
          cliente: rawTask.customer.customer_name,
          initiative_id: rawTask.initiative_id,
          data_inicio: moment(rawTask.initial_date).format("DD/MM/YYYY"),
          data_fim: moment(rawTask.final_date).format("DD/MM/YYYY"),
          Atividade: rawTask.initiative.name,
        };
      });

      setSearchData(formattedTasks);
      setListTasks(formattedTasks);
      const status = await ActionPlanService.getStatus(companyId, level4_id);
      setStatus(status.pgc_action_plan);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function PostActionPlain() {
    try {
      setLoading(true);
      let body = {
        id: id,
        company_id: companyId,
        initiative_id: initiative_id,
        customer_id: customer_id,
        initial_date: initial_date,
        final_date: final_date,
        description: description,
        status: status_value,
        level4_id: level4,
      };
      await ActionPlanService.PostActionPlain(companyId, body);
      FindData(level4);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function findAllInitiatives() {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllInitiatives(companyId);
      setInitiatives(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllCustomers(level4_id: number) {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllCustomers(
        companyId,
        level4_id
      );
      setData_customer(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function disabledDate(current: any) {
    if (initial_date) {
      return current && current < initial_date;
    } else {
      return false;
    }
  }
  function showModalAction() {
    setModalNewAction(true);
    findAllCustomers(level4);
  }

  function handleOk() {
    setModalNewAction(false);
    PostActionPlain();
    handleCancel();
    findAllCustomers(level4);
  }

  function handleCancel() {
    setModalNewAction(false);
    setDescription(undefined);
    setStatus_value(undefined);
    setCustomer_id([]);
    setId(null);
    setInitiative_id(undefined);
    setInitial_date(undefined);
    setFinal_date(undefined);
  }

  function handleChangeStatus(e: any) {
    const { value } = e.target;
    if (value === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(value);
    }
  }
  function handleRemoveStatus() {
    setFilteredStatus(null);
  }
  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search && !filteredStatus) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            (!filteredStatus ||
              (record["status"] &&
                record["status"]
                  ?.toUpperCase()
                  .indexOf(filteredStatus.toUpperCase()) > -1)) &&
            (!search ||
              record["description"]
                .toUpperCase()
                .indexOf(search.toUpperCase()) > -1 ||
              record["Atividade"].toLowerCase().indexOf(search.toLowerCase()) >
                -1 ||
              record["cliente"].toLowerCase().indexOf(search.toLowerCase()) >
                -1 ||
              record["level4"].toLowerCase().indexOf(search.toLowerCase()) > -1)
        );
        setSearchData(array);
      }
    }
  }, [search, filteredStatus, listTasks]);
  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    setFilter(false);
    FindData(obj.level4);
    findAllCustomers(obj.level4);
  }

  function disableSave(): boolean {
    return (
      !description ||
      !status_value ||
      !customer_id ||
      !initiative_id ||
      !initial_date ||
      !final_date ||
      description.trim().length === 0
    );
  }

  return (
    <Breadcrumb
      path="Plano de Ação"
      child={[listTasks[0]?.level4]}
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level4,
        },
      ]}
    >
      <Row gutter={[16, 16]}>
        {filter && (
          <Col span={4}>
            <Card bordered={false}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                initialValues={commercialStructure}
              >
                <CommercialStructure formRef={formRef} level={4} />
                <Button id="buttons" htmlType="submit" type="primary" block>
                  Buscar
                </Button>
              </Form>
            </Card>
          </Col>
        )}
        <Col span={filter ? 20 : 24}>
          <Card bordered={false}>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={8}>
                <Buttons>
                  {filter ? (
                    <FiX
                      color="#00CC66"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilter(!filter)}
                    />
                  ) : (
                    <FiMenu
                      color="#00CC66"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilter(!filter)}
                    />
                  )}
                  <Button
                    icon={<FiPlus />}
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={showModalAction}
                    disabled={level4 === undefined || disbled}
                  >
                    Novo
                  </Button>
                  <Input
                    placeholder="Pesquisar"
                    prefix={<FiSearch color="#00CC66" />}
                    suffix={<FiFilter color="#00CC66" />}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Buttons>
              </Col>
              <Col>
                <Radio.Group buttonStyle="solid" value={filteredStatus}>
                  <Radio.Button onClick={handleChangeStatus} value="PENDENTE">
                    PENDENTE
                  </Radio.Button>
                  <Radio.Button
                    onClick={handleChangeStatus}
                    value="EM ANDAMENTO"
                  >
                    EM ANDAMENTO
                  </Radio.Button>
                  <Radio.Button onClick={handleChangeStatus} value="CANCELADO">
                    CANCELADO
                  </Radio.Button>
                  <Radio.Button onClick={handleChangeStatus} value="FINALIZADO">
                    FINALIZADO
                  </Radio.Button>
                  {filteredStatus !== null ? (
                    <Button
                      style={{
                        backgroundColor: "#fc5f5f",
                        color: "#000",
                      }}
                      onClick={handleRemoveStatus}
                    >
                      X
                    </Button>
                  ) : (
                    ""
                  )}
                </Radio.Group>
              </Col>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={searchData}
                  loading={{ indicator: Icon, spinning: isLoading }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Modal
          //  footer={false}
          visible={modalNewAction}
          okButtonProps={{ disabled: disableSave() }}
          okText={"Salvar"}
          onOk={handleOk}
          width={600}
          onCancel={handleCancel}
          title={id ? "Atualizar Plano de Ação" : "Novo Plano de Ação"}
        >
          <Row justify="center" gutter={20}>
            <Col span={12}>
              <Input
                allowClear
                placeholder="Descrição"
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />

              <StyledDatePicker
                format="DD/MM/YYYY"
                placeholder="Data Inicial"
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                value={initial_date}
                onChange={(date: any) => setInitial_date(date)}
                disabledDate={disabledDate}
              />
            </Col>

            <Col span={12}>
              <Select
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                dropdownMatchSelectWidth={false}
                placeholder="Status"
                onChange={(value) => setStatus_value(value)}
                value={status_value}
              >
                <Option value={"PENDENTE"}>Pendente</Option>
                <Option value={"CANCELADO"}>Cancelado</Option>
                <Option value={"EM ANDAMENTO"}>Em Andamento</Option>
                <Option value={"FINALIZADO"}>Finalizado</Option>
              </Select>
              <StyledDatePicker
                format="DD/MM/YYYY"
                placeholder="Data Final"
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                onChange={(date: any) => setFinal_date(date)}
                value={final_date}
                disabledDate={disabledDate}
              />
            </Col>
            <Col span={24}>
              <Select
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                placeholder="Iniciativa"
                onChange={(value) => [setInitiative_id(value)]}
                value={initiative_id}
              >
                {initiatives.map((item) => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
                )
              </Select>
              {id ? (
                data_customer.length > 0 ? (
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    dropdownMatchSelectWidth={false}
                    showSearch
                    placeholder="Cliente"
                    optionFilterProp="children"
                    onChange={(value) => setCustomer_id(value)}
                    value={customer_id}
                  >
                    {data_customer.map((item) => (
                      <Option value={item.customer_id}>
                        {item.customer_name}
                      </Option>
                    ))}
                    )
                  </Select>
                ) : (
                  <> </>
                )
              ) : data_customer.length > 0 ? (
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  dropdownMatchSelectWidth={true}
                  showSearch
                  mode="multiple"
                  optionFilterProp="children"
                  placeholder="Cliente"
                  onChange={(value) => setCustomer_id(value)}
                  value={customer_id}
                >
                  {data_customer.map((item) => (
                    <Option value={item.customer_id}>
                      {item.customer_name}
                    </Option>
                  ))}
                  )
                </Select>
              ) : (
                <> </>
              )}
            </Col>
          </Row>
        </Modal>
      </Row>
    </Breadcrumb>
  );
}
