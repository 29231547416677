import Http from "../../utils/http";

class ElectProductService extends Http {
  public constructor() {
    super();
  }

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/campaign`);

    // public getIndustryProducts = (companyId: number) =>
    // this.instance.get(`/company/${companyId}/product`);

    
    public getCampaignProducts = (campaignid: number) =>
    this.instance.get(`/company/${campaignid}/campaign-product`);

    public getAwardProducts = (campaignid: number) =>
    this.instance.get(`/company/${campaignid}/campaign-award`);

    public getProductsInfo = (campaignid: number,productid: number|undefined) =>
    this.instance.get(`/company/${campaignid}/campaign-product/${productid}`);

    public getIndustryProducts = (campaignid: number,industry_id: number,) =>
    this.instance.get(`/company/${campaignid}/product/industry/${industry_id}`);

    public getCampaignTeste = (companyId: number, data: any) => {
      return this.instance.post(`/company/${companyId}/campaign/filtro`,data);
    };
  

    public getCampaign = (companyId: number) =>
    this.instance.get(`/company/${companyId}/campaign`);

    public getIndustry = (Industry_id: number, ) => {
    
      return this.instance.get(
        `/global/industry/${Industry_id}`
      );
    };
  

    public getTypeAll = (companyId: number) => {
      return this.instance.get(`/company/${companyId}/campaign/all/type`);
    };
  
    public getType = (companyId: number, id: number) => {
      return this.instance.get(`/company/${companyId}/campaign/type/${id}`);
    };

    
    public createCompanyProduct = (selectedCampaignId: number, body: object) => {
      return this.instance.post(`/company/${selectedCampaignId}/campaign-product`, body);
    };


    public createCompanyAward = (selectedCampaignId: number, body: object) => {
      return this.instance.post(`/company/${selectedCampaignId}/campaign-award`, body);
    };

    public updateAward = (selectedCampaignId: number, ProductId:number | undefined ,data: any) => {
      return this.instance.put(
        `/company/${selectedCampaignId}/campaign-product/${ProductId}`,
        data
      );
    };


    public DeleteCompanyProduct = (selectedCampaignId: number, ProductId: number) => {
      return this.instance.delete(`/company/${selectedCampaignId}/campaign-product/${ProductId}`);
    };

    public DeleteCompanyAward = (campaignid: number, ProductId: number) => {
      return this.instance.delete(`/company/${campaignid}/campaign-award/${ProductId}`);
    };

  public createTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/productgroup`, body);
  };

  public updateTask = (companyId: number, body: object, taskId: number) => {
    return this.instance.put(
      `/company/${companyId}/productgroup/${taskId}`,
      body
    );
  };

  public deleteTask = (companyId: number, taskId: number) => {
    return this.instance.delete(`/company/${companyId}/productgroup/${taskId}`);
  };
}

export default new ElectProductService();
