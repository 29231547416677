import styled from "styled-components";
import {Col, ColProps, Button, ButtonProps} from "antd";

import backgroundImage from "../../../assets/background.png";
import textureImage from "../../../assets/texture.svg";

export const Container = styled(Col)<ColProps>`
  display: flex;
  height: 100vh;
  background-color: #00CC66;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  background: url(${textureImage}) no-repeat center;
  background-size: cover;
`;

export const Image = styled(Col)<ColProps>`
  flex: 1;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center;
  background-size: cover;
`;

export const Title = styled.h3`
  color: #FFFFFF;
  font-weight: bold;
  font-size: 32px;
  margin-top: 70px;
  margin-bottom: 5px;
  line-height: 24px;
`;

export const SubTitle = styled.p`
  color: #FFFFFF;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  max-width: 350px;
`;

export const Forms = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 30px;
`;

export const ForgotPassword = styled.p`
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
`;

export const Logo = styled.img``;

export const Signin = styled(Button)<ButtonProps>`
  background-color: #008E47;
  color: #FFFFFF;
  border: 1px solid #008E47;

  &:hover {
    background-color: #008E47;
    color: #FFFFFF;
    border: 1px solid #008E47;
  }
`;
