import { Routes as RoutesDom, Route, BrowserRouter } from "react-router-dom";
import { AuthRoutes } from "../modules/Auth/Routes";
import { ParametersRoutes } from "../modules/Parameters/Routes";
import { CustomerManagementRoutes } from "../modules/Pgc/Routes";
import { SalesPlanningRoutes } from "../modules/SalesPlanning/Routes";
import { RebateRoutes } from "../modules/Rebate/Routes";
import { SetupRoutes } from "../modules/Setup/Routes";
import { useAppSelector } from "../hooks";

import { Layout } from "../components/Layout";
import { useEffect } from "react";
import { loadEntities } from "../entities";

export function Routes() {
  const { autorized } = useAppSelector((state) => state.auth);
  const companyId = useAppSelector((state) => state.parameters.company.id);

  useEffect(() => {
    (async () => {
      if (autorized && companyId) await loadEntities(companyId);
    })();
  }, [companyId, autorized]);

  function publicRoutes() {
    return (
      <BrowserRouter>
        <RoutesDom>
          {AuthRoutes.map((props, index) => (
            <Route key={index} {...props} />
          ))}
        </RoutesDom>
      </BrowserRouter>
    );
  }

  function privateRoutes() {
    return (
      <BrowserRouter>
        <Layout>
          <RoutesDom>
            {ParametersRoutes.map((props, index) => (
              <Route key={index} {...props} />
            ))}
            {CustomerManagementRoutes.map((props, index) => (
              <Route key={index} {...props} />
            ))}
            {SalesPlanningRoutes.map((props, index) => (
              <Route key={index} {...props} />
            ))}
            {RebateRoutes.map((props, index) => (
              <Route key={index} {...props} />
            ))}
            {SetupRoutes.map((props, index) => (
              <Route key={index} {...props} />
            ))}
          </RoutesDom>
        </Layout>
      </BrowserRouter>
    );
  }

  return autorized ? privateRoutes() : publicRoutes();
}
