import { Button, Card, Col, message, Row } from "antd";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useState, useEffect } from "react";
import PowerBi_all from "../../services/Global/PowerBi";
import { LeftOutlined, MenuOutlined } from "@ant-design/icons";
import { FiMenu, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
export function PowerBi_Dashboard() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [data, setData] = useState<any>([]);
  const [url, setUrl] = useState<any>(undefined);
  const [Menu, setMenu] = useState<boolean>(true);
  const navigate = useNavigate();
  function abiriRelaroio(url: any) {
    if (url.length > 50) {
      setMenu(false);
      setUrl(url);
    } else {
      setUrl(undefined);
      message.warning("Relatório está indisponível no momento!");
    }
  }
  useEffect(() => {
    if (companyId) loadLinks();
  }, [companyId]);

  async function loadLinks() {
    const response = await PowerBi_all.getPowerBi(companyId);
    setData(response);
  }
  return (
    <div>
      <Row style={{ margin: 10 }}>
        <Col style={{ backgroundColor: "#fff", borderRadius: 5 }}>
          <Button
            style={{
              display: "contents",
            }}
            onClick={() => navigate("/select-module")}
          >
            <LeftOutlined
              style={{
                fontSize: 12,
                paddingLeft: 5,
                paddingRight: 5,
                color: "#00CC66",
              }}
              twoToneColor="#00CC66"
              color="#00CC66"
            />
          </Button>
        </Col>
        <span style={{ marginLeft: 10 }}>DashBoard</span>
      </Row>
      <Row style={{ marginTop: 10 }}>
        {Menu ? (
          <>
            <Col span={4}>
              {data ? (
                <Card bordered={true} style={{ marginRight: 10 }}>
                  {data.map((item: any) => (
                    <Button
                      key={item.id}
                      type="primary"
                      onClick={() => {
                        abiriRelaroio(item.value);
                      }}
                      style={{ width: "100%", marginBottom: 10 }}
                    >
                      {item.name}
                    </Button>
                  ))}
                </Card>
              ) : (
                <></>
              )}
            </Col>
          </>
        ) : (
          <></>
        )}
        <Col span={Menu ? 20 : 24}>
          {url !== undefined ? (
            <>
              <Card
                style={{
                  border: "none",
                  cursor: "pointer",
                  width: 50,
                  height: 50,
                  position: "absolute",
                  zIndex: 9999,
                  left: 0,
                  backgroundColor: "#fff",
                }}
                onClick={() => setMenu(!Menu)}
              >
                <FiMenu color="#00CC66" size={20} />
              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <iframe
                  title="% Faturamento por Tipo Acesso"
                  width="100%"
                  allowFullScreen={true}
                  style={{ border: "none", height: "87vh" }}
                  src={url}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
}
