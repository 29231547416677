import styled from "styled-components";
import {Input as InputANTD} from "antd";

export const Actions = styled.div`
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const Input = styled(InputANTD)`
  margin: 5px 0;
`;
