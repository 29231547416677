import { GoogleMap } from "@react-google-maps/api";
import { mapStyle } from "../../utils";

export function Map({ children }) {
  return (
    <GoogleMap
      center={{
        lat: -14.235004,
        lng: -51.92528,
      }}
      mapContainerStyle={{
        width: "100%",
        height: "calc(100vh - 65px)",
      }}
      zoom={4}
      options={{
        fullscreenControl: false,
        disableDefaultUI: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: mapStyle,
        disableDoubleClickZoom: true,
      }}
    >
      {children}
    </GoogleMap>
  );
}
