import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Select,
  Button,
  Input,
  message,
  Modal,
  Typography,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import { createRef, useCallback, useEffect, useState } from "react";
import {
  FiEdit2,
  FiEye,
  FiFilter,
  FiMenu,
  FiPlus,
  FiSearch,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import {
  useAppDispatch,
  useAppSelector,
  useParameterName,
} from "../../../hooks";
import { FormInstance } from "antd/es/form";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { Actions, Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import GeographyService from "../../../services/Global/Geography";
import CompanyCropService from "../../../services/Company/Crop";
import CostomerMappingService from "../../../services/Global/CustomerMapping";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;
export function CustomerMapping() {
  const dispatch = useAppDispatch();
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [filter, setFilter] = useState(true);
  const [status, setStatus] = useState<string>();
  const formRef = createRef<FormInstance>();
  const [data_nao_alocados, setData_nao_alocados] = useState<any>(undefined);
  const [modalShowNew, setModalShowNew] = useState(false);
  const [modalShowEdite, setModalShowEdite] = useState(false);
  const [disabledNew, setDisabledNew] = useState(true);
  const [nomeVendedor, setNomeVendedor] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [listTasks, setListTasks] = useState<any>();
  const [listTasks2, setListTasks2] = useState<any>();
  const [level4, setLevel4] = useState<any>();

  const [nomeCultivo, setNomeCultivo] = useState<any>(undefined);
  const [estado, setEstado] = useState<any>(undefined);
  const [cidade, setCidade] = useState<any>(undefined);
  const [area, setArea] = useState<number | undefined>(undefined);
  if (area !== undefined) {
    if (isNaN(area)) {
      setArea(0);
    }
  }

  const [crop_id, setCrop_id] = useState<any>(undefined);
  const [city_id, setCity_id] = useState<any>(undefined);
  const [customer_id, setCustomer_id] = useState<any>(undefined);
  const [cultivos, setCultivos] = useState<any>(undefined);
  const [states, setStates] = useState<any>(undefined);
  const [cities, setCities] = useState<any>(undefined);
  const [Estilizacao, setEstilizacao] = useState<any>(undefined);

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [loading, setLoading] = useState(false);

  if (status === "F") {
    var newStatus = "Reabrir";
    var disbled = true;
  } else {
    var newStatus = "Finalizar";
    var disbled = false;
  }
  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  const columns = [
    {
      title: "Cod. Grupo",
      dataIndex: "customer_group_id",
      key: "customer_group_id",
    },
    {
      title: "Grupo",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Cod. Cliente",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (text: any) =>
        Estilizacao !== undefined ? (
          text === Estilizacao.customer_id ? (
            <a style={{ fontWeight: "bold" }}>{text}</a>
          ) : (
            <p>{text}</p>
          )
        ) : (
          <p>{text}</p>
        ),
    },
    {
      title: "Categoria Cliente",
      dataIndex: "customer_category",
      key: "customer_category",
    },
    {
      title: "Cliente",
      dataIndex: "customer_name",
      key: "customer_id",
    },
    {
      title: "Cidade",
      dataIndex: "customer_city_name",
      key: "customer_city_id",
    },
    {
      title: "UF",
      dataIndex: "customer_state",
      key: "customer_state",
    },
  ];
  const columns2 = [
    {
      title: "Cultivo",
      dataIndex: "crop_name",
      key: "crop_name",
    },
    {
      title: "Cidade",
      dataIndex: "city_name",
      key: "city_name",
    },
    {
      title: "UF",
      dataIndex: "state_initials",
      key: "state_initials",
    },
    {
      title: "Área (ha)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            {status === "F" ? (
              <></>
            ) : (
              <FiEdit2
                size={18}
                color={status === "F" ? "#9b9b9b" : "#00CC66"}
                style={{ cursor: status === "F" ? "no-drop" : "pointer" }}
                onClick={() => handleClickEdit2(record)}
              />
            )}

            <Popconfirm
              title="Tem certeza que deseja excluir?"
              onConfirm={() => DeleteWallet(record.id)}
              disabled={status === "F" ? true : false}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash
                size={18}
                color={status === "F" ? "#9b9b9b" : "#00CC66"}
                style={{ cursor: status === "F" ? "no-drop" : "pointer" }}
              />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];
  async function cultivo() {
    try {
      setLoading(true);
      const response = await CompanyCropService.getCompanyCrop(companyId);
      setCultivos(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function States() {
    try {
      setLoading(true);
      const response = await GeographyService.getStates();
      setStates(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function Cities(estados: any) {
    try {
      setLoading(true);
      const response = await GeographyService.getCities(estados);
      setCities(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function handleClickEdit2(data: any) {
    setModalShowEdite(true);
    setNomeCultivo(data.crop_name);
    setEstado(data.state_initials);
    setCidade(data.city_name);
    setCrop_id(data.crop_id);
    setArea(Number(data.area));
    setCity_id(data.city_id);
  }
  async function Nao_alocados(customer_id: any) {
    setLoading(true);
    try {
      const response = await CostomerMappingService.getNewcustomers(
        companyId,
        level4,
        customer_id
      );
      setNomeCultivo(undefined);
      setArea(undefined);
      setEstado(undefined);
      setCidade(undefined);
      setCrop_id(undefined);
      setCity_id(undefined);
      setCities(undefined);
      setStates(undefined);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          city_name: rawTask.city_name,
          crop_name: rawTask.crop_name,
          crop_id: rawTask.crop_id,
          state_initials: rawTask.state_initials,
          type_name: rawTask.type_name,
          city_id: rawTask.city_id,
          id: rawTask.id,
          area: rawTask.area,
        };
      });

      setData_nao_alocados(formattedTasks);
      setListTasks2(
        formattedTasks.sort(function (a: any, b: any) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      cultivo();
      States();
    }
  }

  async function PostWallet() {
    setLoading(true);
    let body: any = {
      level4_id: level4,
      customer_id: customer_id,
      area: area,
      city_id: city_id,
      crop_id: crop_id,
    };
    try {
      await CostomerMappingService.PostCostomerMapping(companyId, body);
      loadTableProduct(level4);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
      Nao_alocados(customer_id);
    }
  }
  async function getCustomerStatus(level4_id: any) {
    try {
      const response = await CostomerMappingService.getCostomerMappingStatus(
        companyId,
        level4_id
      );
      setNomeVendedor(response.level4_external_name);
      setStatus(response.pgc_customer_mapping);
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  async function PutWallet() {
    setLoading(true);
    let body: any = {
      level4_id: level4,
      customer_id: customer_id,
      city_id: city_id,
      state_initials: estado,
      crop_id: crop_id,
      area: area,
    };
    try {
      await CostomerMappingService.PutCostomerMapping(companyId, body);
      loadTableProduct(level4);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
      Nao_alocados(customer_id);
    }
  }

  async function DeleteWallet(value: any) {
    try {
      await CostomerMappingService.DeleteCostomerMapping(companyId, value);
      loadTableProduct(level4);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
      Nao_alocados(customer_id);
    }
  }

  function showModalNew() {
    setModalShowNew(true);
    setNomeCultivo(undefined);
    setArea(undefined);
    setEstado(undefined);
    setCidade(undefined);
    setCity_id(undefined);
  }
  const loadTableProduct = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const response = await CostomerMappingService.getCostomerMapping(
          companyId,
          id
        );
        setData(response);
        const formattedTasks = response.map((rawTask: any) => {
          return {
            customer_city_id: rawTask.customer_city_id,
            customer_city_name: rawTask.customer_city_name,
            customer_external_code:
              rawTask.customer_external_code === ""
                ? " "
                : rawTask.customer_external_code,
            customer_id: rawTask.customer_id,
            customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : " ",
            customer_group_id: rawTask.customer_group_id
              ? rawTask.customer_group_id
              : " ",
            customer_name: rawTask.customer_name,
            customer_registered_number: rawTask.customer_registered_number
              ? rawTask.customer_registered_number
              : " ",
            customer_state: rawTask.customer_state,
            group_external_code: rawTask.group_external_code
              ? rawTask.group_external_code
              : " ",
            group_name: rawTask.group_name ? rawTask.group_name : " ",
            group_registered_number: rawTask.group_registered_number
              ? rawTask.group_registered_number
              : " ",
            pgc_customer_mapping_id: rawTask.pgc_customer_mapping_id
              ? rawTask.pgc_customer_mapping_id
              : " ",
            key: rawTask.customer_id,
          };
        });
        setListTasks(
          formattedTasks.sort(function (a: any, b: any) {
            if (a.customer_id < b.customer_id) {
              return -1;
            } else {
              return 1;
            }
          }) as []
        );
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );
  useEffect(() => {
    filterTable();

    async function filterTable() {
      if (!search) {
        setData(listTasks);
        if (customer_id !== undefined) {
          Nao_alocados(customer_id);
        }
      } else {
        setData_nao_alocados(undefined);
        let newList = await listTasks.filter((task: any) => {
          return (
            task.customer_name.toLowerCase().includes(search.toLowerCase()) ||
            task.customer_category.toLowerCase().includes(search.toLowerCase()) ||
            task.customer_city_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.customer_registered_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.group_name.toLowerCase().includes(search.toLowerCase()) ||
            task.customer_state.toLowerCase().includes(search.toLowerCase()) ||
            task.group_external_code
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.group_registered_number
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            task.customer_id.toString().includes(search.toLowerCase()) ||
            task.customer_group_id.toString().includes(search.toLowerCase())
          );
        });
        setData(newList);
      }
    }
  }, [search, listTasks]);
  useEffect(() => {
    filterTable2();
    async function filterTable2() {
      if (!search2) {
        setData_nao_alocados(listTasks2);
      } else {
        let newList2 = await listTasks2.filter((task: any) => {
          return (
            task.city_name.toLowerCase().includes(search2.toLowerCase()) ||
            task.crop_name.toLowerCase().includes(search2.toLowerCase()) ||
            task.state_initials.toLowerCase().includes(search2.toLowerCase()) ||
            task.type_name.toLowerCase().includes(search2.toLowerCase())
          );
        });
        setData_nao_alocados(newList2);
      }
    }
  }, [search2, listTasks2]);

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    setData_nao_alocados(undefined);
    setCustomer_id(undefined);
    setEstilizacao(undefined);
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    getCustomerStatus(obj.level4);
    loadTableProduct(obj.level4);
    setFilter(false);
    setDisabledNew(false);
  }
  function handleOk() {
    if (crop_id !== undefined) {
      PostWallet();
    } else {
      PutWallet();
    }
    setModalShowNew(false);
    setModalShowEdite(false);
  }
  function handleCancel() {
    setSearch2("");
    setModalShowNew(false);
    setModalShowEdite(false);
    setCities(undefined);
    data_nao_alocados(undefined);
  }
  function disableSave(): boolean {
    return !area;
  }

  return (
    <Breadcrumb
      path="Mapeamento de Cliente"
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level4,
        },
      ]}
      child={nomeVendedor}
    >
      <Row gutter={[16, 16]}>
        {filter && (
          <Col span={4}>
            <Card bordered={false}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                initialValues={commercialStructure}
              >
                <CommercialStructure formRef={formRef} level={4} />
                <Button htmlType="submit" type="primary" block>
                  Buscar
                </Button>
              </Form>
            </Card>
          </Col>
        )}
        <Col
          span={
            filter
              ? data_nao_alocados !== undefined
                ? 12
                : 20
              : data_nao_alocados === undefined
              ? 24
              : 16
          }
        >
          <Card bordered={false}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col span={filter ? 3.5 : 2.5}>
                <Buttons>
                  {filter ? (
                    <FiX
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  ) : (
                    <FiMenu
                      color="#00CC66"
                      size={20}
                      onClick={() => setFilter(!filter)}
                    />
                  )}
                </Buttons>
              </Col>
              <Col span={8}>
                <Input
                  allowClear
                  placeholder="Pesquisar"
                  prefix={<FiSearch color="#00CC66" />}
                  suffix={<FiFilter color="#00CC66" />}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns}
                  rowSelection={{
                    type: "radio",
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                      Nao_alocados(+selectedRowKeys.toString());
                      setCustomer_id(+selectedRowKeys.toString());
                      setEstilizacao(selectedRows);
                    },
                  }}
                  dataSource={data}
                  loading={{ indicator: Icon, spinning: loading }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {data_nao_alocados !== undefined ? (
          <Col span={8}>
            <Card bordered={false}>
              <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col>Lista de Cultivo</Col>
                <Button
                  type="primary"
                  onClick={showModalNew}
                  disabled={status === "F" ? true : disabledNew}
                  icon={<FiPlus />}
                >
                  Novo
                </Button>
                <Col span={24}>
                  <Input
                    allowClear
                    placeholder="Pesquisar"
                    prefix={<FiSearch color="#00CC66" />}
                    suffix={<FiFilter color="#00CC66" />}
                    onChange={(e) => setSearch2(e.target.value)}
                  />
                </Col>

                <Col span={24}>
                  <Table
                    size="large"
                    columns={columns2}
                    style={{ color: "#00CC66" }}
                    dataSource={data_nao_alocados}
                    loading={{ indicator: Icon, spinning: loading }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <> </>
        )}
        <Modal
          visible={modalShowNew || modalShowEdite}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"Salvar"}
          okButtonProps={{ disabled: disableSave() }}
        >
          <Typography.Title level={4}>{"Lista Cultivo"}</Typography.Title>
          {cultivos !== undefined ? (
            <>
              <div style={{ fontWeight: "bold" }}> {"Cultivo"}</div>
              <Select
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                dropdownMatchSelectWidth={false}
                disabled={modalShowEdite}
                showSearch
                placeholder="Tipo de Cultivo"
                optionFilterProp="children"
                onChange={(value) => [setNomeCultivo(value), setCrop_id(value)]}
                value={nomeCultivo}
              >
                {cultivos.map((initiative: any) => (
                  <Option value={initiative.crop_id}>
                    {initiative.crop.type_name}
                    {" - "}
                    {initiative.external_name}
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            <></>
          )}
          {states !== undefined ? (
            <>
              <div style={{ fontWeight: "bold" }}> {"UF"}</div>
              <Select
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                dropdownMatchSelectWidth={false}
                disabled={modalShowEdite}
                showSearch
                placeholder="Estado"
                optionFilterProp="children"
                onChange={(value) => [setEstado(value), Cities(value)]}
                value={estado}
              >
                {states.map((initiative: any) => (
                  <Option value={initiative.state_initials}>
                    {initiative.state_initials}
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            <></>
          )}
          {cities !== undefined ? (
            <>
              <div style={{ fontWeight: "bold" }}>{"Cidade"}</div>
              <Select
                style={{ width: "100%", margin: "10px 0" }}
                size="large"
                disabled={modalShowEdite}
                dropdownMatchSelectWidth={false}
                showSearch
                placeholder="Cidade"
                optionFilterProp="children"
                onChange={(value) => setCity_id(+value)}
                value={cidade}
              >
                {cities.map((initiative: any) => (
                  <>
                    <Option value={initiative.id}>
                      {initiative.city_name}
                    </Option>
                  </>
                ))}
              </Select>
            </>
          ) : (
            <></>
          )}
          {modalShowEdite ? (
            <>
              <div style={{ fontWeight: "bold" }}>{"Cidade"}</div>
              <div style={{ fontSize: 5 }}>⠀</div>
              <Input
                size="large"
                placeholder="Cidade"
                min={0}
                max={99}
                disabled={modalShowEdite}
                value={cidade}
              />
            </>
          ) : (
            <></>
          )}
          <div style={{ fontSize: 5 }}>⠀</div>
          <div style={{ fontWeight: "bold" }}>{"Área (ha)"}</div>
          <div style={{ fontSize: 5 }}>⠀</div>
          <Input
            size="large"
            placeholder="Área"
            maxLength={7}
            value={area}
            onChange={(e) => setArea(+e.target.value)}
          />
        </Modal>
      </Row>
    </Breadcrumb>
  );
}
