import styled from "styled-components";
import { Layout } from "antd";

export const Container = styled(Layout.Header)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;

export const Logo = styled.img`
  cursor: pointer;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 8px;
  }
`;
