import { Button, Card, Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../../components/Breadcrumb";
import { Loading } from "../../../components/Loading";
import { useAppSelector } from "../../../hooks";
import PgcService from "../../../services/Pgc";
import { Content, Title } from "./styles";

export function Setup_pgc() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [permission, setPermission] = useState<any>([]);
  const [parameter, setParameter] = useState<any>(false);
  const [user, setUser] = useState<any>(false);
  const [commercialStructure, setCommercialStructure] = useState<any>(false);
  const [commercialStructureUser, setCommercialStructureUser] =
    useState<any>(false);
  const [cultivation, setCultivation] = useState<any>(false);
  const [groupProduct, setGroupProduct] = useState<any>(false);
  const [product, setProduct] = useState<any>(false);
  const [accessType, setAccessType] = useState<any>(false);
  const [createClient, setCreateClient] = useState<any>(false);
  const [updateSeller, setUpdateSeller] = useState<any>(false);
  const [businessType, setBusinessType] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadPermission();
  }, [companyId]);
  async function loadPermission() {
    setLoading(true);
    try {
      const response = await PgcService.getPGCPermission(companyId);
      setPermission(response);
      console.log("response", response.pgc_setup_businessType.permission);
      setParameter(response.pgc_setup_parameter.permission);
      setUser(response.pgc_setup_user.permission);
      setCommercialStructure(response.pgc_setup_commercialStructure.permission);
      setCommercialStructureUser(
        response.pgc_setup_commercialStructure_user.permission
      );
      setCultivation(response.pgc_setup_crop.permission);
      setGroupProduct(response.pgc_setup_groupProduct.permission);
      setProduct(response.pgc_setup_product.permission);
      setAccessType(response.pgc_setup_accessType.permission);
      setCreateClient(response.pgc_setup_customerwallet.permission);
      setUpdateSeller(response.pgc_setup_migrationwallet.permission);
      setBusinessType(response.pgc_setup_businessType.permission);
    } catch (err) {
      message.error(err);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Breadcrumb path="Setup">
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={[16, 16]}>
          {parameter ? (
            <Col span={6} key={1}>
              <Card bordered={false}>
                <Content>
                  <Title>Parâmetro</Title>
                  <Button
                    disabled={!parameter}
                    type="primary"
                    onClick={() => navigate("/setup/parameter")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {user ? (
            <Col span={6} key={2}>
              <Card bordered={false}>
                <Content>
                  <Title>Usuário</Title>
                  <Button
                    disabled={!user}
                    type="primary"
                    onClick={() => navigate("/setup/user")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {commercialStructure ? (
            <Col span={6} key={3}>
              <Card bordered={false}>
                <Content>
                  <Title>Estrutura Comercial</Title>
                  <Button
                    disabled={!commercialStructure}
                    type="primary"
                    onClick={() => navigate("/setup/commercial-structure")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {commercialStructureUser ? (
            <Col span={6} key={4}>
              <Card bordered={false}>
                <Content>
                  <Title>Estrutura Comercial Usuário</Title>
                  <Button
                    type="primary"
                    disabled={!commercialStructureUser}
                    onClick={() => navigate("/setup/commercial-structure-user")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {cultivation ? (
            <Col span={6} key={5}>
              <Card bordered={false}>
                <Content>
                  <Title>Cultivo</Title>
                  <Button
                    disabled={!cultivation}
                    type="primary"
                    onClick={() => navigate("/setup/crop")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {businessType ? (
            <Col span={6} key={11}>
              <Card bordered={false}>
                <Content>
                  <Title>Tipo de Negócio</Title>
                  <Button
                    disabled={!businessType}
                    type="primary"
                    onClick={() => navigate("/setup/business-type")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {groupProduct ? (
            <Col span={6} key={6}>
              <Card bordered={false}>
                <Content>
                  <Title>Grupo Produto</Title>
                  <Button
                    disabled={!groupProduct}
                    type="primary"
                    onClick={() => navigate("/setup/group-product")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {product ? (
            <Col span={6} key={7}>
              <Card bordered={false}>
                <Content>
                  <Title>Produto</Title>
                  <Button
                    disabled={!product}
                    type="primary"
                    onClick={() => navigate("/setup/product")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {accessType ? (
            <Col span={6} key={8}>
              <Card bordered={false}>
                <Content>
                  <Title>Tipo de Acesso</Title>
                  <Button
                    disabled={!accessType}
                    type="primary"
                    onClick={() => navigate("/setup/access-type")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {createClient ? (
            <Col span={6} key={9}>
              <Card bordered={false}>
                <Content>
                  <Title>Cadastro de Clientes</Title>
                  <Button
                    disabled={!createClient}
                    type="primary"
                    onClick={() => navigate("/setup/create-client")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {updateSeller ? (
            <Col span={6} key={10}>
              <Card bordered={false}>
                <Content>
                  <Title>Migração de Carteira</Title>
                  <Button
                    disabled={!updateSeller}
                    type="primary"
                    onClick={() => navigate("/setup/update-seller")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
        </Row>
      )}
    </Breadcrumb>
  );
}
