import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 8px;
    cursor: pointer;
  }
`;
