import styled from "styled-components";

export const Container = styled.div`
  padding: 0 40px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px 96px;
  height: 300px;
  margin-top: 48px;

  background: #ffffff;
  border-radius: 8px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  font-size: 32px;
  line-height: 30px;
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #727272;
`;

export const Icon = styled.img`
  width: 67px;
  height: 64px;
  color: #727272;
`;
