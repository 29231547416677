import Http from "../../../utils/http";

class ActionPlanService extends Http {
  public constructor() {
    super();
  }

  public getFindAll = (id: number, level4_id: number) =>
    this.instance.get(`/company/${id}/pgc/action-plan?level4_id=${level4_id}`);

  public findAllInitiatives = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/initiative`);
  };

  public findAllCustomers = (companyId: number, level4_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/action-plan/customers?level4_id=${level4_id}`
    );
  };

  public PostActionPlain = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgc/action-plan`, body);

  public getStatus = (id: number, level4_id: number) =>
    this.instance.get(
      `/company/${id}/pgc/action-plan/status?level4_id=${level4_id}`
    );

  public putStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgc/action-plan/status`, body);
}

export default new ActionPlanService();
