import Http from "../../../../utils/http";
import {ParameterData} from "./ParameterDataTypes";


class ParameterService extends Http {
  public constructor() {
    super();
  }


  public findAll = (id: number): Promise<ParameterData[]> => {
    return this.instance.get(`/company/${id}/parameter`);
  }

  public create = (companyId: number, parameter: ParameterData): Promise<void> => {
    console.log(parameter)
    

    return this.instance.post(`/company/${companyId}/parameter`, parameter);
  }

  public update = (companyId: number, parameter: ParameterData): Promise<void> => {
    return this.instance.put(`/company/${companyId}/parameter/${parameter.id}`, parameter);
  }

  public deleteById = (companyId: number, parameterId: number | null| undefined): Promise<void> => {
    return this.instance.delete(`/company/${companyId}/parameter/${parameterId}`);
  }

}

export default new ParameterService();