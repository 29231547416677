import Http from "../../../utils/http";

class CompanyProductService extends Http {
  public constructor() {
    super();
  }

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/product`);
    
  public createTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/product`, body);
  };

  public updateTask = (companyId: number, body: object, taskId: number) => {
    return this.instance.put(`/company/${companyId}/product/${taskId}`, body);
  };

  public deleteTask = (companyId: number, taskId: number) => {
    return this.instance.delete(`/company/${companyId}/product/${taskId}`);
  };
}

export default new CompanyProductService();
