import { Pgc } from "../../Pgc";
import { BranchMapping } from "../BranchMapping";
import { CustomerMapping } from "../CustomerMapping";
import { SalesForecast } from "../SalesForecast";
import { ActionPlan } from "../ActionPlan";
import { WalletMapping } from "../WalletMapping";

export const CustomerManagementRoutes = [
  { path: "/pgc", element: <Pgc /> },
  { path: "/pgc/branch-mapping", element: <BranchMapping /> },
  { path: "/pgc/customer-mapping", element: <CustomerMapping /> },
  { path: "/pgc/sales-forecast", element: <SalesForecast /> },
  { path: "/pgc/action-plain", element: <ActionPlan /> },
  { path: "/pgc/wallet-mapping", element: <WalletMapping /> },
];
