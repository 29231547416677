import { FormInstance } from "antd/es/form";
import { RefObject, useState } from "react";
import { Level0 } from "./Level0";
import { Level1 } from "./Level1";
import { Level2 } from "./Level2";
import { Level3 } from "./Level3";
import { Level4 } from "./Level4";

interface CommercialStructureProps {
  level: number;
  formRef: RefObject<FormInstance<any>>;
}

export function CommercialStructure({
  level,
  formRef,
}: CommercialStructureProps) {
  const [loadingLevel1, setLoadingLevel1] = useState(false);
  const [loadingLevel2, setLoadingLevel2] = useState(false);
  const [loadingLevel3, setLoadingLevel3] = useState(false);
  const [loadingLevel4, setLoadingLevel4] = useState(false);

  return (
    <>
      {level >= 0 && <Level0 setLoading={setLoadingLevel1} formRef={formRef} />}
      {level >= 1 && (
        <Level1
          loading={loadingLevel1}
          setLoading={setLoadingLevel2}
          formRef={formRef}
        />
      )}
      {level >= 2 && (
        <Level2
          loading={loadingLevel2}
          setLoading={setLoadingLevel3}
          formRef={formRef}
        />
      )}
      {level >= 3 && (
        <Level3
          loading={loadingLevel3}
          setLoading={setLoadingLevel4}
          formRef={formRef}
        />
      )}
      {level >= 4 && <Level4 loading={loadingLevel4} />}
      
    </>
  );
}
