import styled from "styled-components";

import {DatePicker} from "antd";

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 16px;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledDatePicker = styled(DatePicker as any)`
  width: 100%;
`;
