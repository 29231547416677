import styled from "styled-components";
import {Row as RowANTD} from "antd";
import {Input as InputANTD} from "antd";
import {Select as SelectANTD } from "antd";

export const Row = styled(RowANTD)`
  display: flex;
  gap: 0.6%;
  align-items: baseline;
`

export const Col = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: calc(20% - 8px);
  padding: 12px;
  border-radius: 4px;
  justify-content: space-between;
`;


export const ColHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-
  align-items: center;
  width: 100%;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
  }
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  overflow: auto;
  max-height: calc(82vh - 100px);
`;

interface StructureItemContainerProps{
  active: boolean
}

export const StructureItemContainer = styled.div<StructureItemContainerProps>`
  display: flex;
  flex-direction: row;
  background: ${({active}) => active ? "#C7F7D4" : "#FAFAFA"};
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  justify-content: space-between;
  transition: 0.3s all ease-in-out;
  
`

export const ItemLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px;
  color: #727272;
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;

  color: #00CC66;
  
  svg{
    margin: 5px;
  }
`
export const Actions = styled.div`
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const Input = styled(InputANTD)`
  margin: 5px 0;
`;

export const Select = styled(SelectANTD)`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
margin: 5px;
color: #727272;
`;


export const StructureItemContainerse = styled.div`
  display: flex;
  flex-direction: row;
  background: "#C7F7D4"};
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  /* justify-content: space-between; */
  transition: 0.3s all ease-in-out;
  
`
