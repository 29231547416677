import Http from "../../../utils/http";

class PowerBi_all extends Http {
  public constructor() {
    super();
  }

  public getPowerBi = async (companyId: number) => {
    console.log(this.instance.get(`/company/${companyId}/parameter/type/3`));
    return this.instance.get(`/company/${companyId}/parameter/type/3`);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PowerBi_all();
