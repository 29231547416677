import { Row } from "antd";
import { Image, Container, Content, Logo, Title, SubTitle, Forms } from "./styles";
import logoWhite from "../../../assets/logo_white.svg";

interface FormAuthProps {
  children: JSX.Element,
  title: string,
  subTitle: string,
}

export function FormAuth({ children, title, subTitle }: FormAuthProps) {
  return (
    <Row>
      <Container lg={10} md={24} sm={24} xs={24}>
        <Content>
          <Logo src={logoWhite} />
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>

          <Forms>
            {children}
          </Forms>
        </Content>
      </Container>
      <Image lg={14} />
    </Row>
  );
}