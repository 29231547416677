import styled from "styled-components";
import InputNumber from "react-number-format";

export const Input = styled(InputNumber as any)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #0f0f0f;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f9f9f9;
  background-image: none;
  border: 1px solid #f9f9f9;
  border-radius: 2px;
  transition: all 0.3s;

  &:focus-visible {
    border: 1px solid #23d978;
    outline: none;
  }
`;
