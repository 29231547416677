import { Button, Card, Col, Row, Table, Modal, Typography, Input } from "antd";
import { useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";

export function Office() {
  const [modalNewOffice, setModalNewOffice] = useState(false);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descrição",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: () => {
        return (
          <Actions>
            <FiEdit2 size={18} color="#00CC66" />
            <FiTrash size={18} color="#00CC66" />
          </Actions>
        );
      },
    },
  ];

  function showModalNewOffice() {
    setModalNewOffice(true);
  }

  function handleOk() {
    setModalNewOffice(false);
  }

  function handleCancel() {
    setModalNewOffice(false);
  }

  return (
    <Breadcrumb path="Setup" child={["Cargos"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewOffice}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table size="small" columns={columns} />
            </Col>
          </Row>
        </Card>

        <Modal
          footer={false}
          visible={modalNewOffice}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>Novo Cargo</Typography.Title>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
