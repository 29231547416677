import {CommercialStructureProps} from "../../../entities/DTO";
import {ItemActions, ItemLabel, StructureItemContainer} from "./styles";
import {FiEdit2, FiTrash} from "react-icons/fi";
import {Popconfirm} from "antd";


interface Props {
  structure: CommercialStructureProps;
  level: number;
  handleClick: () => void;
  handleUpdate: () => void;
  handleDelete: () => void
  isActive: boolean;
  showDelete: boolean;
}

export function StructureItem(props: Props) {

  function getName() {
    switch (props.structure.level_type) {
      case "0":
        return `level0_external_name`;
      case "1":
        return `level1_external_name`;
      case "2":
        return `level2_external_name`;
      case "3":
        return `level3_external_name`;
      default:
        return `level4_external_name`;

    }
  }

  return (
    <StructureItemContainer onClick={props.handleClick} active={props.isActive}>
      <ItemLabel>{props.structure[getName()]}</ItemLabel>

      <ItemActions>
        <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/>

        {props.showDelete && (
          <Popconfirm
            disabled={!props.showDelete}
            title="Tem certeza que deseja excluir este item?"
            onConfirm={() => props.handleDelete()}
            okText="Excluir"
            okButtonProps={{danger: true}}
            cancelText="Cancelar"
          >
            <FiTrash onClick={e => {
              e.stopPropagation();
            }} size={18}/>
          </Popconfirm>
        )}

      </ItemActions>
    </StructureItemContainer>
  )
}