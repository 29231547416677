import styled from "styled-components";
import { Layout } from "antd";

interface ContentProps {
  fullScreen?: boolean;
}

export const Container = styled(Layout.Content)`
  margin-top: 16px;
`;

export const Content = styled(Layout.Content)<ContentProps>`
  padding: 0;

`;
