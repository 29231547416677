import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Select,
  Typography,
  Divider,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import {
  FiCopy,
  FiEdit2,
  FiFilter,
  FiLink,
  FiMail,
  FiPlus,
  FiSearch,
  FiTrash,
} from "react-icons/fi";

import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
import { UserData } from "../../../services/Global/User/userDataTypes";
import UserService from "../../../services/Global/User";
import { Input } from "../Parameter/styles";
import { useAppSelector } from "../../../hooks";
import AcesseType from "../../../services/Global/AcesseType";
const { Option } = Select;
export function User() {
  const idCompany = useAppSelector((state) => state.parameters.company.id);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [listUsers, setListUsers] = useState<UserData[]>([]);
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emails, setEmails] = useState<any[]>([]);
  const [profile_id, setProfileId] = useState<any>(undefined);
  const [idCompanyUser, setIdCompanyUser] = useState<any>(undefined);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [exited, setExited] = useState<boolean>(false);
  const [picture, setPicture] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [disableName, setDisableName] = useState<boolean>(false);
  const [AccessTypes, setAccessTypes] = useState<any[]>([]);
  const [varificado, setVarificado] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>([]);
  const [showModalNewPassword, setShowModalNewPassword] = useState(false);
  const [randomSave, setRandomSave] = useState<string>("");
  const [EmailSave, setEmailSave] = useState<string>("");

  useEffect(() => {
    AccessType();
  }, []);
  useEffect(() => {
    setDisableName(false);
    setVarificado(false);
    setCreateNew(false);
  }, [email]);
  useEffect(() => {
    getListUsers();
  }, [AccessTypes]);

  async function AccessType() {
    try {
      const response = await AcesseType.getAcessType();

      const formattedTasks = response.map((task: any) => {
        return {
          profile: task.name,
          profile_id: task.id,
        };
      });
      setAccessTypes(
        formattedTasks.sort((a: any, b: any) => a.profile_id - b.profile_id)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
  }
  async function ComparaEmail() {
    setVarificado(false);
    try {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i] === email) {
          message.error("Email já cadastrado nessa empresa");
          setCreateNew(false);
          return true;
        }
      }
      for (let i = 0; i < emails.length; i++) {
        message.info("Email não cadastrado nessa empresa");
        setCreateNew(true);
        setExited(true);
        for (let i = 0; i < allUsers.length; i++) {
          if (allUsers[i].email === email) {
            setName(allUsers[i].name);
            setSelectedUserId(allUsers[i].id);
            setDisableName(true);
            return false;
          }
        }
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    } finally {
      setVarificado(true);
    }
  }
  async function verifyEmail() {
    setName("");
    try {
      const response = await UserService.findByEmail(email);
      if (response) {
        setDisableSave(true);
        ComparaEmail();
      } else {
        setDisableSave(false);
      }
    } catch (error) {
      setCreateNew(true);
      setExited(false);
    }
  }
  async function getListUsers() {
    try {
      setLoading(true);
      const response: UserData[] = await UserService.findAll();
      const response2: UserData[] = await UserService.getCompanyLinks(
        idCompany
      );
      var data: any = [];
      var Emails = [];
      for (let i = 0; i < response.length; i++) {
        if (i > response2.length + response.length) {
        } else {
          for (var j = 0; j < response2.length; j++) {
            if (response[i].id === response2[j].user_id) {
              Emails.push(response[i].email);
              var nun = response2[j].profile_id;
              data.push({
                id: response[i].id,
                profile_id: AccessTypes[nun - 1].profile,
                idCompanyUser: response2[j].id,
                email: response[i].email,
                name: response[i].name,
                picture: response[i].picture,
              });
            }
          }
        }
      }
      setListUsers(data);
      setAllUsers(response);
      setSearchData(data);
      setEmails(Emails);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Perfil",
      dataIndex: "profile_id",
      key: "profile_id",
    },
    {
      title: "Imagem URL",
      dataIndex: "picture",
      key: "picture",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 150,
      align: "center" as const,
      render: (_: null, record: UserData) => {
        return (
          <Actions>
            <Tooltip title="Resetar Senha">
              <Popconfirm
                title="Tem certeza que deseja Resetar a Senha do usuário?"
                onConfirm={() =>
                  confirmResetPassword(record.email ? record.email : "")
                }
                okText="Resetar"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <FiMail size={18} color="#00CC66" />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Editar">
              <FiEdit2
                size={18}
                color="#00CC66"
                onClick={() => handleClickEdit(record)}
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir esse usuário?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <FiTrash size={18} color="#00CC66" />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  function clearData() {
    setSelectedUserId(null);
    setName("");
    setEmail("");
    setPicture("");
    setProfileId(undefined);
    setCreateNew(false);
    setDisableName(false);
    setIdCompanyUser(undefined);
  }

  function handleClickEdit(user: UserData) {
    //  verifyEmail();
    setSelectedUserId(user.id ?? null);
    setName(user.name);
    setEmail(user.email ?? "");
    setPicture(user.picture ?? "");
    setProfileId(user.profile_id ?? undefined);
    setIdCompanyUser(user.idCompanyUser ?? null);
    setShowModalNewUser(true);
  }

  async function confirmDelete(user: UserData) {
    try {
      const companyLinks: [] = await UserService.getCompanyLinks(idCompany);
      let linkId = 0;
      companyLinks.forEach((link: any) => {
        if (link.company_id === idCompany && link.user_id === user.id) {
          linkId = link.id;
        }
      });
      if (linkId) {
        console.log(linkId);
        console.log("deletando");
        try {
          await UserService.unlinkToCompany(idCompany, linkId);
        } catch {
          message.error("Erro ao excluir usuário dessa empresa");
        }
      }
      await UserService.deleteById(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      await getListUsers();
    }
  }
  const makeHalfUpperCase = () => {
    const str =
      Math.random().toString(36).slice(2, 8) +
      Math.random().toString(36).slice(2, 8);
    let result = "";
    let upperCount = Math.floor(str.length / 2);
    for (let i = 0; i < str.length; i++) {
      if (Math.random() < 0.5 && upperCount > 0) {
        result += str[i].toUpperCase();
        upperCount--;
      } else {
        result += str[i];
      }
    }
    return result;
  };

  const confirmResetPassword = async (email: string) => {
    const random = makeHalfUpperCase();
    try {
      await UserService.ResetPassword(email, random);
      setRandomSave(random);
      setEmailSave(email);
      setShowModalNewPassword(true);
      message.success("Senha resetada com sucesso!");
    } catch (e) {
      console.error(e);
      message.error("Erro ao resetar senha!");
    }
  };

  function handleShowModalNewUser() {
    setShowModalNewUser(true);
  }
  async function handleCreateUser() {
    try {
      if (await canUserThisEmail()) {
        exited === false ? await createUserInCognito() : await null;
        if (exited === false) {
          const userCreated = await createUserInDatabase();
          await UserService.linkToCompany(
            idCompany,
            userCreated.id,
            profile_id
          );
        } else {
          if (selectedUserId !== null) {
            await UserService.linkToCompany(
              idCompany,
              selectedUserId,
              profile_id
            );
          }
        }
      } else {
        message.error("Email já vinculado à outro usuário");
      }
    } catch (e) {
      console.error("Falha ao salvar Usuário: ");
      console.error(e);
    }

    async function canUserThisEmail() {
      if (emails.indexOf(email) != -1) {
        return false;
      } else {
        return true;
      }

      /*
      try {
        const user = await UserService.findByEmail(email);
        return !user;
      } catch (e) {
        if (e.response.data.message === "Usuário não encontrado!") {
          return true;
        }
      }*/
    }

    async function createUserInDatabase() {
      return await UserService.create({ name, email, picture, profile_id });
    }

    async function createUserInCognito() {
      const password = "Mud@r123";
      //Create user in cognito
      /* await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
        },
      });*/
    }
  }

  async function handleSave() {
    setShowModalNewUser(false);
    setDisableSave(true);
    try {
      if (selectedUserId && createNew === false) {
        await UserService.update({
          id: selectedUserId,
          name,
          email,
          picture,
          profile_id,
        });
        await UserService.updateCompanyUser(
          idCompany,
          idCompanyUser === undefined ? selectedUserId : idCompanyUser,
          {
            profile_id: profile_id,
          }
        );
      } else {
        await handleCreateUser();
      }
      setDisableSave(false);
    } catch (e) {
      console.log(e);
    } finally {
      await getListUsers();
      setShowModalNewUser(false);
      clearData();
    }
  }

  function handleCancel() {
    setShowModalNewUser(false);
    clearData();
  }

  function isSaveDisabled(): boolean {
    return !email || validaEmail(email) || !profile_id || !name;
  }
  function validaEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return !re.test(email);
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = listUsers.filter(
        (record) =>
          record["name"].toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listUsers);
    }
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success("Texto copiado com sucesso!");
    } catch (err) {
      console.error("Falha ao copiar texto: ", err);
    }
  };

  return (
    <Breadcrumb path="Setup" child={["Usuário"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={handleShowModalNewUser}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          visible={showModalNewUser}
          okText={"Salvar"}
          onOk={handleSave}
          width={800}
          okButtonProps={{ disabled: isSaveDisabled() }}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedUserId ? "Atualizar" : "Novo"} Usuário
              </Typography.Title>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Email
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Email"
                  disabled={selectedUserId && !createNew}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                {selectedUserId || varificado ? null : (
                  <Button
                    type="primary"
                    title="Validar"
                    size="large"
                    disabled={validaEmail(email)}
                    icon={<FiLink />}
                    onClick={() => verifyEmail()}
                  />
                )}
              </Col>
              {createNew || selectedUserId ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Nome
                    </Typography.Text>
                    <Input
                      size="large"
                      placeholder="Nome"
                      disabled={disableName}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Perfil
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      dropdownMatchSelectWidth={false}
                      showSearch
                      placeholder="Perfil"
                      optionFilterProp="children"
                      onChange={(e) => setProfileId(e)}
                      value={profile_id}
                    >
                      {AccessTypes.map((initiative) => (
                        <Option value={initiative.profile_id}>
                          {initiative.profile}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Imagem URL
                    </Typography.Text>
                    <Input
                      size="large"
                      placeholder="Imagem URL"
                      value={picture}
                      onChange={(e) => setPicture(e.target.value)}
                    />
                  </Col>
                </>
              ) : null}
            </Col>
          </Row>
        </Modal>
        <Modal
          style={{
            width: "800px",
            height: "auto",
          }}
          title={<b>Senha Resetada</b>}
          open={showModalNewPassword}
          footer={null}
          width={800}
          closable={false}
        >
          <Typography.Text
            style={{ fontSize: "18px", lineHeight: 1.5, color: "#333" }}
          >
            <br />
            Email: <b>{EmailSave}</b>
            <br />
            <br />
            <div
              onClick={() => copyToClipboard(randomSave)}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <Tooltip title="click para Copiar">
                Nova Senha: <b>{randomSave}</b>
                <FiCopy style={{ marginLeft: 20 }} />
              </Tooltip>
            </div>
          </Typography.Text>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div></div>
            <Button
              style={{
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                transition: "all 0.3s ease",
                height: "auto",
              }}
              type="primary"
              onClick={() => setShowModalNewPassword(false)}
            >
              OK
            </Button>
          </div>
        </Modal>
      </>
    </Breadcrumb>
  );
}
